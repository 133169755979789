import FrameComponent from "./FrameComponent";

const Container2 = () => {
  return (
    <div className="self-stretch bg-white flex flex-col items-end justify-start py-0 pr-[23px] pl-6 gap-[22px] text-left text-xs text-lightslategray-100 font-inter">
      <FrameComponent
        image60="/image-60@2x.png"
        friedChicken="Jollof Rice with Veggies"
        sizeL="Size: L"
        toppingColeslawPLANTAIN="Topping: Coleslaw, Plantain"
        spicinessHot="Spiciness: Hot"
        prop="N3,200"
        propWidth="217px"
        propColor="#171a1f"
      />
      <div className="w-[110px] h-8 flex flex-row items-center justify-start gap-[16px] text-dimgray-100">
        <div className="self-stretch flex-1 rounded bg-whitesmoke-200 overflow-hidden flex flex-row items-center justify-start py-1.5 px-3">
          <img
            className="h-3 w-3 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/minus.svg"
          />
          <div className="self-stretch w-0 relative leading-[20px] inline-block" />
        </div>
        <div className="relative text-base leading-[26px] font-poppins text-gray-100 text-center">
          1
        </div>
        <img
          className="h-8 w-9 relative rounded overflow-hidden shrink-0 min-h-[32px]"
          loading="eager"
          alt=""
          src="/button-20.svg"
        />
      </div>
    </div>
  );
};

export default Container2;
