import { useMemo } from "react";

const DeliveryServiceFrame = ({
  onlineOrderButton,
  bellefulJollof,
  steamJollofRiceWith2Soulf,
  propMarginLeft,
  propHeight,
}) => {
  const onlineOrderButtonStyle = useMemo(() => {
    return {
      marginLeft: propMarginLeft,
    };
  }, [propMarginLeft]);

  const bellefulJollofStyle = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  return (
    <div className="flex-1 flex flex-col items-center min-w-[200px] max-w-[310px] text-center">
      <div className="self-stretch rounded-xl [background:linear-gradient(179.69deg,_rgba(255,_255,_255,_0),_#f547482e)] flex flex-col items-center justify-start pt-[146px] pb-[84px] pr-[33px] pl-[34px] gap-[30px] mq450:gap-[15px] mq450:pt-[95px] mq450:pb-[55px] mq450:box-border">
        <div className="relative rounded-xl [background:linear-gradient(179.69deg,_rgba(255,_255,_255,_0),_#f54748)] h-[368px] hidden" />
        <div className="flex flex-row items-start justify-start py-0 pr-[3px] pl-0">
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-4 w-5 relative overflow-hidden shrink-0 z-[1]"
              alt=""
              src="/naira.svg"
            />
            <div
              className="relative capitalize font-medium z-[2]"
              style={onlineOrderButtonStyle}
            >
              {onlineOrderButton}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[13px] text-5xl text-crimson-100 font-poppins">
          <div
            className="h-9 relative capitalize font-black whitespace-pre-wrap inline-block z-[1] mq450:text-lgi"
            style={bellefulJollofStyle}
          >
            {bellefulJollof}
          </div>
          <div className="self-stretch relative text-xs capitalize text-gray-300 z-[1]">
            {steamJollofRiceWith2Soulf}
          </div>
        </div>
      </div>
      <button className="cursor-pointer [border:none] pt-2.5 pb-2 pr-3 pl-5 bg-crimson-100 rounded-81xl flex flex-row items-center justify-end whitespace-nowrap z-[1] mt-[-27px] hover:bg-tomato-100">
        <div className="h-[45px] w-[131px] relative rounded-81xl bg-crimson-100 hidden" />
        <div className="h-[27px] relative text-lg capitalize font-poppins text-white text-left inline-block z-[1]">
          Order Now
        </div>
      </button>
    </div>
  );
};

export default DeliveryServiceFrame;
