import OrderOnlineFrameGroceries from "./OrderOnlineFrameGroceries";

const FoodtrayFrame = () => {
  return (
    <div className="w-[1317px] flex flex-row items-start justify-start py-0 px-3 box-border max-w-full text-left text-lg text-white font-poppins">
      <div className="flex-1 flex flex-col items-start justify-start gap-[45px] max-w-full mq750:gap-[22px]">
        <div className="self-stretch flex flex-row flex-wrap items-end justify-start gap-[36px] max-w-full mq750:gap-[18px]">
          <OrderOnlineFrameGroceries propWidth="403px" />
          <div className="flex-1 flex flex-col items-start justify-start py-0 pr-3 pl-0 box-border min-w-[270px] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
              <img
                className="h-[490px] flex-1 relative rounded-xl max-w-full overflow-hidden object-cover"
                loading="eager"
                alt=""
                src="/rectangle-4597-1@2x.png"
              />
              <button className="cursor-pointer [border:none] p-2 bg-crimson-100 my-0 mx-[!important] absolute right-[80px] bottom-[-16px] rounded-81xl flex flex-row items-center justify-center whitespace-nowrap z-[1] hover:bg-tomato-100">
                <div className="h-[27px] relative text-lg capitalize font-medium font-poppins text-snow-100 text-left inline-block">
                  Restaurant Reservation
                </div>
              </button>
            </div>
          </div>
          <div className="w-[403px] flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border max-w-full">
            <OrderOnlineFrameGroceries
              propWidth="unset"
              propAlignSelf="stretch"
            />
          </div>
        </div>
        <div className="self-stretch grid flex-row items-start justify-start gap-[36px] max-w-full grid-cols-[repeat(3,_minmax(307px,_1fr))] mq750:gap-[18px] mq750:grid-cols-[minmax(307px,_1fr)] mq1050:justify-center mq1050:grid-cols-[repeat(2,_minmax(307px,_532px))]">
          <div className="flex flex-row items-start justify-start relative max-w-full">
            <img
              className="h-[490px] flex-1 relative rounded-xl max-w-full overflow-hidden object-cover"
              loading="eager"
              alt=""
              src="/rectangle-4597-3@2x.png"
            />
            <button className="cursor-pointer [border:none] p-2 bg-crimson-100 my-0 mx-[!important] absolute bottom-[-16px] left-[145px] rounded-81xl flex flex-row items-center justify-center z-[1] hover:bg-tomato-100">
              <div className="h-[27px] relative text-lg capitalize font-poppins text-white text-left inline-block">
                Groceries
              </div>
            </button>
          </div>
          <div className="flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border max-w-full">
            <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
              <img
                className="h-[490px] flex-1 relative rounded-xl max-w-full overflow-hidden object-cover"
                loading="eager"
                alt=""
                src="/rectangle-4597-4@2x.png"
              />
              <div className="my-0 mx-[!important] absolute right-[103px] bottom-[-16px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center py-2 pr-1 pl-[9px] whitespace-nowrap z-[1]">
                <div className="relative capitalize">Recipe Generation</div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start relative max-w-full">
            <img
              className="h-[490px] flex-1 relative rounded-xl max-w-full overflow-hidden object-cover"
              loading="eager"
              alt=""
              src="/rectangle-4597-5@2x.png"
            />
            <button className="cursor-pointer [border:none] p-2 bg-crimson-100 my-0 mx-[!important] absolute right-[87px] bottom-[-16px] rounded-81xl flex flex-row items-center justify-center whitespace-nowrap z-[1] hover:bg-tomato-100">
              <div className="relative text-lg capitalize font-poppins text-white text-left">{`Competitions & Events`}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodtrayFrame;
