const Menu = () => {
  return (
    <div className="w-full h-[932px] relative bg-white overflow-hidden hidden flex-col items-center justify-start pt-[19px] px-0 pb-0 box-border gap-[12px] tracking-[normal] text-left text-mini text-grey-2 font-poppins mq450:h-auto">
      <img
        className="w-[2048px] h-[1094px] relative object-cover hidden max-w-full"
        alt=""
        src="/pngegg-42-2@2x.png"
      />
      <div className="w-[69px] relative leading-[99.5%] font-black text-black hidden whitespace-nowrap">
        AED 50.5
      </div>
      <div className="w-[19px] relative text-2xs leading-[99.5%] font-black text-new-2 hidden">
        5.5
      </div>
      <div className="w-[129px] relative text-smi leading-[99.5%] font-black hidden">
        Fried Rice with Fish
      </div>
      <div className="w-[366px] flex flex-row items-start justify-start pt-0 px-5 pb-[35px] box-border max-w-full shrink-0">
        <img
          className="h-[12.8px] flex-1 relative max-w-full overflow-hidden"
          loading="eager"
          alt=""
          src="/icon-iphone.svg"
        />
      </div>
      <div className="w-[143px] h-1.5 relative hidden">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-21xl bg-gainsboro-500" />
      </div>
      <main className="self-stretch flex flex-col items-center justify-start gap-[11px] max-w-full shrink-0 text-left text-5xl text-grey-2 font-poppins">
        <div className="w-[398px] h-11 flex flex-row items-start justify-start py-0 px-5 box-border max-w-full">
          <h1 className="m-0 w-[72px] relative text-inherit font-black font-inherit inline-block">
            Menu
          </h1>
        </div>
        <section className="w-[399px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[20px] min-h-[96px] max-w-full text-left text-xs text-grey-2 font-poppins">
          <div className="self-stretch flex flex-row items-start justify-between py-[7px] pr-1 pl-[9px] relative gap-[20px] mq450:flex-wrap">
            <div className="h-full w-16 absolute my-0 mx-[!important] top-[0px] bottom-[0px] left-[0.5px] rounded bg-new-2 shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)]" />
            <div className="h-full w-16 absolute my-0 mx-[!important] top-[0px] bottom-[0px] left-[74.5px] rounded bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)]" />
            <div className="h-full w-16 absolute my-0 mx-[!important] top-[0px] right-[146.5px] bottom-[0px] rounded bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)]" />
            <div className="h-full w-16 absolute my-0 mx-[!important] top-[0px] right-[73.5px] bottom-[0px] rounded bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)]" />
            <div className="h-full w-16 absolute my-0 mx-[!important] top-[0px] right-[-0.5px] bottom-[0px] rounded bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)]" />
            <div className="h-[19px] w-[53px] flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border text-white">
              <div className="self-stretch flex-1 relative font-black z-[1]">
                Dishes
              </div>
            </div>
            <div className="h-[19px] w-11 flex flex-col items-start justify-start py-0 pr-[7px] pl-0 box-border">
              <div className="self-stretch flex-1 relative font-black z-[1]">
                Pizza
              </div>
            </div>
            <div className="h-[19px] w-[52px] flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
              <div className="self-stretch flex-1 relative font-black z-[1]">
                Burger
              </div>
            </div>
            <div className="h-[19px] w-[42px] relative font-black inline-block shrink-0 z-[1]">
              Drinks
            </div>
            <div className="h-[19px] w-[55px] relative font-black inline-block shrink-0 z-[1]">
              Dessert
            </div>
          </div>
          <div className="self-stretch h-[34px] bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-between pt-[9px] pb-[3px] pr-[15px] pl-[5px] box-border gap-[20px] max-w-full text-sm text-dimgray-500">
            <div className="h-[34px] w-[359px] relative bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] hidden max-w-full" />
            <div className="self-stretch w-[214px] flex flex-row items-start justify-start gap-[20px]">
              <img
                className="h-[19px] w-[19px] relative mix-blend-normal z-[1]"
                loading="eager"
                alt=""
                src="/xmlid-223.svg"
              />
              <div className="flex-1 relative font-black mix-blend-normal z-[1]">
                Search for today’s meal
              </div>
            </div>
            <img
              className="h-4 w-[20.2px] relative z-[1]"
              loading="eager"
              alt=""
              src="/filter.svg"
            />
          </div>
        </section>
        <section className="w-[402px] flex flex-row items-start justify-start py-0 pr-5 pl-[23px] box-border min-h-[596px] max-w-full text-left text-xs text-grey-2 font-poppins">
          <div className="flex-1 flex flex-col items-start justify-start gap-[12px] max-w-full">
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row flex-wrap items-center justify-start pt-4 pb-[11px] pr-[13px] pl-8 box-border relative gap-[22px] max-w-full">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
              <div className="h-[65px] w-[115px] flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1]"
                  loading="eager"
                  alt=""
                  src="/pngegg-42-1@2x.png"
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[115px]">
                <div className="self-stretch h-[47px] flex flex-col items-start justify-start gap-[5px]">
                  <div className="w-[67px] h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                    White Rice
                  </div>
                  <div className="self-stretch h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                    Basmati rice with Vegetable
                  </div>
                  <div className="w-[35px] h-3 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                    <img
                      className="h-[11px] w-3 relative z-[1]"
                      loading="eager"
                      alt=""
                      src="/vector.svg"
                    />
                    <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                      4.5
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-end justify-between gap-[20px] text-sm text-black">
                  <div className="w-14 relative leading-[99.5%] font-black inline-block z-[1]">
                    N 5,000
                  </div>
                  <img
                    className="h-[22px] w-7 relative z-[1]"
                    loading="eager"
                    alt=""
                    src="/vector-1.svg"
                  />
                </div>
              </div>
              <img
                className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[16px] left-[8px] z-[1]"
                loading="eager"
                alt=""
                src="/vector-2.svg"
              />
            </div>
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row flex-wrap items-start justify-start pt-4 pb-[11px] pr-[13px] pl-[30px] box-border relative gap-[22px] max-w-full">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
              <img
                className="h-[71px] w-[117px] relative object-cover z-[1]"
                loading="eager"
                alt=""
                src="/pngegg-45-1@2x.png"
              />
              <div className="flex-1 flex flex-row items-end justify-start gap-[13px] min-w-[115px]">
                <div className="h-[78px] flex-1 flex flex-col items-start justify-start gap-[17px]">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[5px]">
                    <div className="w-[47px] h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                      Biryani
                    </div>
                    <div className="self-stretch h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                      Chicken Biryani India
                    </div>
                    <div className="w-[34px] h-3 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                      <img
                        className="h-[11px] w-3 relative z-[1]"
                        loading="eager"
                        alt=""
                        src="/vector.svg"
                      />
                      <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                        3.5
                      </div>
                    </div>
                  </div>
                  <div className="w-[53px] h-3.5 relative text-sm leading-[99.5%] font-black text-black inline-block z-[1]">
                    N3,500
                  </div>
                </div>
                <img
                  className="h-[22px] w-7 relative z-[1]"
                  loading="eager"
                  alt=""
                  src="/vector-1.svg"
                />
              </div>
              <img
                className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[16px] left-[8px] z-[1]"
                loading="eager"
                alt=""
                src="/vector-2.svg"
              />
            </div>
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row flex-wrap items-start justify-start pt-4 pb-3 pr-[5px] pl-7 box-border relative gap-[24px] max-w-full">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
              <img
                className="h-[72px] w-[117px] relative object-cover z-[1]"
                loading="eager"
                alt=""
                src="/pngegg-43-2@2x.png"
              />
              <div className="flex-1 flex flex-row items-start justify-start relative min-w-[120px]">
                <img
                  className="h-[22px] w-7 absolute my-0 mx-[!important] right-[8px] bottom-[-1px] z-[1]"
                  loading="eager"
                  alt=""
                  src="/vector-1.svg"
                />
                <div className="h-[78px] flex-1 flex flex-col items-start justify-start gap-[17px]">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[5px]">
                    <div className="w-[67px] h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                      White Rice
                    </div>
                    <div className="self-stretch h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                      Vegetable Salad,Thai Cuisine
                    </div>
                    <div className="w-[35px] h-3 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                      <img
                        className="h-[11px] w-3 relative z-[1]"
                        loading="eager"
                        alt=""
                        src="/vector.svg"
                      />
                      <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                        4.5
                      </div>
                    </div>
                  </div>
                  <div className="w-[54px] h-3.5 relative text-sm leading-[99.5%] font-black text-black inline-block z-[1]">
                    N4,500
                  </div>
                </div>
              </div>
              <img
                className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[16px] left-[8px] z-[1]"
                loading="eager"
                alt=""
                src="/vector-2.svg"
              />
            </div>
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row flex-wrap items-start justify-start pt-[15px] pb-[13px] pr-[69px] pl-[30px] box-border relative gap-[32px] max-w-full mq450:gap-[16px] mq450:pr-5 mq450:box-border">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
              <img
                className="h-[22px] w-7 absolute my-0 mx-[!important] right-[13px] bottom-[12px] z-[1]"
                loading="eager"
                alt=""
                src="/vector-1.svg"
              />
              <img
                className="h-[77px] w-[107px] relative rounded-[159px] object-cover min-h-[77px] z-[1]"
                loading="eager"
                alt=""
                src="/pngegg-44-1@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-[17px] min-w-[79px]">
                <div className="self-stretch h-[47px] flex flex-col items-start justify-start gap-[5px]">
                  <div className="w-16 h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                    Jollof Rice
                  </div>
                  <div className="self-stretch h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                    Nigerian Jollof Rice
                  </div>
                  <div className="w-[35px] h-3 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                    <img
                      className="h-[11px] w-3 relative z-[1]"
                      loading="eager"
                      alt=""
                      src="/vector.svg"
                    />
                    <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                      4.5
                    </div>
                  </div>
                </div>
                <div className="w-[52px] h-3.5 relative text-sm leading-[99.5%] font-black text-black inline-block z-[1]">
                  N2,500
                </div>
              </div>
              <img
                className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[15px] left-[8px] z-[1]"
                loading="eager"
                alt=""
                src="/vector-2.svg"
              />
            </div>
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row flex-wrap items-center justify-start pt-[9px] pb-2 pr-10 pl-[27px] box-border relative gap-[30px] max-w-full mq450:gap-[15px]">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
              <img
                className="h-[89px] w-28 relative object-contain z-[1]"
                loading="eager"
                alt=""
                src="/pngegg-54-1@2x.png"
              />
              <div className="flex-1 flex flex-row items-start justify-start relative min-w-[97px]">
                <img
                  className="h-[22px] w-7 absolute my-0 mx-[!important] right-[-27px] bottom-[0px] z-[1]"
                  loading="eager"
                  alt=""
                  src="/vector-1.svg"
                />
                <div className="h-[78px] flex-1 flex flex-col items-start justify-start gap-[17px]">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[5px]">
                    <div className="w-[111px] h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                      Rice And Plantain
                    </div>
                    <div className="self-stretch h-3 relative leading-[99.5%] font-black inline-block z-[1]">
                      Fried Rice With Plantain
                    </div>
                    <div className="w-[35px] h-3 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                      <img
                        className="h-[11px] w-3 relative z-[1]"
                        loading="eager"
                        alt=""
                        src="/vector.svg"
                      />
                      <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                        5.5
                      </div>
                    </div>
                  </div>
                  <div className="w-[52px] h-3.5 relative text-sm leading-[99.5%] font-black text-black inline-block z-[1]">
                    N2,000
                  </div>
                </div>
              </div>
              <img
                className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[15px] left-[8px] z-[1]"
                loading="eager"
                alt=""
                src="/vector-2.svg"
              />
            </div>
          </div>
        </section>
        <section className="self-stretch h-[90px] relative max-w-full text-left text-sm text-grey-2 font-poppins">
          <img
            className="absolute top-[0px] left-[65px] w-[118px] h-[73px] object-cover hidden z-[1]"
            alt=""
            src="/pngegg-48-1@2x.png"
          />
          <div className="absolute top-[-17px] left-[37px] w-[359px] flex flex-row items-start justify-start max-w-full">
            <img
              className="h-[22px] w-7 relative z-[1]"
              loading="eager"
              alt=""
              src="/vector-1.svg"
            />
            <img
              className="h-[11px] w-3 relative z-[1]"
              loading="eager"
              alt=""
              src="/vector.svg"
            />
            <div className="h-[106px] w-[359px] rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row items-start justify-start pt-[15px] px-2 pb-[77px] box-border gap-[151px] max-w-full whitespace-nowrap">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full" />
              <img
                className="h-[9px] w-2.5 relative z-[1]"
                alt=""
                src="/vector-2.svg"
              />
              <div className="self-stretch w-[71px] relative leading-[99.5%] font-black inline-block z-[1]">
                Fried Rice
              </div>
            </div>
          </div>
          <div className="absolute top-[0px] left-[0px] rounded-t-none rounded-b-14xl bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] w-full flex flex-row items-center justify-center pt-[5px] px-0 pb-0 box-border max-w-full z-[2]">
            <div className="h-[85px] w-[430px] relative rounded-t-none rounded-b-14xl bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] hidden max-w-full" />
            <footer className="flex-1 bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_2px_5px_rgba(23,_26,_31,_0.13)] flex flex-row items-center justify-start py-4 px-12 box-border relative gap-[45px] max-w-full z-[4] text-left text-3xs text-dimgray-100 font-poppins mq450:gap-[22px] mq450:pl-5 mq450:pr-5 mq450:box-border">
              <div className="w-[182px] flex flex-row items-center justify-start py-0 pr-px pl-0 box-border gap-[23px] text-slategray">
                <div className="w-[31px] flex flex-col items-start justify-start">
                  <img
                    className="w-7 h-7 relative overflow-hidden shrink-0"
                    loading="eager"
                    alt=""
                    src="/home2.svg"
                  />
                  <b className="self-stretch h-4 relative leading-[16px] inline-block">
                    Home
                  </b>
                </div>
                <div className="h-12 flex-1 bg-gray-1300 overflow-hidden flex flex-col items-end justify-start pt-0 px-0.5 pb-0.5 box-border gap-[14px] text-4xs text-white font-inter">
                  <div className="w-[34px] h-[18px] flex flex-row items-start justify-start py-0 px-[3px] box-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start relative">
                      <img
                        className="h-full w-full absolute my-0 mx-[!important] bottom-[-12px] left-[-12px]"
                        loading="eager"
                        alt=""
                        src="/group-8515.svg"
                      />
                      <div className="self-stretch flex-1 rounded-lg bg-crimson-200 flex flex-row items-center justify-center pt-[5px] px-[3px] pb-1 z-[1] border-[2px] border-solid border-white">
                        <div className="self-stretch w-[18px] relative leading-[9px] inline-block">
                          99+
                        </div>
                      </div>
                    </div>
                  </div>
                  <b className="w-[58px] relative text-3xs leading-[16px] inline-block font-poppins text-dimgray-100">
                    Restaurant
                  </b>
                </div>
                <div className="h-[42px] w-[29px] flex flex-col items-center justify-start gap-[6px] text-new-2">
                  <img
                    className="w-[25px] h-5 relative"
                    alt=""
                    src="/menu-icon.svg"
                  />
                  <b className="self-stretch flex-1 relative leading-[16px]">
                    Menu
                  </b>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start">
                <img
                  className="w-7 h-7 relative"
                  loading="eager"
                  alt=""
                  src="/group-8516.svg"
                />
                <b className="w-[25px] h-4 relative leading-[16px] inline-block">
                  Feed
                </b>
              </div>
              <div className="w-[33px] my-0 mx-[!important] absolute top-[calc(50%_-_22px)] right-[47px] flex flex-col items-start justify-start">
                <img
                  className="w-7 h-7 relative overflow-hidden shrink-0"
                  loading="eager"
                  alt=""
                  src="/account-circle.svg"
                />
                <b className="self-stretch h-4 relative leading-[16px] inline-block">
                  Profile
                </b>
              </div>
            </footer>
          </div>
        </section>
        <section className="w-[402px] flex flex-row items-start justify-start py-0 pr-5 pl-[23px] box-border max-w-full text-left text-sm text-grey-2 font-poppins">
          <div className="flex-1 rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row items-start justify-between pt-[15px] pb-[7px] pr-9 pl-2 box-border gap-[20px] max-w-full mq450:flex-wrap">
            <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full" />
            <div className="flex flex-row items-start justify-start gap-[10px]">
              <img
                className="h-[9px] w-2.5 relative z-[1]"
                alt=""
                src="/vector-2.svg"
              />
              <img
                className="h-[84px] w-[84px] relative object-contain z-[1]"
                alt=""
                src="/pngegg-47-1@2x.png"
              />
            </div>
            <div className="h-[79px] w-[154px] flex flex-row items-start justify-start relative">
              <img
                className="h-[22px] w-7 absolute my-0 mx-[!important] right-[-23px] bottom-[0px] z-[1]"
                alt=""
                src="/vector-1.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[17px]">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[4px]">
                  <div className="w-12 flex-1 relative leading-[99.5%] font-black inline-block z-[1]">
                    Prawn
                  </div>
                  <div className="self-stretch flex-1 relative text-smi leading-[99.5%] font-black whitespace-pre-wrap z-[1]">{`Asian Cuisine Shrimps             `}</div>
                  <div className="w-[35px] flex-1 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                    <img
                      className="h-[11px] w-3 relative z-[1]"
                      alt=""
                      src="/vector.svg"
                    />
                    <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                      4.5
                    </div>
                  </div>
                </div>
                <div className="w-[67px] h-[15px] relative text-mini leading-[99.5%] font-black text-black inline-block whitespace-nowrap z-[1]">
                  AED 35.9
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-[402px] flex flex-row items-start justify-start py-0 pr-5 pl-[23px] box-border max-w-full text-left text-sm text-grey-2 font-poppins">
          <div className="flex-1 flex flex-col items-start justify-start gap-[12px] max-w-full">
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row items-start justify-start pt-[17px] px-[25px] pb-2.5 box-border relative gap-[27px] max-w-full mq450:flex-wrap">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
              <img
                className="h-[22px] w-7 absolute my-0 mx-[!important] right-[13px] bottom-[10px] z-[1]"
                alt=""
                src="/vector-1.svg"
              />
              <img
                className="h-[73.5px] w-[117px] relative object-cover z-[1]"
                alt=""
                src="/pngegg-50-2@2x.png"
              />
              <div className="h-[79px] w-[113px] flex flex-col items-start justify-start gap-[17px]">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[4px]">
                  <div className="w-[43px] flex-1 relative leading-[99.5%] font-black inline-block z-[1]">
                    Pasta
                  </div>
                  <div className="self-stretch flex-1 relative text-smi leading-[99.5%] font-black z-[1]">
                    Pasta Bolognese
                  </div>
                  <div className="w-[35px] flex-1 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                    <img
                      className="h-[11px] w-3 relative z-[1]"
                      alt=""
                      src="/vector.svg"
                    />
                    <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                      5.0
                    </div>
                  </div>
                </div>
                <div className="w-[66px] h-[15px] relative text-mini leading-[99.5%] font-black text-black inline-block whitespace-nowrap z-[1]">
                  AED 25.9
                </div>
              </div>
              <img
                className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[17px] left-[8px] z-[1]"
                alt=""
                src="/vector-2.svg"
              />
            </div>
            <div className="self-stretch rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row items-center justify-start pt-[17px] pb-2.5 pr-0 pl-[17px] box-border gap-[36px] max-w-full mq450:flex-wrap mq450:gap-[18px] mq450:pr-[17px] mq450:box-border">
              <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full" />
              <div className="flex flex-row items-start justify-start relative">
                <img
                  className="h-[73px] w-[116px] relative object-cover z-[1]"
                  alt=""
                  src="/pngegg-51-1@2x.png"
                />
                <img
                  className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[-3px] left-[-9px] z-[2]"
                  alt=""
                  src="/vector-2.svg"
                />
              </div>
              <div className="flex-1 flex flex-row items-start justify-start relative min-w-[123px]">
                <img
                  className="h-[22px] w-7 absolute my-0 mx-[!important] right-[13px] bottom-[0px] z-[1]"
                  alt=""
                  src="/vector-1.svg"
                />
                <div className="h-[79px] flex-1 flex flex-col items-start justify-start gap-[17px]">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[4px]">
                    <div className="w-[54px] flex-1 relative leading-[99.5%] font-black inline-block z-[1]">
                      Biryani
                    </div>
                    <div className="self-stretch flex-1 relative text-smi leading-[99.5%] font-black whitespace-pre-wrap z-[1]">{`Biryani with Grilled Chicken   `}</div>
                    <div className="w-[35px] flex-1 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                      <img
                        className="h-[11px] w-3 relative z-[1]"
                        alt=""
                        src="/vector.svg"
                      />
                      <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                        4.5
                      </div>
                    </div>
                  </div>
                  <div className="w-[65px] h-[15px] relative text-mini leading-[99.5%] font-black text-black inline-block whitespace-nowrap z-[1]">
                    AED 75.9
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="w-[402px] flex flex-row items-start justify-start py-0 pr-5 pl-[23px] box-border max-w-full shrink-0 text-sm">
        <div className="flex-1 rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] flex flex-row flex-wrap items-start justify-start pt-[18px] pb-[7px] pr-[58px] pl-[25px] box-border relative gap-[27px] max-w-full mq450:pr-5 mq450:box-border">
          <div className="h-[106px] w-[359px] relative rounded-sm bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden max-w-full z-[0]" />
          <img
            className="h-[22px] w-7 absolute my-0 mx-[!important] right-[13px] bottom-[9px] z-[1]"
            alt=""
            src="/vector-1.svg"
          />
          <img
            className="self-stretch w-[117px] relative max-h-full object-cover min-h-[80px] z-[1]"
            alt=""
            src="/pngegg-46-1@2x.png"
          />
          <div className="flex-1 flex flex-col items-start justify-start gap-[17px] min-w-[86px]">
            <div className="self-stretch h-[47px] flex flex-col items-start justify-start gap-[4px]">
              <div className="w-[113px] flex-1 relative leading-[99.5%] font-black inline-block z-[1]">
                Chicken Grilled
              </div>
              <div className="self-stretch flex-1 relative text-smi leading-[99.5%] font-black whitespace-pre-wrap z-[1]">{`Full Chicken Grilled     `}</div>
              <div className="w-[34px] flex-1 flex flex-row items-start justify-start gap-[4px] text-2xs text-new-2">
                <img
                  className="h-[11px] w-3 relative z-[1]"
                  alt=""
                  src="/vector.svg"
                />
                <div className="flex-1 relative leading-[99.5%] font-black z-[1]">
                  3.5
                </div>
              </div>
            </div>
            <div className="w-[66px] h-[15px] relative text-mini leading-[99.5%] font-black text-black inline-block whitespace-nowrap z-[1]">
              AED 25.9
            </div>
          </div>
          <img
            className="h-[9px] w-2.5 absolute my-0 mx-[!important] top-[18px] left-[8px] z-[1]"
            alt=""
            src="/vector-2.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;
