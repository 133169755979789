import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();

  const onDeliveryTaxFrameClick = useCallback(() => {
    navigate("/checkout");
  }, [navigate]);

  const onProceedToCheckoutClick = useCallback(() => {
    navigate("/checkout");
  }, [navigate]);

  return (
    <div className="w-full h-[952px] relative bg-white overflow-hidden hidden tracking-[normal] text-left text-mini text-black font-poppins mq450:h-auto mq450:min-h-[952]">
      <b className="absolute top-[279px] left-[24px] hidden opacity-[0.83]">
        Popular Meals
      </b>
      <b className="absolute top-[689px] left-[24px] hidden opacity-[0.83]">
        Previously viewed
      </b>
      <div className="absolute top-[209px] left-[24px] w-[381px] h-[35px] hidden text-base">
        <div className="absolute top-[0px] left-[0px] rounded-md bg-gainsboro-200 w-full h-full" />
        <b className="absolute top-[6px] left-[44px] opacity-[0.38]">Search</b>
        <img
          className="absolute top-[9px] left-[14px] w-[18px] h-[18px] object-cover"
          alt=""
          src="/search-149852-1@2x.png"
        />
      </div>
      <div className="absolute top-[28px] left-[24px] w-[376px] h-[45px] hidden text-base">
        <div className="absolute top-[7px] left-[135px] font-semibold">
          Hi Jane Doe,
        </div>
        <img
          className="absolute top-[0px] left-[331px] w-[45px] h-[45px] object-cover"
          alt=""
          src="/mask-group@2x.png"
        />
        <img
          className="absolute top-[8px] left-[0px] w-[30px] h-[30px] object-cover"
          alt=""
          src="/menu-2976215-1@2x.png"
        />
      </div>
      <main className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] w-full flex flex-col items-center justify-start pt-[30px] px-6 pb-[226px] box-border gap-[24px] max-w-full text-left text-xl text-gray-700 font-poppins">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
        <div className="w-[114px] h-[30px] flex flex-row items-start justify-start py-0 pr-[19px] pl-0 box-border">
          <div className="self-stretch flex-1 relative font-medium z-[1]">
            Your Cart
          </div>
        </div>
        <section className="self-stretch flex flex-col items-start justify-start gap-[18px] max-w-full text-left text-xl text-gray-700 font-poppins">
          <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-between pt-3 px-0 pb-[25px] box-border gap-[20px] max-w-full z-[1] mq450:flex-wrap">
            <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
            <div className="flex flex-row items-end justify-start gap-[15px]">
              <img
                className="h-[65px] w-[65px] relative object-cover z-[1]"
                loading="eager"
                alt=""
                src="/mask-group-11@2x.png"
              />
              <div className="h-[25px] w-[54px] flex flex-row items-start justify-start relative">
                <div className="w-36 absolute my-0 mx-[!important] top-[-29px] right-[-90px] inline-block z-[1]">
                  Chicken Salad
                </div>
                <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                  N 5000
                </div>
              </div>
            </div>
            <div className="h-[33px] flex flex-col items-start justify-start pt-[13px] px-0 pb-0 box-border font-poor-story">
              <div className="flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                <div className="rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-start py-0 pr-1 pl-[5px] gap-[7px] z-[1]">
                  <div className="h-[16.9px] w-[61.3px] relative rounded-4xl bg-gainsboro-400 hidden" />
                  <div className="h-[17px] w-9 relative">
                    <img
                      className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                      loading="eager"
                      alt=""
                      src="/minussign@2x.png"
                    />
                    <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                  </div>
                  <img
                    className="h-[9.4px] w-[9.4px] relative object-cover z-[2]"
                    loading="eager"
                    alt=""
                    src="/plussign@2x.png"
                  />
                </div>
                <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                  1
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-between pt-[15px] px-0 pb-[22px] box-border gap-[20px] max-w-full z-[1] mq450:flex-wrap">
            <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
            <div className="flex flex-row items-end justify-start gap-[15px]">
              <img
                className="h-[65px] w-[65px] relative object-cover z-[4]"
                loading="eager"
                alt=""
                src="/mask-group-21@2x.png"
              />
              <div className="h-[30px] w-[54px] flex flex-col items-start justify-start pt-0 px-0 pb-[5px] box-border">
                <div className="self-stretch flex-1 flex flex-row items-start justify-start relative">
                  <div className="w-[100px] absolute my-0 mx-[!important] top-[-27px] right-[-46px] inline-block z-[1]">
                    Jollof Rice
                  </div>
                  <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                    N 5000
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[27px] flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border font-poor-story">
              <div className="flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                <div className="rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-start py-0 pr-1 pl-[5px] gap-[7px] z-[1]">
                  <div className="h-[16.9px] w-[61.3px] relative rounded-4xl bg-gainsboro-400 hidden" />
                  <div className="h-[17px] w-9 relative">
                    <img
                      className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                      loading="eager"
                      alt=""
                      src="/minussign@2x.png"
                    />
                    <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                  </div>
                  <img
                    className="h-[9.4px] w-[9.4px] relative object-cover z-[2]"
                    loading="eager"
                    alt=""
                    src="/plussign@2x.png"
                  />
                </div>
                <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                  1
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-between pt-[15px] px-0 pb-[22px] box-border gap-[20px] max-w-full z-[1] mq450:flex-wrap">
            <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
            <div className="flex flex-row items-end justify-start gap-[15px]">
              <img
                className="h-[65px] w-[65px] relative object-cover z-[1]"
                loading="eager"
                alt=""
                src="/mask-group-11@2x.png"
              />
              <div className="h-[30px] w-[54px] flex flex-col items-start justify-start pt-0 px-0 pb-[5px] box-border">
                <div className="self-stretch flex-1 flex flex-row items-start justify-start relative">
                  <div className="w-[66px] absolute my-0 mx-[!important] top-[-27px] right-[-12px] inline-block z-[1]">
                    Burger
                  </div>
                  <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                    N 5000
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[27px] flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border font-poor-story">
              <div className="flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                <div className="rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-start py-0 pr-1 pl-[5px] gap-[7px] z-[1]">
                  <div className="h-[16.9px] w-[61.3px] relative rounded-4xl bg-gainsboro-400 hidden" />
                  <div className="h-[17px] w-9 relative">
                    <img
                      className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                      loading="eager"
                      alt=""
                      src="/minussign@2x.png"
                    />
                    <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                  </div>
                  <img
                    className="h-[9.4px] w-[9.4px] relative object-cover z-[2]"
                    loading="eager"
                    alt=""
                    src="/plussign@2x.png"
                  />
                </div>
                <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                  1
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row flex-wrap items-end justify-start pt-[15px] px-0 pb-[22px] box-border gap-[15px] max-w-full z-[2]">
            <img
              className="h-[65px] w-[65px] relative object-cover z-[4]"
              loading="eager"
              alt=""
              src="/mask-group-4@2x.png"
            />
            <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
            <div className="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5px] box-border min-w-[71px]">
              <div className="w-[54px] h-[25px] flex flex-row items-start justify-start relative">
                <div className="w-[106px] absolute my-0 mx-[!important] top-[-27px] right-[-52px] inline-block z-[1]">
                  Beef Pasta
                </div>
                <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                  N 5000
                </div>
              </div>
            </div>
            <div className="h-[35px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[11px] box-border min-w-[65px]">
              <img
                className="w-[9.4px] h-[24.6px] relative hidden z-[1]"
                alt=""
                src="/group-5.svg"
              />
            </div>
            <div className="h-[39px] flex flex-col items-start justify-start pt-0 px-0 pb-[19px] box-border font-poor-story">
              <div className="flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                <div className="rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-start py-0 pr-1 pl-[5px] gap-[7px] z-[1]">
                  <div className="h-[16.9px] w-[61.3px] relative rounded-4xl bg-gainsboro-400 hidden" />
                  <div className="h-[17px] w-9 relative">
                    <img
                      className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                      loading="eager"
                      alt=""
                      src="/minussign@2x.png"
                    />
                    <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                  </div>
                  <img
                    className="h-[9.4px] w-[9.4px] relative object-cover z-[2]"
                    loading="eager"
                    alt=""
                    src="/plussign@2x.png"
                  />
                </div>
                <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                  1
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="absolute top-[635px] left-[0px] bg-gainsboro-400 w-full flex flex-col items-center justify-start pt-2.5 px-2.5 pb-[19px] box-border gap-[14px] max-w-full z-[1] text-left text-xl text-gray-900 font-poppins">
        <div className="w-[428px] h-[317px] relative bg-gainsboro-400 hidden max-w-full" />
        <div className="self-stretch flex flex-col items-start justify-start py-0 pr-3.5 pl-[13px] gap-[6px] text-base">
          <b className="w-[122px] h-6 relative inline-block z-[2]">
            Apply Voucher
          </b>
          <div className="self-stretch h-[35px] relative rounded-md bg-whitesmoke-300 z-[2]" />
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-4 pl-[13px] box-border min-h-[154px] max-w-full text-crimson-500 font-poor-story">
          <div className="flex-1 flex flex-col items-start justify-start gap-[9px] max-w-full">
            <div className="self-stretch flex flex-row items-end justify-between gap-[20px] mq450:flex-wrap">
              <nav className="m-0 h-[108px] w-[88px] flex flex-col items-start justify-start gap-[9px] text-left text-xl text-gray-700 font-poppins">
                <div className="w-[53px] flex-1 relative font-extrabold inline-block z-[2]">
                  Price
                </div>
                <div className="self-stretch flex-1 relative font-extrabold z-[2]">
                  Delivery
                </div>
                <div className="w-[39px] flex-1 relative font-extrabold inline-block z-[2]">
                  Tax
                </div>
              </nav>
              <div className="h-[103px] w-16 flex flex-col items-start justify-start gap-[14px]">
                <div className="self-stretch flex-1 relative z-[2]">
                  N 25000
                </div>
                <div className="self-stretch flex-1 relative z-[2]">
                  N 20000
                </div>
                <div className="w-[61px] flex-1 relative inline-block z-[2]">
                  N 15000
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-end justify-between gap-[20px] text-gray-700 font-poppins mq450:flex-wrap">
              <div className="h-[30px] w-[53px] relative font-extrabold inline-block z-[2]">
                Total
              </div>
              <div className="w-[63px] relative font-poor-story text-crimson-500 inline-block z-[2]">
                N 60000
              </div>
            </div>
          </div>
        </div>
        <div className="w-[342px] flex flex-row items-start justify-start py-0 pr-[33px] pl-5 box-border max-w-full text-white">
          <div
            className="h-[41px] flex-1 rounded-81xl bg-crimson-100 flex flex-row items-center justify-center pt-1.5 pb-[5px] pr-[41px] pl-10 box-border whitespace-nowrap cursor-pointer z-[2]"
            onClick={onDeliveryTaxFrameClick}
          >
            <div
              className="self-stretch flex-1 relative lowercase cursor-pointer"
              onClick={onProceedToCheckoutClick}
            >
              proceed to checkout
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Cart;
