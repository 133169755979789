/**
 * 
 */
import { Link } from 'react-router-dom';

import Nav from "../components/nav";
import Intro from "../components/into";
import SpecialOffers from "../components/special_offers";
import Service from "../components/services";
import Footer from "../components/footer";

const LandingPage = () => {
    return (
        <main>
            <Nav />
            <Intro />
            <SpecialOffers />
            <Service />
            <Footer />
        </main>
    );
};

export default  LandingPage;