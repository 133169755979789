import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const onPaymentMessageContainerClick = useCallback(() => {
    navigate("/order-tracking");
  }, [navigate]);

  const onHomeLinkContainerClick = useCallback(() => {
    navigate("/home-page");
  }, [navigate]);

  return (
    <div className="w-full relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] overflow-hidden flex flex-col items-center justify-start pt-0 pb-[219px] pr-[42px] pl-[41px] box-border gap-[7px]">
      <div className="w-[428px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[952px] hidden" />
      <img
        className="w-[148px] h-[111px] relative object-cover z-[1]"
        alt=""
        src="/chopinns-v3-3-2@2x.png"
      />
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0.5 pb-2.5">
        <img
          className="h-[341px] flex-1 relative max-w-full overflow-hidden object-cover z-[1]"
          loading="eager"
          alt=""
          src="/payment-successful-1@2x.png"
        />
      </section>
      <section className="flex flex-col items-center justify-start gap-[23px] text-left text-xl text-crimson-100 font-poppins">
        <h2 className="m-0 h-[54px] relative text-[36px] capitalize font-extrabold font-inherit inline-block z-[1]">
          Payment Success
        </h2>
        <div className="relative font-medium text-black text-center z-[1]">
          <p className="m-0">Your payment was successful.</p>
          <p className="m-0">Please wait for your order to arrive</p>
        </div>
        {/* <div
          className="w-[262px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center pt-1 px-[3px] pb-[3px] box-border cursor-pointer z-[1] text-white"
          onClick={onPaymentMessageContainerClick}
        >
          <div className="h-[30px] relative capitalize font-semibold inline-block">
            Track Order
          </div>
        </div> */}
        <div
          className="w-[266px] h-[41px] rounded-81xl box-border flex flex-row items-center justify-center pt-1 px-[3px] pb-[3px] cursor-pointer z-[1] border-[2px] border-solid border-crimson-100"
          onClick={onHomeLinkContainerClick}
        >
          <div className="h-[30px] relative capitalize font-semibold inline-block">
            Back Home
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentSuccess;
