import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/reset-password");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-center pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-smi text-neutral-black font-poppins">
      <div className="h-6 w-80 overflow-hidden shrink-0 hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border font-source-sans-pro">
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="h-[78.2px] w-[91.1px] relative hidden text-3xs text-midnightblue font-inter">
        <div className="absolute top-[0px] left-[calc(50%_-_25.45px)] inline-block w-[71px] h-[15px]">
          Remember me
        </div>
        <img
          className="absolute h-[18.16%] w-[14.27%] top-[81.84%] right-[85.73%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
      </div>
      <div className="h-[45px] w-[162px] relative hidden text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="h-[52px] w-[183px] relative hidden text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <main className="h-[772px] flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-[41px] pb-[386px] pr-12 pl-[45px] box-border relative gap-[59px] max-w-full text-left text-2xl text-grey-2 font-poppins mq450:pt-5 mq450:pb-[163px] mq450:box-border mq750:pt-[27px] mq750:pb-[251px] mq750:box-border">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full z-[0]" />
        <img
          className="w-[9.4px] h-[24.6px] absolute my-0 mx-[!important] right-[190px] bottom-[258.5px] z-[1]"
          loading="eager"
          alt=""
          src="/group-5.svg"
        />
        <div className="flex flex-row items-start justify-start py-0 pr-0 pl-[3px]">
          <div className="flex flex-col items-center justify-start gap-[23px]">
            <img
              className="w-[172px] h-[41px] relative object-cover z-[1]"
              loading="eager"
              alt=""
              src="/chopinns-v4-1-2@2x.png"
            />
            <div className="h-[26px] flex flex-row items-start justify-start py-0 px-0 box-border z-[1]">
              <h2 className="m-0 h-8 relative text-inherit font-black font-inherit inline-block">
                Forgotten Password
              </h2>
            </div>
          </div>
        </div>
        <section className="self-stretch flex-1 flex flex-col items-center justify-start gap-[19px] max-w-full text-left text-2xs text-black font-open-sans">
          <div className="self-stretch rounded-3xs bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] box-border flex flex-row items-center justify-start pt-[15px] px-[17px] pb-[18px] max-w-full z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[335px] relative rounded-3xs bg-white box-border hidden max-w-full border-[1px] border-solid border-crimson-100" />
            <input
              className="w-[241px] [border:none] [outline:none] font-medium font-inter text-sm bg-[transparent] h-3 relative text-goldenrod text-left inline-block z-[1]"
              placeholder="Provide your Email / Phone Number"
              type="text"
            />
          </div>
          <div className="relative tracking-[0.06em] z-[1]">
            An OTP Code will be sent to you to complete this action
          </div>
        </section>
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border max-w-full">
          <button
            className="cursor-pointer [border:none] py-[13px] pr-[95px] pl-20 bg-crimson-100 flex-1 rounded-xl flex flex-row items-center justify-center box-border max-w-full whitespace-nowrap z-[1] hover:bg-tomato-100 mq450:pl-5 mq450:pr-5 mq450:box-border"
            onClick={onGroupButtonClick}
          >
            <div className="h-[46px] w-[331px] relative rounded-xl bg-crimson-100 hidden max-w-full" />
            <b className="relative text-mini font-open-sans text-white text-left z-[1]">
              Reset your password
            </b>
          </button>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
