import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FRAMEHOMEPAGE from "../components/FRAMEHOMEPAGE";

import MealSearchBar from "../components/MealSearchBar";
import RestaurantCard from "../components/RestaurantCard";
import PopularMeals from "../components/PopularMeals";
import HomePageFooter from "../components/footer/HomePageFooter";

const HomePage2 = () => {
  const navigate = useNavigate();

  const onMenuIconClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  const onImage37Click = useCallback(() => {
    navigate("/food-details");
  }, [navigate]);

  const onSearchContainerClick = useCallback(() => {
    navigate("");
  }, [navigate]);

  return (
    <main className="flex justify-center">
      <div className="lg:max-w-[800px] md:max-w-[600px] flex flex-col">
        <div className="flex flex-col items-center justify-center">
          <FRAMEHOMEPAGE />
          <section className="self-stretch flex flex-col items-center justify-start p-6 gap-[24px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))]">
            <MealSearchBar />
            <RestaurantCard />
            <PopularMeals />
          </section> 
        </div>
        <HomePageFooter />
      </div>
    </main>
  );
};

export default HomePage2;
