import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Detail = () => {
  const navigate = useNavigate();

  const onForward4241333IconClick = useCallback(() => {
    navigate("/search1");
  }, [navigate]);

  const onRightArrow4IconClick = useCallback(() => {
    navigate("/checkout");
  }, [navigate]);

  const onNLabelContainerClick = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onAddToCartClick = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <div className="w-full h-[952px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] overflow-hidden hidden">
      <div className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] w-[428px] h-[952px] hidden" />
      <img
        className="absolute top-[117px] left-[81px] w-[266px] h-[266px] object-cover z-[1]"
        loading="eager"
        alt=""
        src="/mask-group1@2x.png"
      />
      <img
        className="absolute top-[46px] left-[375px] w-[29px] h-[29px] object-cover cursor-pointer z-[1]"
        loading="eager"
        alt=""
        src="/forward-4241333@2x.png"
        onClick={onForward4241333IconClick}
      />
      <img
        className="absolute top-[44px] left-[304px] w-[33px] h-8 object-cover hidden"
        alt=""
        src="/heart-11844540@2x.png"
      />
      <main className="absolute top-[40px] left-[24px] w-[350px] flex flex-col items-start justify-start gap-[366px]">
        <div className="w-[335px] flex flex-row items-start justify-between py-0 pr-5 pl-0 box-border">
          <img
            className="h-[18px] w-4 relative object-contain cursor-pointer z-[1]"
            loading="eager"
            alt=""
            src="/rightarrow-4@2x.png"
            onClick={onRightArrow4IconClick}
          />
          <div className="flex flex-col items-start justify-start pt-1.5 px-0 pb-0">
            <img
              className="w-[29px] h-[29px] relative object-cover z-[1]"
              loading="eager"
              alt=""
              src="/heart-2107845-1@2x.png"
            />
          </div>
        </div>
        <section className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[15px] text-left text-13xl text-gray-700 font-poppins">
          <div className="flex-1 flex flex-col items-center justify-start gap-[50px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[41px]">
              <div className="self-stretch h-[111px] flex flex-row items-start justify-between">
                <div className="self-stretch w-[143px] flex flex-col items-start justify-start">
                  <b className="self-stretch flex-1 relative leading-[32px] z-[1]">
                    <p className="m-0">{`Chicken `}</p>
                    <p className="m-0">Salad</p>
                  </b>
                  <div className="self-stretch h-[23px] relative text-mini font-light inline-block pr-[15px] z-[1]">
                    Chicken Republic
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[15px] pl-0 gap-[2px]">
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px] z-[1]"
                      loading="eager"
                      alt=""
                      src="/star-12902023-1@2x.png"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px] z-[1]"
                      loading="eager"
                      alt=""
                      src="/star-12902023-1@2x.png"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px] z-[1]"
                      loading="eager"
                      alt=""
                      src="/star-12902023-1@2x.png"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px] z-[1]"
                      loading="eager"
                      alt=""
                      src="/star-12902023-1@2x.png"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px] z-[2]"
                      loading="eager"
                      alt=""
                      src="/star-12902023-1@2x.png"
                    />
                  </div>
                </div>
                <div className="h-[71px] w-[86px] flex flex-col items-end justify-start gap-[6px] font-poor-story">
                  <div className="self-stretch flex-1 relative z-[1]">
                    N 5000
                  </div>
                  <div className="self-stretch h-[25px] flex flex-row items-end justify-start py-0 pr-[35px] pl-[9px] box-border text-5xl">
                    <div className="flex-1 rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-center py-0 pr-1 pl-1.5 z-[1]">
                      <div className="h-[21.1px] w-[76.6px] relative rounded-4xl bg-gainsboro-400 hidden" />
                      <div className="h-[21px] flex-1 relative">
                        <img
                          className="absolute top-[4.8px] left-[54.6px] w-[11.7px] h-[11.7px] object-cover z-[2]"
                          loading="eager"
                          alt=""
                          src="/plussign1@2x.png"
                        />
                        <img
                          className="absolute top-[0.9px] left-[0.7px] w-[13.3px] h-[19.5px] object-cover z-[2]"
                          loading="eager"
                          alt=""
                          src="/minussign1@2x.png"
                        />
                        <div className="absolute top-[0.1px] left-[21.8px] bg-snow-200 w-[23.4px] h-[21.1px] z-[2]" />
                      </div>
                    </div>
                    <div className="w-[7px] relative inline-block h-[23.4px] shrink-0 z-[3] ml-[-42px]">
                      1
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch h-[147px] relative text-sm font-medium inline-block pr-0.5 z-[1]">
                <p className="m-0">{`Chicken Salad description here with additional `}</p>
                <p className="m-0">{`info for the customer... `}</p>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipi
                </p>
                <p className="m-0">{`scing elit. Proin pulvinar lacinia viverra. Integer `}</p>
                <p className="m-0">
                  bibendum arcu tellus, ut consectetur odio trist
                </p>
                <p className="m-0">{`ique eget. Curabitur eget ante sit amet lectus `}</p>
                <p className="m-0">efficitur sollicitudin in ac justo.</p>
              </div>
            </div>
            <div className="w-[267px] flex flex-row items-start justify-start py-0 pr-7 pl-5 box-border text-xl text-white">
              <div
                className="h-[53px] flex-1 rounded-81xl bg-crimson-100 flex flex-row items-center justify-center pt-3 px-[11px] pb-[11px] box-border cursor-pointer z-[1]"
                onClick={onNLabelContainerClick}
              >
                <div
                  className="self-stretch w-28 relative lowercase inline-block cursor-pointer"
                  onClick={onAddToCartClick}
                >
                  add to Cart
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Detail;
