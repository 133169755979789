export default function RestaurantDetailsComp() {
    return (
        <section>
            <div className="flex flex-col items-center text-2xs text-gray-100 font-inter">
                <img
                    className="h-[250px] w-full object-cover"
                    loading="eager"
                    alt=""
                    src="/image-36@2x.png"
                />
                <div className="rounded bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_2px_5px_rgba(23,_26,_31,_0.13)] flex flex-col items-center justify-start p-4 gap-[12px] mt-[-60px]">
                    <div className="flex flex-row">
                        <p className="rounded-sm bg-honeydew-100 p-1">Hot Deal</p>
                        <p className="rounded-sm bg-[#FB6236]/10 p-1 text-[#FB6236]">Near you</p>
                    </div>
                    <p className="m-0 font-semibold text-5xl font-poppins">
                        Chicken Republic
                    </p>
                    <div className="flex flex-row gap-[25px] text-sm">
                        <div className="flex flex-row items-center gap-[6px]">
                            <img
                                className="h-4 w-4"
                                loading="eager"
                                alt="time icon"
                                src="/clock-1.svg"
                            />
                            <span>6am - 9pm</span>
                        </div>
                        <div className="flex flex-row items-center gap-[6px]">
                            <img
                                className="h-4 w-4"
                                loading="eager"
                                alt="distance icon"
                                src="/pin-3-1.svg"
                            />
                            <span>2 km</span>
                        </div>
                        <div className="flex flex-row items-center gap-[6px]">
                            <img
                                className="h-4 w-4"
                                loading="eager"
                                alt="price icon"
                                src="/tag-29.svg"
                            />
                            <span>N1500</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-start justify-start py-2 px-5 text-sm font-inter">
                <div className="w-full pr-[65px] flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-col items-start py-3 relative gap-[12px]">
                        <img
                            className="w-5 h-5 absolute  right-[-26px]"
                            loading="eager"
                            alt=""
                            src="/chevron-right-large-1.svg"
                        />
                        <div className="flex flex-row items-center justify-start gap-[6px]">
                            <img
                                className="h-5 w-5"
                                loading="eager"
                                alt=""
                                src="/star-1.svg"
                            />
                            <span>4.8 (289 reviews)</span>
                        </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start py-3 relative gap-[12px]">
                        <img
                            className="w-5 h-5 absolute  right-[-26px]"
                            loading="eager"
                            alt=""
                            src="/chevron-right-large-1.svg"
                        />
                        <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                                className="h-5 w-5"
                                loading="eager"
                                alt=""
                                src="/label-1.svg"
                            />
                            <span> 2 discount voucher for restaurant</span>
                        </div>
                        {/* <img
                            className="self-stretch relative max-w-full overflow-hidden max-h-full"
                            alt=""
                            src="/line-11.svg"
                        /> */}
                    </div>
                    <div className="self-stretch flex flex-col items-start py-3 relative gap-[12px]">
                        <img
                            className="w-5 h-5 absolute  right-[-26px]"
                            loading="eager"
                            alt=""
                            src="/chevron-right-large-1.svg"
                        />
                        <div className="flex flex-row items-center justify-start gap-[6px]">
                            <img
                                className="h-5 w-5"
                                loading="eager"
                                alt=""
                                src="/delivery-1.svg"
                            />
                            <span>Delivery in 20 mins</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}