import MainContainer from "../components/MainContainer";
import SearchBox from "../components/SearchBox";
import ServiceFrame from "../components/ServiceFrame";
import FoodtrayFrame from "../components/FoodtrayFrame";
import FrameComponent8 from "../components/FrameComponent8";
import FrameComponent7 from "../components/FrameComponent7";

const LandingPage1 = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[771px] box-border min-h-[5576px] tracking-[normal] text-center text-5xl text-crimson-100 font-poppins">
      {/* <MainContainer /> */}
      <img
        className="w-[596.6px] absolute my-0 mx-[!important] top-[-49px] right-[-217.6px] h-[782.4px] object-contain"
        alt=""
        src="/group-8438@2x.png"
      />
      <div className="w-[162px] h-[45px] relative hidden z-[2] text-left text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <img
        className="w-[13.7px] h-[8.7px] absolute my-0 mx-[!important] top-[233px] left-[350px] object-contain z-[1]"
        alt=""
        src="/arrow-1-stroke.svg"
      />
      <div className="w-[183px] h-[52px] relative hidden z-[4] text-left text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <SearchBox />
      <div className="w-[248.3px] h-[248.3px] relative rounded-[50%] box-border [transform:_rotate(178.69deg)] hidden z-[6] border-[16px] border-solid border-crimson-300" />
      <div className="w-[207px] relative capitalize font-black hidden z-[7] mq450:text-lgi">
        Spur Restaurant
      </div>
      <img
        className="w-[198px] relative h-[207px] hidden z-[8]"
        alt=""
        src="/group-8447.svg"
      />
      <div className="w-[248.3px] h-[248.3px] relative rounded-[50%] box-border [transform:_rotate(178.69deg)] hidden z-[9] border-[16px] border-solid border-crimson-300" />
      <div className="w-[207px] relative capitalize font-black hidden z-[10] mq450:text-lgi">
        Spur Restaurant
      </div>
      <img
        className="w-[198px] relative h-[207px] hidden z-[11]"
        alt=""
        src="/group-8447.svg"
      />
      <div className="w-[248.3px] h-[248.3px] relative rounded-[50%] box-border [transform:_rotate(178.69deg)] hidden z-[12] border-[16px] border-solid border-crimson-300" />
      <div className="w-[207px] relative capitalize font-black hidden z-[13] mq450:text-lgi">
        Spur Restaurant
      </div>
      <img
        className="w-[198px] relative h-[207px] hidden z-[14]"
        alt=""
        src="/group-8447.svg"
      />
      <div className="w-[248.3px] h-[248.3px] relative rounded-[50%] box-border [transform:_rotate(178.69deg)] hidden z-[15] border-[16px] border-solid border-crimson-300" />
      <div className="w-[207px] relative capitalize font-black hidden z-[16] mq450:text-lgi">
        Spur Restaurant
      </div>
      <img
        className="w-[198px] relative h-[207px] hidden z-[17]"
        alt=""
        src="/group-8447.svg"
      />
      <div className="w-[248.3px] h-[248.3px] relative rounded-[50%] box-border [transform:_rotate(178.69deg)] hidden z-[18] border-[16px] border-solid border-crimson-300" />
      <div className="w-[207px] relative capitalize font-black hidden z-[19] mq450:text-lgi">
        Spur Restaurant
      </div>
      <img
        className="w-[198px] relative h-[207px] hidden z-[20]"
        alt=""
        src="/group-8447.svg"
      />
      <div className="w-[248.3px] h-[248.3px] relative rounded-[50%] box-border [transform:_rotate(178.69deg)] hidden z-[21] border-[16px] border-solid border-crimson-300" />
      <div className="w-[207px] relative capitalize font-black hidden z-[22] mq450:text-lgi">
        Spur Restaurant
      </div>
      <img
        className="w-[198px] relative h-[207px] hidden z-[23]"
        alt=""
        src="/group-8447.svg"
      />
      <div className="w-[1057px] hidden flex-row flex-wrap items-start justify-start gap-[30px] max-w-full z-[24] text-left text-lg text-style">
        <div className="h-[47px] rounded-81xl bg-crimson-100 flex flex-row items-start justify-start py-2.5 px-5 box-border text-white">
          <div className="self-stretch relative capitalize">Ramen</div>
        </div>
        <div className="flex-1 rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] min-w-[81px] border-[2px] border-solid border-style">
          <div className="h-[27px] flex-1 relative capitalize inline-block opacity-[0.8]">
            Breakfast
          </div>
        </div>
        <div className="h-[47px] rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] border-[2px] border-solid border-style">
          <div className="self-stretch relative capitalize opacity-[0.8]">
            Lunch
          </div>
        </div>
        <div className="h-[47px] rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] border-[2px] border-solid border-style">
          <div className="self-stretch relative capitalize opacity-[0.8]">
            Dinner
          </div>
        </div>
        <div className="flex-1 rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] min-w-[76px] border-[2px] border-solid border-style">
          <div className="h-[27px] flex-1 relative capitalize inline-block opacity-[0.8]">
            Maxican
          </div>
        </div>
        <div className="h-[47px] rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] border-[2px] border-solid border-style">
          <div className="self-stretch relative capitalize opacity-[0.8]">
            Italian
          </div>
        </div>
        <div className="flex-1 rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] min-w-[76px] border-[2px] border-solid border-style">
          <div className="h-[27px] flex-1 relative capitalize inline-block opacity-[0.8]">
            Desserts
          </div>
        </div>
        <div className="h-[47px] rounded-81xl box-border flex flex-row items-start justify-start py-2.5 pr-[22px] pl-[18px] opacity-[0.6] border-[2px] border-solid border-style">
          <div className="self-stretch relative capitalize opacity-[0.8]">
            Drinks
          </div>
        </div>
      </div>
      <section className="self-stretch flex flex-row items-start justify-center py-0 pr-[21px] pl-5 box-border min-h-[163px] max-w-full text-center text-29xl text-crimson-100 font-poppins">
        <h1 className="m-0 h-36 w-[653px] relative text-inherit capitalize font-bold font-inherit inline-block shrink-0 max-w-full mq450:text-10xl mq1050:text-19xl">
          <span>Services</span>
          <span className="text-style">{` to `}</span>
          <span className="text-style1">satisfy</span>
          <span className="text-style">{` Your `}</span>
          <span>Cravings</span>
        </h1>
      </section>
      <div className="w-[550px] h-[81px] relative text-lg text-style whitespace-pre-wrap text-left hidden opacity-[0.8] max-w-full z-[26]">
        All you need to do is downlode one of the best delivery apps, make a and
        most companies are opting for mobile app devlopment for food delivery
      </div>
      <section className="self-stretch flex flex-row items-start justify-end py-0 pr-[55px] pl-0 box-border min-h-[2048px] max-w-full lg:pr-[27px] lg:box-border">
        <div className="w-[1453px] flex flex-col items-end justify-start gap-[92px] max-w-[105%] shrink-0 mq450:gap-[23px] mq750:gap-[46px]">
          <ServiceFrame />
          <FoodtrayFrame />
        </div>
      </section>
      <FrameComponent8 />
      <img
        className="w-[135px] h-10 relative rounded-8xs overflow-hidden shrink-0 hidden mix-blend-normal z-[29]"
        alt=""
        src="/mobile-app-store-badge.svg"
      />
      <img
        className="w-[120px] h-10 relative rounded-6xs hidden mix-blend-normal z-[30]"
        alt=""
        src="/mobile-app-store-badge.svg"
      />
      <section className="self-stretch flex flex-row items-start justify-start py-0 px-[66px] box-border min-h-[498px] max-w-full text-left text-29xl text-black font-poppins mq1050:pl-[33px] mq1050:pr-[33px] mq1050:box-border">
        <div className="w-[643px] flex flex-col items-start justify-start gap-[42px] max-w-full mq750:gap-[21px]">
          <h1 className="m-0 self-stretch relative text-inherit font-bold font-inherit whitespace-pre-wrap z-[1] mq450:text-10xl mq1050:text-19xl">
            <span>{`You’ve come long way. `}</span>
            <span className="text-crimson-100">Don’t Starve,</span>
            <span>{` Get  `}</span>
            <span className="text-style1">{`50%  `}</span>
            <span>{`Off Your `}</span>
            <span className="text-crimson-100">First Order</span>
          </h1>
          <div className="flex flex-row items-start justify-start py-0 px-[11px]">
            <button className="cursor-pointer [border:none] py-[9px] pr-[22px] pl-[19px] bg-crimson-100 rounded-81xl flex flex-row items-center justify-center whitespace-nowrap z-[1] hover:bg-tomato-100">
              <div className="h-[45px] w-[237px] relative rounded-81xl bg-crimson-100 hidden" />
              <div className="h-[27px] relative text-lg capitalize font-semibold font-poppins text-white text-left inline-block z-[1]">
                Place your First Order
              </div>
            </button>
          </div>
        </div>
      </section>
      <FrameComponent7 />
      <div className="self-stretch h-[-404px] flex flex-row items-start justify-end py-0 pr-1 pl-0 box-border max-w-full">
        <div className="self-stretch flex-1 relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0),_rgba(253,_197,_94,_0.1))] max-w-full" />
      </div>
    </div>
  );
};

export default LandingPage1;
