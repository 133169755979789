import Container3 from "../components/Container3";
import FrameComponent1 from "../components/FrameComponent1";
import Container2 from "../components/Container2";
import Container1 from "../components/Container1";
import AlsoOrderedContainer from "../components/AlsoOrderedContainer";
import EwalletForBillPayment from "../components/EwalletForBillPayment";
import PaymentOptions from "../components/PaymentOptions";
import Container from "../components/Container";

const OrderReview = () => {
  return (
    <div className="w-full relative bg-snow-400 shadow-[0px_3px_6px_rgba(18,_15,_40,_0.12)] overflow-hidden flex flex-col items-center justify-start gap-[24px] tracking-[normal]">
      <Container3 />
      {/* <FrameComponent1 /> */}
      <section className="self-stretch flex flex-col items-start justify-start py-0 pr-[22px] pl-5 box-border gap-[16px] max-w-full text-left text-base text-darkslategray-200 font-poppins">
        <div className="w-[339px] flex flex-row items-center justify-between gap-[20px] max-w-full mq450:flex-wrap">
          <div className="relative leading-[26px] font-semibold">
            Order details
          </div>
          <div className="relative text-xs leading-[20px] font-inter text-new-2">
            Add more
          </div>
        </div>
        <Container2 />
        <img
          className="w-[335px] relative max-h-full max-w-full"
          loading="eager"
          alt=""
          src="/line-11.svg"
        />
        <Container1 />
      </section>
      <AlsoOrderedContainer />
      <EwalletForBillPayment />
      <PaymentOptions />
      <Container />
    </div>
  );
};

export default OrderReview;
