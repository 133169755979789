import React, { useState } from 'react';

import chopinns_logo from "../../assets/img/chopinns_logo.png"

export default function Nav (){
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav>
            <div class="hidden md:flex justify-between p-4">
                <a href="/" class="flex items-center space-x-3">
                    <img src={chopinns_logo} class="h-8" alt="chop inns Logo" />
                </a>
                <div class="flex font-poppins font-medium">
                    <ul class="flex flex-row text-l p-4 md:p-0 mt-4 md:space-x-8">
                        <li>
                            <a href="#special-offers" class="block my-3 mx-2" >Special Offers</a>
                        </li>
                        <li>
                            <a href="#services" class="block my-3 mx-2 ">Services</a>
                        </li>
                        <li class="bg-[#F54748] rounded-81xl">
                            <a href="/sign-up" class="flex my-3 px-2 text-[#fff]">Sign In</a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Hamburger button */}
            <div className="md:hidden flex flex-col items-end p-4">
                <button
                    className="flex"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>

                {/* Responsive menu */}
                {isOpen && (
                    <div className="md:hidden mt-2 w-48 bg-white border rounded-lg shadow-lg font-poppins font-medium">
                        <ul className='flex flex-col text-l p-4'>
                            <li className="px-4 py-2">
                                <a href="#special-offers" class="block" >Special Offers</a>
                            </li>
                            <li className="px-4 py-2">
                                <a href="#services" class="block">Services</a>
                            </li>
                            <li className="px-4 py-2 bg-[#F54748] rounded-xl">
                                <a href="/sign-up" class="flex text-[#fff]">Sign In</a>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
};