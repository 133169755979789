/**
 * Special Offers Component
 */
import DeliveryServiceFrame from "../DeliveryServiceFrame";

import service_img_0 from "../../assets/img/service_0.png"
import service_img_0_m from "../../assets/img/service_0_m.png"

import s_events from "../../assets/img/s_events.png"
import s_groceries from "../../assets/img/s_groceries.png"
import s_order from "../../assets/img/s_order.png"
import s_recipe from "../../assets/img/s_recipe.png"
import s_reservation from "../../assets/img/s_reservation.png"

import credit_icon from "../../assets/img/credit_icon.png"
import events_icon from "../../assets/img/events_icon.png"
import groceries_icon from "../../assets/img/groceries_icon.png"
import order_icon from "../../assets/img/order_icon.png"
import recipe_icon from "../../assets/img/recipe_icon.png"
import reserved_icon from "../../assets/img/reserved_icon.png"

import burger_king from "../../assets/img/burger_king.png"
import domions from "../../assets/img/dominos.png"
import kfc from "../../assets/img/kfc.png"
import pizza_hut from "../../assets/img/pizza_hut.png"
import place from "../../assets/img/place.png"
import spur from "../../assets/img/spur.png"


export default function SpecialOffers() {
    return (

        <section id="special-offers" class="justify-between my-4 p-4">
            <div class=" mt-4">
                <p class="mb-4 text-xl text-center"><span class="text-[#F54748]">Special</span> Offers For <span class="text-[#FDC55E]">You</span></p>
                <p class="mb-4 text-l font-normal text-center">Discover The Perfect Deal For Your Cravings Now.</p>
            </div>
            {/* <div className="flex flex-row justify-between">
        < DeliveryServiceFrame
          onlineOrderButton="1,500"
          bellefulJollof="Belleful  Jollof"
          steamJollofRiceWith2Soulf="Steam Jollof Rice with 2 Soulfully spiced chicken"
          propHeight="36px"
        />
        <DeliveryServiceFrame
          onlineOrderButton="2,800"
          bellefulJollof={`Chicken & Chips`}
          steamJollofRiceWith2Soulf="Fried chips with chicken, hot chilli Sauce"
          propMarginLeft="-1px"
          propHeight="unset"
        />
        <div class="hidden md:block">
        <DeliveryServiceFrame
          onlineOrderButton="1,800"
          bellefulJollof={`Asun Spagheti`}
          steamJollofRiceWith2Soulf="Smokey Hot Spaghetti & Peppered Asun   "
          propMarginLeft="-1px"
          propHeight="unset"
        />
        </div>
        <div class="hidden md:block">
        <DeliveryServiceFrame
          onlineOrderButton="1,500"
          bellefulJollof={`Pro Ewa Agoyin`}
          steamJollofRiceWith2Soulf="Ewa Agoyin with Fried Fish, Pomo & Dodo"
          propMarginLeft="-1px"
          propHeight="unset"
        />
        </div>
      </div> */}
            {/* desktop view */}
            <div class="hidden md:flex">
                <div class="flex flex-wrap justify-evenly py-4 m-4 text-[#FFF] text-lg font-normal">
                    <div class="relative  m-4 flex justify-center">
                        <img class="w-[300px] pb-3 object-contain" src={s_order} alt="Online Orders Chop Inns" />
                        <span class="absolute bottom-0 bg-[#F54748] rounded-[14px]">
                            <a href="#" class="mx-2 text-[#fff]">Order Online</a>
                        </span>
                    </div>
                    <div class="relative m-4 flex justify-center">
                        <img class="w-[300px] pb-3 object-contain" src={s_reservation} alt="Restaurant Reservation Chop Inns" />
                        <span class="absolute bottom-0 bg-[#F54748] rounded-[14px]">
                            <a href="#" class="mx-2 text-[#fff]">Restaurant Reservation</a>
                        </span>
                    </div>
                    <div class="relative m-4 flex justify-center">
                        <img class="w-[300px] pb-3 object-contain" src={s_order} alt="Online Orders Chop Inns" />
                        <span class="absolute bottom-0 bg-[#F54748] rounded-[14px]">
                            <a href="#" class="mx-2 text-[#fff]" >Order Online</a>
                        </span>
                    </div>
                    <div class="relative m-4 flex justify-center">
                        <img class="w-[300px] pb-3 object-contain" src={s_groceries} alt="Groceries Chop Inns" />
                        <span class="absolute bottom-0 bg-[#F54748] rounded-[14px]">
                            <a href="#" class="mx-2 text-[#fff]">Groceries</a>
                        </span>
                    </div>
                    <div class="relative m-4 flex justify-center">
                        <img class="w-[300px] pb-3 object-contain" src={s_recipe} alt="Recipe Generation Chop Inns" />
                        <span class="absolute bottom-0 bg-[#F54748] rounded-[14px]">
                            <a href="#" class="mx-2 text-[#fff]">Recipe Generation</a></span>
                    </div>
                    <div class="relative m-4 flex justify-center">
                        <img class="w-[300px] pb-3 object-contain" src={s_events} alt="competitions and events chop inns" />
                        <span class="absolute bottom-0 bg-[#F54748] rounded-[14px]">
                            <a href="#" class="mx-2 text-[#fff]">Competitions & Events</a>
                        </span>
                    </div>
                </div>
            </div>
            {/* mobile view */}
            <div class="md:hidden">
                {/* <div class="mt-4">
                    <p class="mb-4 text-xl text-center"><span class="text-[#F54748]">Services</span> To <span class="text-[#FDC55E]">Satisfy</span> Your</p>
                    <p class="mb-4 text-xl text-center"><span class="text-[#F54748]">Cravings</span></p>
                </div> */}
                <div class="flex flex-row mt-6 justify-evenly">
                    <a href="#"><img src={spur} alt="order from spur" /></a>
                    <a href="#"><img src={kfc} alt="order from kfc" /></a>
                </div>
                <div class="flex flex-row mt-6 justify-evenly">
                    <a href="#"><img src={burger_king} alt="order from spur" /></a>
                    <a href="#"><img src={place} alt="order from kfc" /></a>
                </div>
                <div class="flex flex-row mt-6 justify-evenly">
                    <a href="#"><img src={pizza_hut} alt="order from spur" /></a>
                    <a href="#"><img src={domions} alt="order from kfc" /></a>
                </div>
            </div>
        </section>
    )
}