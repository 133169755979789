const FeedbackFrame = () => {
  return (
    <div className="w-full my-0 mx-[!important] absolute right-[0px] bottom-[1px] left-[0px] flex flex-col items-center justify-start pt-[83px] px-[25px] pb-[41px] box-border gap-[20px] max-w-full text-center text-base text-grey-2 font-poppins">
      <div className="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]">
        <div className="absolute top-[0px] left-[38px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] bg-cover bg-no-repeat bg-[top] z-[1]">
          <img
            className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-full h-full object-cover hidden"
            alt=""
            src="/rectangle-25@2x.png"
          />
          <div className="absolute top-[12px] left-[196px] w-4 h-4">
            <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-full h-full z-[2]" />
            <img
              className="absolute top-[5px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
              loading="eager"
              alt=""
              src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
            />
          </div>
        </div>
        <div className="absolute top-[47px] left-[0px] rounded-t-45xl rounded-b-none bg-white box-border w-[428px] h-[509px] z-[6] border-[1px] border-solid border-gray-1000" />
      </div>
      <div className="self-stretch flex flex-col items-center justify-start py-0 px-[18px] box-border gap-[15px] min-h-[89px]">
        <h2 className="m-0 self-stretch relative text-inherit font-bold font-inherit z-[7]">
          Kindly drop a rating for the just completed order
        </h2>
        <div className="flex flex-row items-start justify-start py-0 pr-[11px] pl-0 text-left text-sm">
          <div className="h-[21px] relative whitespace-pre-wrap inline-block shrink-0 z-[7]">
            Ordered on Jan 19 at 13:00
          </div>
        </div>
      </div>
      <div className="w-72 flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border">
        <div className="flex-1 flex flex-row items-start justify-between gap-[20px] z-[7]">
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-8.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-8.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-8.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-10.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-10.svg"
          />
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-sm">
        <div className="flex flex-row items-start justify-start py-0 px-[19px]">
          <h3 className="m-0 h-[21px] relative text-inherit font-bold font-inherit inline-block z-[7]">
            Your Comment
          </h3>
        </div>
        <textarea
          className="bg-white h-36 w-auto [outline:none] self-stretch rounded-2xl box-border flex flex-row items-start justify-start py-5 px-[26px] font-poppins text-sm text-darkslategray-500 z-[7] border-[1px] border-solid border-crimson-600"
          placeholder="Write your comment"
          rows={7}
          cols={19}
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
        <div className="h-0 w-0 absolute my-0 mx-[!important] top-[NaNpx] right-[378px]">
          <img
            className="absolute top-[NaNpx] left-[NaNpx] w-[115px] h-[61px] object-cover hidden"
            alt=""
            src="/pngegg-42-1@2x.png"
          />
          <div className="absolute top-[NaNpx] left-[NaNpx] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] hidden z-[1]" />
          <img
            className="absolute top-[NaNpx] left-[NaNpx] rounded-12xs w-[15px] h-3.5 hidden z-[2]"
            alt=""
            src="/vector.svg"
          />
          <img
            className="absolute top-[NaNpx] left-[NaNpx] w-24 h-[78px] object-cover hidden"
            alt=""
            src="/pizzapng19310-1@2x.png"
          />
          <div className="absolute top-[NaNpx] left-[NaNpx] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] hidden z-[1]" />
          <img
            className="absolute top-[NaNpx] left-[NaNpx] rounded-12xs w-[15px] h-3.5 hidden z-[2]"
            alt=""
            src="/vector.svg"
          />
        </div>
        <button className="cursor-pointer pt-3.5 pb-[13px] pr-[23px] pl-5 bg-crimson-100 flex-1 rounded-2xl box-border flex flex-row items-center justify-center max-w-full z-[7] border-[1px] border-solid border-gray-1000 hover:bg-tomato-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400">
          <div className="h-[63px] w-[378px] relative rounded-2xl bg-crimson-100 box-border hidden max-w-full border-[1px] border-solid border-gray-1000" />
          <b className="h-9 relative text-5xl inline-block font-poppins text-snow-500 text-left z-[8]">
            Send
          </b>
        </button>
      </div>
    </div>
  );
};

export default FeedbackFrame;
