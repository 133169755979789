/**
 * 
 */

export default function MealCard({ image, title, rating, price }) {
    return (
        <div className="rounded m-2 bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)]">
            <a href="/food-details">
            <img
                className="w-[120px] sm:w-[130px] object-cover cursor-pointer"
                loading="eager"
                alt=""
                src={image}
            />
            <span className="block text-md px-2 m-0 font-medium text-darkslategray-200">
                {title}
            </span>
            <span className="flex flex-row px-2 items-start justify-start">
                <img
                    className=""
                    loading="eager"
                    alt=""
                    src="/rating-131.svg"
                />
                <span className="m-0 text-2xs md:text-xs font-semibold">
                    {rating}
                    <span className="text-lightslategray-200">(99)</span>
                    <span className="text-3xs font-bold pl-3 md:pl-3 md:text-xs ">{price}</span>
                </span>
            </span>
            </a>
        </div>
    );
}