import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameB from "../components/FrameB";
import AmountNFrame from "../components/AmountNFrame";
import FrameComponent4 from "../components/FrameComponent4";

const Payment = () => {
  const navigate = useNavigate();

  const onRightArrow2IconClick = useCallback(() => {
    // navigate("/checkout");
    navigate(-1);
  }, [navigate]);

  const onCardInfoFrameClick = useCallback(() => {
    navigate("/payment-success");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-center pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-mini text-black font-poppins">
      <b className="w-[110px] relative hidden opacity-[0.83]">Popular Meals</b>
      <b className="w-[140px] relative hidden opacity-[0.83]">
        Previously viewed
      </b>
      <div className="h-[35px] w-[381px] relative hidden max-w-full text-base">
        <div className="absolute top-[0px] left-[0px] rounded-md bg-gainsboro-200 w-full h-full" />
        <b className="absolute top-[6px] left-[44px] opacity-[0.38]">Search</b>
        <img
          className="absolute top-[9px] left-[14px] w-[18px] h-[18px] object-cover"
          alt=""
          src="/search-149852-1@2x.png"
        />
      </div>
      <div className="h-[45px] w-[376px] relative hidden max-w-full text-base">
        <div className="absolute top-[7px] left-[135px] font-semibold">
          Hi Jane Doe,
        </div>
        <img
          className="absolute top-[0px] left-[331px] w-[45px] h-[45px] object-cover"
          alt=""
          src="/mask-group@2x.png"
        />
        <img
          className="absolute top-[8px] left-[0px] w-[30px] h-[30px] object-cover"
          alt=""
          src="/menu-2976215-1@2x.png"
        />
      </div>
      <div className="h-[243px] w-[428px] relative bg-gainsboro-400 hidden max-w-full" />
      <div className="h-[147px] w-[379px] relative hidden max-w-full text-xl">
        <div className="absolute top-[0px] left-[1px] font-extrabold opacity-[0.83]">
          Price
        </div>
        <div className="absolute top-[39px] left-[1px] font-extrabold opacity-[0.83]">
          Delivery
        </div>
        <div className="absolute top-[78px] left-[0px] font-extrabold opacity-[0.83]">
          Tax
        </div>
        <div className="absolute top-[117px] left-[0px] font-extrabold opacity-[0.83]">
          Total
        </div>
        <div className="absolute top-[5px] left-[316px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 25000
        </div>
        <div className="absolute top-[44px] left-[316px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 20000
        </div>
        <div className="absolute top-[83px] left-[315px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 15000
        </div>
        <div className="absolute top-[122px] left-[315px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 60000
        </div>
      </div>
      <main className="flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-[30px] pb-[23px] pr-[22px] pl-[23px] box-border gap-[25px] max-w-full shrink-0 text-left text-xl text-gray-700 font-poppins mq750:pt-5 mq750:pb-5 mq750:box-border">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start py-0 px-0.5 box-border">
          <div className="w-[220px] flex flex-row items-center justify-between gap-[20px]">
            <img
              className="h-4 w-4 relative object-contain cursor-pointer z-[1]"
              loading="eager"
              alt=""
              src="/rightarrow-2@2x.png"
              onClick={onRightArrow2IconClick}
            />
            <div className="h-[30px] relative font-medium inline-block z-[1]">
              Payment
            </div>
          </div>
        </div>
        <FrameB />
        <AmountNFrame />
        <section className="self-stretch h-[111px] flex flex-row items-start justify-start py-0 px-px box-border relative max-w-full text-left text-base text-gray-900 font-poppins">
          <input
            className="[border:none] [outline:none] bg-whitesmoke-300 h-[35px] w-[calc(100%_-_2px)] absolute my-0 mx-[!important] right-[2px] bottom-[-33px] left-[0px] rounded-md z-[2]"
            type="text"
          />
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[6px] max-w-full">
            <b className="h-6 relative inline-block z-[1]">Name on card</b>
            <div className="self-stretch flex flex-col items-start justify-start gap-[15px]">
              <input
                className="[border:none] [outline:none] bg-whitesmoke-300 self-stretch h-[35px] relative rounded-md min-w-[229px] z-[1]"
                type="text"
              />
              <b className="h-6 relative inline-block z-[1]">Card number</b>
            </div>
          </div>
        </section>
        <FrameComponent4 />
        <div className="w-[307px] flex flex-row items-start justify-start py-0 pr-[18px] pl-0 box-border">
          <button
            className="cursor-pointer [border:none] pt-1.5 px-[5px] pb-[5px] bg-crimson-100 flex-1 rounded-81xl flex flex-row items-center justify-center whitespace-nowrap z-[1] hover:bg-tomato-100"
            onClick={onCardInfoFrameClick}
          >
            <div className="relative text-xl lowercase font-poppins text-white text-left">
              confirm payment
            </div>
          </button>
        </div>
      </main>
    </div>
  );
};

export default Payment;
