import React from 'react';
import { useNavigate } from 'react-router-dom';
import img45 from "../assets/img/Image45.png"

const FrameFoodDetails = () => {
  const navigate = useNavigate();
  // const history = useHistory();

  const handleBackClick = () => {
    // history.goBack(); // Navigates back to the previous page
    navigate(-1);
  };
  return (
    <section className="self-stretch flex flex-col items-start justify-start text-left text-2xs text-white font-inter">
      <div style={{backgroundImage: `url(${img45})`}} className="self-stretch flex flex-col items-start justify-start pt-0 pb-[132px] pr-[7px] pl-0 gap-[10px] bg-cover bg-no-repeat bg-[top]">
        {/* <img
          className="self-stretch relative max-w-full overflow-hidden max-h-full object-cover hidden"
          alt=""
          src="/image-45@2x.png"
        /> */}
        {/* <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="h-10 overflow-hidden flex flex-col items-end justify-start pt-[17px] pb-0 pr-[15px] pl-[30px] box-border gap-[24px] z-[1]">
            <img
              className="w-[27.3px] h-[10.7px] relative"
              loading="eager"
              alt=""
              src="/group.svg"
            />
            <div className="h-0 flex flex-row items-start justify-start py-0 px-[7px] box-border">
              <img className="h-0 w-0 relative" loading="eager" alt="" />
            </div>
          </div>
          <div className="w-24 overflow-hidden shrink-0 flex flex-col items-start justify-center pt-[17px] pb-[13px] pr-[18px] pl-3 box-border relative z-[1]">
            <img
              className="self-stretch h-[10.6px] relative max-w-full overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/group-2.svg"
            />
            <img
              className="w-0 h-0 absolute my-0 mx-[!important] right-[45.5px] bottom-[-10.5px]"
              loading="eager"
              alt=""
            />
          </div>
        </div> */}
        <div className="h-7 flex flex-row items-start justify-start py-0 px-5 box-border">
          
          <div className="self-stretch rounded-sm bg-lightslategray-100 overflow-hidden flex flex-row items-center justify-start py-[5px] px-2 z-[1]">
            <img onClick={handleBackClick}
              className="h-3 w-3 relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/e-remove.svg"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch rounded bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] flex flex-row items-start justify-between py-5 pr-[13px] pl-5 gap-[20px] z-[1] mt-[-4px] text-base text-gray-100 font-poppins mq450:flex-wrap">
        <div className="flex flex-col items-start justify-start">
          <b className="relative leading-[26px] inline-block pr-[7px]">
            Jollof Rice
          </b>
          <div className="relative text-sm leading-[22px] text-lightslategray-100">
            with Veggies
          </div>
        </div>
        <div className="w-[74px] flex flex-col items-start justify-start text-right">
          <b className="self-stretch relative leading-[26px] inline-block pr-[18px]">
            N1,000
          </b>
          <div className="relative text-sm leading-[22px] text-lightslategray-100 text-left">
            Base price
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameFoodDetails;
