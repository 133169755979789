import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const navigate = useNavigate();

  const onPopularMealsContainerClick = useCallback(() => {
    navigate("/detail");
  }, [navigate]);

  const onDrumstixContainerClick = useCallback(() => {
    navigate("/detail");
  }, [navigate]);

  const onGroupContainer1Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <div className="w-full h-[952px] relative bg-white overflow-hidden hidden text-left text-2xs text-gray-700 font-poppins">
      <div className="absolute top-[768px] left-[25px] w-[380px] flex flex-row items-start justify-start py-0 pr-px pl-0 box-border gap-[11px] z-[1]">
        <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 gap-[4px]">
          <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
          <img
            className="w-[65px] h-[65px] relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/mask-group-11@2x.png"
          />
          <div className="self-stretch h-12 flex flex-col items-center justify-start py-0 px-3.5 box-border">
            <div className="self-stretch h-[17px] relative inline-block z-[1]">
              Chicken Salad
            </div>
            <div className="self-stretch flex-1 flex flex-col items-center justify-start py-0 px-2.5 gap-[4px] text-3xs text-crimson-100 font-pt-sans-narrow">
              <div className="self-stretch flex-1 relative z-[1]">
                Chicken Republic
              </div>
              <div className="w-[30px] flex-1 relative text-2xs font-poor-story text-gray-700 inline-block z-[1]">
                N 5000
              </div>
            </div>
          </div>
          <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
            <div className="self-stretch w-[53px] relative capitalize inline-block">
              Order Now
            </div>
          </div>
        </div>
        <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 gap-[3px]">
          <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
          <img
            className="w-[65px] h-[65px] relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/mask-group-4@2x.png"
          />
          <div className="w-[71px] h-[17px] relative inline-block pl-5 pr-5 z-[1]">
            Pasta
          </div>
          <div className="w-[72px] h-[13px] relative text-3xs font-pt-sans-narrow text-crimson-100 inline-block pl-5 pr-5 z-[1]">
            Drumstix
          </div>
          <div className="w-[70px] h-3.5 relative font-poor-story inline-block pl-5 pr-5 z-[1]">
            N 3000
          </div>
          <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
            <div className="self-stretch w-[53px] relative capitalize inline-block">
              Order Now
            </div>
          </div>
        </div>
        <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 gap-[3px]">
          <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
          <img
            className="w-[65px] h-[65px] relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/mask-group-21@2x.png"
          />
          <div className="w-[78px] h-[17px] relative inline-block z-[1]">
            Nigerian Jollof
          </div>
          <div className="w-[66px] h-[13px] relative text-3xs font-pt-sans-narrow text-crimson-100 inline-block pl-5 pr-5 z-[1]">
            Iya Oyo
          </div>
          <div className="w-[71px] h-3.5 relative font-poor-story inline-block pl-5 pr-5 z-[1]">
            N 4000
          </div>
          <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
            <div className="self-stretch w-[53px] relative capitalize inline-block">
              Order Now
            </div>
          </div>
        </div>
      </div>
      <main className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] w-[428px] flex flex-col items-center justify-start pt-7 pb-[9px] pr-[23px] pl-6 box-border gap-[31px] text-left text-base text-black font-poppins">
        <div className="w-[428px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[772px] hidden" />
        <div className="self-stretch h-[54px] flex flex-row items-start justify-end py-0 px-[5px] box-border">
          <div className="w-[241px] flex flex-row items-start justify-between">
            <div className="h-[31px] w-[100px] flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border">
              <h2 className="m-0 self-stretch flex-1 relative text-inherit font-semibold font-inherit z-[1]">
                Hi Jane Doe,
              </h2>
            </div>
            <img
              className="h-[45px] w-[45px] relative object-cover z-[1]"
              loading="eager"
              alt=""
              src="/mask-group@2x.png"
            />
          </div>
        </div>
        <section className="self-stretch flex flex-col items-start justify-start gap-[27px] text-left text-5xl text-gray-700 font-poppins">
          <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            <h1 className="m-0 w-[205px] h-[72px] relative text-inherit font-bold font-inherit inline-block z-[1]">
              <p className="m-0">{`What would you `}</p>
              <p className="m-0">like to eat?</p>
            </h1>
            <div className="self-stretch flex flex-col items-start justify-start gap-[15px] text-base text-gray-600">
              <div className="self-stretch h-[35px] rounded-md bg-gainsboro-200 flex flex-row items-center justify-start pt-1.5 px-3.5 pb-[5px] box-border gap-[12px] z-[1]">
                <div className="h-[35px] w-[381px] relative rounded-md bg-gainsboro-200 hidden" />
                <img
                  className="h-[18px] w-[18px] relative object-cover z-[1]"
                  loading="eager"
                  alt=""
                  src="/search-149852-1@2x.png"
                />
                <h2 className="m-0 self-stretch w-[113px] relative text-inherit font-bold font-inherit inline-block z-[1]">
                  Search meals
                </h2>
              </div>
              <div className="self-stretch h-[35px] rounded-md bg-gainsboro-200 flex flex-row items-center justify-start pt-1.5 px-3.5 pb-[5px] box-border gap-[12px] z-[1]">
                <div className="h-[35px] w-[381px] relative rounded-md bg-gainsboro-200 hidden" />
                <img
                  className="h-[18px] w-[18px] relative object-cover z-[1]"
                  loading="eager"
                  alt=""
                  src="/search-149852-1@2x.png"
                />
                <h2 className="m-0 self-stretch w-[159px] relative text-inherit font-bold font-inherit inline-block z-[1]">
                  Search restaurants
                </h2>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-mini text-gray-700">
                <h3 className="m-0 w-[110px] h-[23px] relative text-inherit font-bold font-inherit inline-block z-[1]">
                  Popular Meals
                </h3>
                <div className="self-stretch flex flex-row items-start justify-start relative text-2xs">
                  <img
                    className="h-[24.6px] w-[9.4px] absolute my-0 mx-[!important] right-[166px] bottom-[-20.5px] z-[1]"
                    loading="eager"
                    alt=""
                    src="/group-5.svg"
                  />
                  <div
                    className="flex-1 flex flex-row flex-wrap items-start justify-start py-0 pr-px pl-0 gap-[11px] cursor-pointer z-[2]"
                    onClick={onPopularMealsContainerClick}
                  >
                    <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 box-border gap-[4px] min-w-[89px]">
                      <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
                      <img
                        className="w-[65px] h-[65px] relative object-cover z-[1]"
                        loading="eager"
                        alt=""
                        src="/mask-group-11@2x.png"
                      />
                      <div className="self-stretch h-12 flex flex-col items-center justify-start py-0 px-3.5 box-border">
                        <div className="self-stretch h-[17px] relative inline-block z-[1]">
                          Chicken Salad
                        </div>
                        <div className="self-stretch flex-1 flex flex-col items-center justify-start py-0 px-2.5 gap-[4px] text-3xs text-crimson-100 font-pt-sans-narrow">
                          <div className="self-stretch flex-1 relative z-[1]">
                            Chicken Republic
                          </div>
                          <div className="w-[30px] flex-1 relative text-2xs font-poor-story text-gray-700 inline-block z-[1]">
                            N 5000
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border cursor-pointer z-[1] text-3xs text-white"
                        onClick={onDrumstixContainerClick}
                      >
                        <div className="self-stretch w-[53px] relative capitalize inline-block">
                          Order Now
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 box-border gap-[3px] min-w-[89px] cursor-pointer"
                      onClick={onGroupContainer1Click}
                    >
                      <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
                      <img
                        className="w-[65px] h-[65px] relative object-cover z-[1]"
                        loading="eager"
                        alt=""
                        src="/mask-group-4@2x.png"
                      />
                      <div className="w-[31px] h-[17px] relative inline-block z-[1]">
                        Pasta
                      </div>
                      <div className="w-8 h-[13px] relative text-3xs font-pt-sans-narrow text-crimson-100 inline-block z-[1]">
                        Drumstix
                      </div>
                      <div className="w-[30px] h-3.5 relative font-poor-story inline-block z-[1]">
                        N 3000
                      </div>
                      <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
                        <div className="self-stretch w-[53px] relative capitalize inline-block">
                          Order Now
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 box-border gap-[3px] min-w-[89px]">
                      <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
                      <img
                        className="w-[65px] h-[65px] relative object-cover z-[1]"
                        loading="eager"
                        alt=""
                        src="/mask-group-21@2x.png"
                      />
                      <div className="w-[78px] h-[17px] relative inline-block z-[1]">
                        Nigerian Jollof
                      </div>
                      <div className="w-[26px] h-[13px] relative text-3xs font-pt-sans-narrow text-crimson-100 inline-block z-[1]">
                        Iya Oyo
                      </div>
                      <div className="w-[31px] h-3.5 relative font-poor-story inline-block z-[1]">
                        N 4000
                      </div>
                      <div
                        className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border cursor-pointer z-[1] text-3xs text-white"
                        onClick={onFrameContainerClick}
                      >
                        <div className="self-stretch w-[53px] relative capitalize inline-block">
                          Order Now
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-mini">
            <h3 className="m-0 w-[159px] h-[23px] relative text-inherit font-bold font-inherit inline-block z-[1]">
              Popular Restaurants
            </h3>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-px pl-0 gap-[11px] z-[1] text-xs">
              <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 box-border gap-[17px] min-w-[89px]">
                <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
                <img
                  className="w-[65px] h-[65px] relative object-cover hidden"
                  alt=""
                  src="/mask-group-11@2x.png"
                />
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-3.5 gap-[8px]">
                  <img
                    className="w-[78px] h-[78px] relative z-[1]"
                    loading="eager"
                    alt=""
                    src="/group-8448.svg"
                  />
                  <div className="w-[68px] h-[18px] flex flex-row items-start justify-start py-0 pr-5 pl-[23px] box-border">
                    <b className="self-stretch flex-1 relative z-[1]">KFC</b>
                  </div>
                </div>
                <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
                  <div className="self-stretch w-[53px] relative capitalize inline-block">
                    Order Now
                  </div>
                </div>
              </div>
              <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 box-border gap-[17px] min-w-[89px]">
                <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
                <img
                  className="w-[65px] h-[65px] relative object-cover hidden"
                  alt=""
                  src="/mask-group-4@2x.png"
                />
                <div className="self-stretch flex flex-col items-end justify-start py-0 pr-3.5 pl-[15px] gap-[8px]">
                  <img
                    className="w-[78px] h-[78px] relative z-[1]"
                    loading="eager"
                    alt=""
                    src="/group-8448-1.svg"
                  />
                  <div className="w-[70px] h-[18px] flex flex-row items-start justify-start py-0 px-[7px] box-border">
                    <b className="self-stretch flex-1 relative z-[1]">
                      Dominos
                    </b>
                  </div>
                </div>
                <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
                  <div className="self-stretch w-[53px] relative capitalize inline-block">
                    Order Now
                  </div>
                </div>
              </div>
              <div className="flex-1 rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-center justify-start pt-[7px] px-1.5 pb-1.5 box-border gap-[17px] min-w-[89px]">
                <div className="w-[119px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-[157px] hidden" />
                <img
                  className="w-[65px] h-[65px] relative object-cover hidden"
                  alt=""
                  src="/mask-group-21@2x.png"
                />
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-3.5 gap-[8px]">
                  <img
                    className="w-[78px] h-[78px] relative z-[1]"
                    loading="eager"
                    alt=""
                    src="/group-8448-2.svg"
                  />
                  <b className="w-[59px] h-[18px] relative inline-block z-[1]">
                    The Place
                  </b>
                </div>
                <div className="w-[95px] h-[23px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center p-1 box-border z-[1] text-3xs text-white">
                  <div className="self-stretch w-[53px] relative capitalize inline-block">
                    Order Now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="self-stretch h-[23px] flex flex-row items-start justify-start text-mini text-gray-700">
          <h3 className="m-0 self-stretch w-[140px] relative text-inherit font-bold font-inherit inline-block z-[1]">
            Previously viewed
          </h3>
        </div>
      </main>
    </div>
  );
};

export default Search;
