import PopUpScreen from "../components/PopUpScreen";

const EnterOTP1 = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-center pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-smi text-neutral-black font-source-sans-pro">
      <div className="h-6 w-80 overflow-hidden shrink-0 hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border">
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="h-[45px] w-[162px] relative hidden text-lg text-white font-poppins">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <footer className="h-[52px] w-[183px] relative hidden text-left text-mid text-text-color font-poppins">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </footer>
      <main className="flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-7 px-7 pb-[209px] box-border gap-[76px] max-w-full mq450:gap-[38px] mq450:pb-[88px] mq450:box-border mq750:pt-5 mq750:pb-[136px] mq750:box-border">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
        <header className="w-[172px] flex flex-col items-center justify-start gap-[36px] text-left text-2xl text-grey-2 font-poppins">
          <img
            className="self-stretch h-[41px] relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
            loading="eager"
            alt=""
            src="/chopinns-v4-1-2@2x.png"
          />
          <div className="flex flex-row items-center justify-center py-0 pr-[3px] pl-0 z-[1]">
            <div className="h-[26px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="h-8 relative font-black inline-block whitespace-nowrap">
                Enter your OTP
              </div>
            </div>
          </div>
        </header>
        <PopUpScreen />
      </main>
    </div>
  );
};

export default EnterOTP1;
