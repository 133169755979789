import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const OrderTracking1 = () => {
  const navigate = useNavigate();

  const onMenuIconClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  return (
    <div className="w-full h-[952px] relative bg-white overflow-hidden hidden text-left text-sm text-grey-2 font-poppins">
      <div className="absolute top-[470px] left-[29px] w-80 overflow-hidden hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border text-smi text-neutral-black font-source-sans-pro">
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="absolute top-[634px] left-[24px] w-[162px] h-[45px] hidden text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-[162px] h-[45px]" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="absolute top-[630px] left-[217px] w-[183px] h-[52px] hidden text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <div className="absolute top-[823px] left-[32px] w-[113px] h-[71px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[113px] h-[71px] object-cover"
          alt=""
          src="/pngegg-50-1@2x.png"
        />
        <div className="absolute top-[45px] left-[4px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[19.72%] w-[13.27%] top-[71.83%] right-[81.42%] bottom-[8.45%] left-[5.31%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[53px] left-[19px] font-black">5.8</div>
        <div className="absolute top-[16px] left-[calc(50%_+_106.5px)] rounded-[50%] bg-new-2 [filter:blur(14px)] w-10 h-10 hidden" />
      </div>
      <div className="absolute top-[819px] left-[162px] w-[115px] h-[75px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[115px] h-[61px] object-cover"
          alt=""
          src="/pngegg-42-1@2x.png"
        />
        <div className="absolute top-[49px] left-[13px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[18.67%] w-[13.04%] top-[73.33%] right-[73.91%] bottom-[8%] left-[13.04%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[57px] left-[28px] font-black">5.8</div>
      </div>
      <div className="absolute top-[810px] left-[296px] w-[97px] h-[84px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-24 h-[78px] object-cover"
          alt=""
          src="/pizzapng19310-1@2x.png"
        />
        <div className="absolute top-[58px] left-[8px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[16.67%] w-[15.46%] top-[76.19%] right-[74.23%] bottom-[7.14%] left-[10.31%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[66px] left-[23px] font-black">5.0</div>
      </div>
      <div className="absolute top-[867px] left-[calc(50%_-_214px)] bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] w-[430px] h-[85px] hidden" />
      <div className="absolute top-[888px] left-[36px] w-[359px] h-[47px] hidden text-xs text-grey1">
        <div className="absolute h-[74.47%] w-full top-[25.53%] right-[0%] bottom-[0%] left-[0%] overflow-hidden">
          <div className="absolute top-[10px] left-[calc(50%_-_179.5px)] w-[358px] h-[15px]">
            <div className="absolute top-[0px] left-[0px] font-black">Menu</div>
            <div className="absolute top-[0px] left-[84px] font-black text-new-2">
              Cart
            </div>
            <div className="absolute top-[0px] left-[159px] font-black">
              Home
            </div>
            <div className="absolute top-[0px] left-[232px] font-black">
              Restaurants
            </div>
            <div className="absolute top-[0px] left-[324px] font-black">
              Profile
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] w-[355px] flex flex-row items-end justify-between py-0 pr-0.5 pl-0 box-border">
          <img
            className="h-5 w-[25px] relative cursor-pointer"
            alt=""
            src="/menu-icon.svg"
            onClick={onMenuIconClick}
          />
          <img className="h-5 w-5 relative" alt="" src="/cart-icon.svg" />
          <img
            className="h-[21px] w-[23px] relative min-h-[21px]"
            alt=""
            src="/home.svg"
          />
          <img
            className="self-stretch w-[28.7px] relative max-h-full min-h-[21px]"
            alt=""
            src="/dish.svg"
          />
          <img className="h-5 w-5 relative" alt="" src="/profile.svg" />
        </div>
      </div>
      <img
        className="absolute h-[1.79%] w-[2.83%] top-[6.2%] right-[88.29%] bottom-[92.02%] left-[8.88%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/group.svg"
      />
      <div className="absolute top-[64px] left-[60px] font-black text-grey hidden">
        Your Location
      </div>
      <div className="absolute top-[70px] left-[176px] rounded-[50%] bg-new-2 w-1 h-1 hidden" />
      <img
        className="absolute top-[68px] left-[361px] w-[34px] h-[34px] hidden"
        alt=""
        src="/hamburger-2.svg"
      />
      <img
        className="absolute top-[68px] left-[318px] w-[34px] h-[34px] hidden"
        alt=""
        src="/arrow-2.svg"
      />
      <img
        className="absolute h-[0.74%] w-[2.66%] top-[7.25%] right-[60.42%] bottom-[92.02%] left-[36.92%] max-w-full overflow-hidden max-h-full object-contain hidden"
        alt=""
        src="/group@2x.png"
      />
      <div className="absolute top-[152px] left-[38px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
      <div className="absolute top-[152px] left-[114px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
      <div className="absolute top-[152px] left-[188px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
      <div className="absolute top-[152px] left-[263px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
      <div className="absolute top-[152px] left-[338px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
      <div className="absolute top-[216px] left-[43px] font-black hidden w-[49px] h-5">
        Dishes
      </div>
      <div className="absolute top-[216px] left-[121px] font-black hidden w-[49px] h-5">
        Pizza
      </div>
      <div className="absolute top-[216px] left-[193px] font-black hidden w-[49px] h-5">
        Burger
      </div>
      <div className="absolute top-[216px] left-[268px] font-black hidden w-[49px] h-5">
        Drinks
      </div>
      <div className="absolute top-[216px] left-[343px] font-black hidden w-[49px] h-5">
        Dessert
      </div>
      <img
        className="absolute h-[3.08%] w-[6.85%] top-[17.3%] right-[81.54%] bottom-[79.62%] left-[11.61%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/group.svg"
      />
      <img
        className="absolute h-[3.04%] w-[7.24%] top-[17.33%] right-[63.55%] bottom-[79.63%] left-[29.21%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/pizza.svg"
      />
      <img
        className="absolute h-[2.63%] w-[7.01%] top-[17.54%] right-[46.26%] bottom-[79.83%] left-[46.73%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/vector.svg"
      />
      <img
        className="absolute h-[2.94%] w-[5.09%] top-[17.33%] right-[29.72%] bottom-[79.73%] left-[65.19%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/drinks.svg"
      />
      <img
        className="absolute h-[2.73%] w-[6.36%] top-[17.44%] right-[11.64%] bottom-[79.83%] left-[82.01%] max-w-full overflow-hidden max-h-full hidden"
        alt=""
        src="/donut.svg"
      />
      <div className="absolute top-[239px] left-[36px] font-black hidden">
        Restaurants
      </div>
      <div className="absolute top-[541px] left-[36px] w-[359px] h-[135px] hidden">
        <div className="absolute top-[0px] left-[0px] rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] w-[359px] h-[135px]" />
      </div>
      <div className="absolute top-[395px] left-[38px] w-[225px] h-[107px] hidden text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>Location: 4</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          30 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">5.5</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute h-[6.54%] w-[3.11%] top-[81.31%] right-[14.22%] bottom-[12.15%] left-[82.67%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector-3.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/iconclock.svg"
        />
        <img
          className="absolute top-[17px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
      </div>
      <div className="absolute top-[391px] left-[295px] w-[225px] h-[107px] hidden text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>Location: 4</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          30 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">5.5</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute h-[6.54%] w-[3.11%] top-[81.31%] right-[14.22%] bottom-[12.15%] left-[82.67%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector-3.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/iconclock.svg"
        />
        <img
          className="absolute top-[17px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
      </div>
      <div className="absolute top-[266px] left-[38px] w-[225px] h-[107px] hidden text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-22@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>{`Location: `}</span>
          <span className="text-new-2">2KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          15 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">4.5</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute top-[16px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
        <img
          className="absolute top-[87px] left-[186px] w-[7px] h-[7px] overflow-hidden"
          alt=""
          src="/326702-half-star-icon-1.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/group.svg"
        />
      </div>
      <div className="absolute top-[563px] left-[245px] font-black text-white whitespace-pre-wrap hidden">
        New Limited time
      </div>
      <div className="absolute top-[646px] left-[294px] text-mini font-black text-gold hidden">
        Order Now
      </div>
      <img
        className="absolute top-[571px] left-[200px] w-8 h-1.5 hidden"
        alt=""
        src="/slide.svg"
      />
      <div className="absolute top-[569px] left-[278px] rounded-[50%] bg-white w-1.5 h-1.5 hidden" />
      <div className="absolute top-[266px] left-[295px] w-[225px] h-[107px] hidden text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-27@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>Location: 3</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          20 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">6</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute top-[16px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/group.svg"
        />
        <img
          className="absolute h-[6.54%] w-[3.11%] top-[81.31%] right-[14.22%] bottom-[12.15%] left-[82.67%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector-3.svg"
        />
      </div>
      <img
        className="absolute top-[576px] left-[15px] w-[138px] h-[100px] object-cover hidden"
        alt=""
        src="/pngegg-19-1@2x.png"
      />
      <img
        className="absolute top-[494px] left-[-18px] w-[105px] h-[225px] object-contain hidden"
        alt=""
        src="/pngegg-22-2@2x.png"
      />
      <div className="absolute top-[593px] left-[149px] text-xs font-black text-white whitespace-pre-wrap text-right hidden">
        <p className="m-0">California-style pizza Sicilian pizza</p>
        <p className="m-0">{`with Burger & French fries & 1 free`}</p>
        <p className="m-0">{`  Coca cola drink. `}</p>
      </div>
      <div className="absolute top-[698px] left-[28px] text-smi font-black text-right hidden">
        Popular
      </div>
      <div className="absolute top-[700px] left-[351px] text-smi font-black text-chocolate-100 text-right hidden">
        See all
      </div>
      <div className="absolute top-[725px] left-[35px] w-[90px] h-[74px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[90px] h-[74px] object-cover"
          alt=""
          src="/pngegg-17-1@2x.png"
        />
        <div className="absolute top-[48px] left-[1px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[18.92%] w-[16.67%] top-[72.97%] right-[82.22%] bottom-[8.11%] left-[1.11%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[56px] left-[14px] font-black">5.0</div>
      </div>
      <div className="absolute top-[694px] left-[162px] w-[109px] h-[109px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[109px] h-[109px] object-cover"
          alt=""
          src="/pngegg-4-1@2x.png"
        />
        <div className="absolute top-[79px] left-[7px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[12.84%] w-[13.76%] top-[77.98%] right-[77.98%] bottom-[9.17%] left-[8.26%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[87px] left-[23px] font-black">6.5</div>
      </div>
      <div className="absolute top-[719px] left-[300px] w-[93px] h-20 hidden text-right text-2xs text-new-2">
        <img
          className="absolute h-[calc(100%_-_2px)] top-[0px] bottom-[2px] left-[0px] max-h-full w-20 object-cover"
          alt=""
          src="/4-1@2x.png"
        />
        <div className="absolute top-[54px] left-[4px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[17.5%] w-[16.13%] top-[75%] right-[77.42%] bottom-[7.5%] left-[6.45%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[62px] left-[20px] font-black">6.0</div>
      </div>
      <main className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] w-[428px] h-[773px] flex flex-col items-center justify-start py-[19px] pr-9 pl-[38px] box-border">
        <div className="w-[428px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[772px] hidden z-[0]" />
        <img
          className="w-[9.4px] h-[24.6px] absolute my-0 mx-[!important] right-[190px] bottom-[259.5px] z-[1]"
          loading="eager"
          alt=""
          src="/group-5.svg"
        />
        <section className="self-stretch h-[115px] flex flex-col items-center justify-start gap-[68px]">
          <img
            className="w-[326px] h-[12.8px] relative z-[1]"
            loading="eager"
            alt=""
            src="/icon-iphone.svg"
          />
          <div className="self-stretch flex-1 bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-start pt-2 px-[3px] pb-1 gap-[20px] z-[1]">
            <div className="h-[34px] w-[354px] relative bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] hidden" />
            <img
              className="h-[19px] w-[19px] relative mix-blend-normal z-[2]"
              loading="eager"
              alt=""
              src="/xmlid-223.svg"
            />
            <input
              className="w-[52px] [border:none] [outline:none] font-black font-poppins text-sm bg-[transparent] h-[21px] relative text-dimgray-500 text-left inline-block mix-blend-normal z-[2]"
              placeholder="Search"
              type="text"
            />
          </div>
        </section>
      </main>
      <img
        className="absolute top-[163px] left-[0px] w-[428px] h-[664px] object-cover z-[2]"
        alt=""
        src="/courier-frames@2x.png"
      />
      <footer className="absolute top-[702px] left-[0px] rounded-t-21xl rounded-b-none bg-snow-300 w-[428px] flex flex-row items-start justify-between pt-[71px] pb-[58px] pr-[61px] pl-[54px] box-border z-[3] text-left text-sm text-silver-200 font-poppins">
        <div className="h-[250px] w-[428px] relative rounded-t-21xl rounded-b-none bg-snow-300 hidden" />
        <div className="w-[189px] flex flex-col items-start justify-start gap-[24px]">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-1.5">
            <div className="flex-1 flex flex-row items-start justify-between">
              <div className="flex flex-col items-center justify-start gap-[7px]">
                <img
                  className="w-12 h-12 relative z-[4]"
                  loading="eager"
                  alt=""
                  src="/store.svg"
                />
                <b className="w-[38px] h-[21px] relative inline-block z-[4]">
                  Store
                </b>
              </div>
              <div className="w-[54px] flex flex-col items-center justify-start gap-[7px] text-new-2">
                <img
                  className="w-12 h-12 relative z-[4]"
                  loading="eager"
                  alt=""
                  src="/courier.svg"
                />
                <b className="self-stretch h-[21px] relative inline-block z-[4]">
                  Courier
                </b>
              </div>
            </div>
          </div>
          <div className="w-[171px] h-[21px] relative text-darkslategray-500 inline-block z-[4]">
            Your order is on the way.
          </div>
        </div>
        <div className="flex flex-col items-center justify-start gap-[8px]">
          <img
            className="w-[43.2px] h-[47.5px] relative z-[4]"
            loading="eager"
            alt=""
            src="/user.svg"
          />
          <b className="w-7 h-[21px] relative inline-block z-[4]">You</b>
        </div>
      </footer>
    </div>
  );
};

export default OrderTracking1;
