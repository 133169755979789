/** 
 * 
*/

import RestaurantListCard from "./RestaurantListCard";

export default function RestaurantList() {
    return (
        <section className="my-3 font-inter">
            <div className="bg-white mr-2 gap-[8px]">
                <div className="flex flex-row items-center justify-start my-1 gap-[2px]">
                    <img
                        className="h-5 w-5 px-4"
                        loading="eager"
                        alt=""
                        src="/chevron-left-large1.svg"
                    />
                    <h3 className="text-lg font-extrabold font-poppins">
                        Fast Food
                    </h3>
                </div>
                <div className="flex flex-row px-5 text-xs text-new-2 gap-[11px] w-auto">
                    <div className="rounded-sm bg-seashell flex flex-row items-center justify-start py-1 px-2 gap-[1px]">
                        <span className="">Sort by</span>
                        <img
                            className="h-4 w-4"
                            alt=""
                            src="/chevron-down-large.svg"
                        />
                    </div>
                    <div className="cursor-pointer py-1 px-2 bg-whitesmoke-200 rounded-sm flex flex-row items-center justify-center hover:bg-gainsboro-300">
                        <span className="text-xs font-inter text-darkslategray-200">
                            Freeship
                        </span>
                    </div>
                    <div className="cursor-pointer py-1 px-2 bg-whitesmoke-200 rounded-sm flex flex-row items-center justify-center hover:bg-gainsboro-300">
                        <span className="text-xs font-inter text-darkslategray-200">
                            Favorite
                        </span>
                    </div>
                    <div className="cursor-pointer py-1 px-2 bg-whitesmoke-200 rounded-sm flex flex-row items-center justify-center hover:bg-gainsboro-300">
                        <span className="text-xs font-inter text-darkslategray-200">
                            Near you
                        </span>
                    </div>
                    <div className="cursor-pointer py-1 px-2 bg-whitesmoke-200 rounded-sm flex flex-row items-center justify-center hover:bg-gainsboro-300">
                        <span className="text-xs font-inter text-darkslategray-200">
                            Parner
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap pt-8 gap-4">
                <RestaurantListCard
                    image="/image-23@2x.png"
                    title="Chicken Republic"
                    subTitle="Fried Chicken, Jollof Rice"
                    time="15"
                    rating="4.8"
                    tags="Smokey Jollof & Asun"
                />
                <RestaurantListCard
                    image="/image-24@2x.png"
                    title="KFC"
                    subTitle="Chips, Burger, Toppings & Salad"
                    time="35"
                    rating="4.1"
                    tags="Smokey Jollof & Asun"
                />
                <RestaurantListCard
                    image="/image-25@2x.png"
                    title="The Place"
                    subTitle="Smokey Jollof & Asun"
                    time="35"
                    rating="4.1"
                    tags="Smokey Jollof & Asun"
                />
            </div>
            <div className="flex mt-6 items-center justify-center font-inter">
                <button className="p-4 mx-8 w-full sm:w-1/2 bg-white font-medium text-sm text-new-2">See all</button>
            </div>
        </section>
    );
}