import FriedChickenText from "./FriedChickenText";

const Container10 = () => {
  return (
    <div className="w-[375px] rounded bg-snow-400 flex flex-row flex-wrap items-start justify-start py-0 px-5 box-border gap-[16px] max-w-full text-left text-xs text-lightslategray-100 font-inter">
      <img
        className="h-[90px] w-[90px] relative rounded-md object-cover"
        loading="eager"
        alt=""
        src="/image-30@2x.png"
      />
      <FriedChickenText
        hanaChicken="Chicken Republic"
        friedChicken={`Fried Chicken & Jollof Rice`}
        mins="15 mins"
        resultcontainer="4.8"
        image31="/image-31@2x.png"
        friedChicken1="Jollof Rice"
        friedchickentextbox="N1,000"
        image32="/image-32@2x.png"
        friedChickenPotatos={`Fried Chicken & Chips`}
        prop="N2,500"
        propFlex="1"
        propMinWidth="149px"
        propGap="2px"
      />
    </div>
  );
};

export default Container10;
