import FeedbackFrame from "../components/FeedbackFrame";

const RatingAndReview = () => {
  return (
    <div className="w-full h-[952px] relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[68px] tracking-[normal] text-left text-2xs text-grey-2 font-poppins mq450:h-auto mq450:gap-[17px] mq525:gap-[34px]">
      <div className="w-80 overflow-hidden hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border z-[0] text-smi text-neutral-black font-source-sans-pro">
        <div className="self-stretch h-5 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="w-[162px] h-[45px] relative hidden z-[1] text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="w-[183px] h-[52px] relative hidden z-[2] text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <div className="w-[113px] h-[71px] relative hidden z-[3] text-right text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
          alt=""
          src="/pngegg-50-1@2x.png"
        />
        <div className="absolute top-[45px] left-[4px] w-[89px] h-[26px]">
          <div className="absolute top-[0px] left-[0px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-full h-full" />
          <img
            className="absolute top-[6px] left-[2px] rounded-12xs w-[15px] h-3.5"
            alt=""
            src="/vector.svg"
          />
          <div className="absolute top-[8px] left-[15px] font-black">5.8</div>
        </div>
        <div className="absolute top-[16px] left-[calc(50%_+_106.5px)] rounded-[50%] bg-new-2 [filter:blur(14px)] w-10 h-10 hidden" />
      </div>
      <div className="relative font-black text-new-2 text-right hidden z-[4]">
        5.8
      </div>
      <div className="relative font-black text-new-2 text-right hidden z-[5]">
        5.0
      </div>
      <main className="w-[520px] h-[772px] relative max-w-[121%] shrink-0 text-left text-3xs text-grey-2 font-poppins mq450:h-auto mq450:min-h-[772]">
        <img
          className="absolute top-[488.9px] left-[228.6px] w-[9.4px] h-[24.6px] z-[1]"
          loading="eager"
          alt=""
          src="/group-5.svg"
        />
        <div className="absolute top-[541px] left-[36px] w-[359px] h-[135px] hidden z-[1]">
          <div className="absolute top-[0px] left-[0px] rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] w-full h-full" />
        </div>
        <div className="absolute top-[470px] left-[38px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 hidden z-[2]" />
        <img
          className="absolute top-[482px] left-[224px] w-[7px] h-[7px] z-[3]"
          alt=""
          src="/vector-3.svg"
        />
        <img
          className="absolute top-[482px] left-[142px] w-2 h-2 z-[3]"
          loading="eager"
          alt=""
          src="/iconclock.svg"
        />
        <img
          className="absolute top-[478px] left-[399px] w-2 h-2 z-[3]"
          loading="eager"
          alt=""
          src="/iconclock.svg"
        />
        <div className="absolute top-[569px] left-[278px] rounded-[50%] bg-white w-1.5 h-1.5 z-[3]" />
        <div className="absolute top-[341px] left-[295px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[23px] pl-[11px] box-border gap-[19px] z-[2]">
          <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
          <div className="relative font-black z-[3]">
            <span>Location: 3</span>
            <span className="text-new-2">KM</span>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-start justify-between gap-[20px]">
            <div className="w-14 flex flex-row items-start justify-start gap-[4px]">
              <img
                className="h-2 w-2 relative z-[3]"
                loading="eager"
                alt=""
                src="/group.svg"
              />
              <div className="flex-1 relative font-black z-[3]">20 mins.</div>
            </div>
            <div className="self-stretch w-4 flex flex-row items-start justify-start gap-[2px]">
              <img
                className="h-[7px] w-[7px] relative z-[3]"
                loading="eager"
                alt=""
                src="/vector-3.svg"
              />
              <div className="self-stretch flex-1 relative font-black z-[3]">
                6
              </div>
            </div>
          </div>
        </div>
        <img
          className="absolute top-[576px] left-[15px] w-[138px] h-[100px] object-cover hidden z-[2]"
          alt=""
          src="/pngegg-19-1@2x.png"
        />
        <div className="absolute top-[719px] left-[300px] w-[93px] h-20 hidden z-[2]">
          <img
            className="absolute h-[calc(100%_-_2px)] top-[0px] bottom-[2px] left-[0px] max-h-full w-20 object-cover"
            alt=""
            src="/4-1@2x.png"
          />
          <div className="absolute top-[54px] left-[4px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] z-[1]" />
          <img
            className="absolute h-[17.5%] w-[16.13%] top-[75%] right-[77.42%] bottom-[7.5%] left-[6.45%] rounded-12xs max-w-full overflow-hidden max-h-full z-[2]"
            alt=""
            src="/vector.svg"
          />
        </div>
        <section className="absolute top-[0px] left-[0px] w-[428px] flex flex-row items-start justify-start max-w-full text-left text-3xs text-grey-2 font-poppins">
          <div className="h-8 w-[225px] my-0 mx-[!important] absolute right-[-92px] bottom-[274px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[13px] pl-[116px] box-border gap-[25px] z-[2]">
            <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
            <div className="flex-1 relative font-black z-[3]">30 mins.</div>
            <div className="self-stretch w-[26px] flex flex-row items-start justify-start gap-[2px]">
              <img
                className="h-[7px] w-[7px] relative z-[3]"
                loading="eager"
                alt=""
                src="/vector-3.svg"
              />
              <div className="self-stretch flex-1 relative font-black z-[3]">
                5.5
              </div>
            </div>
          </div>
          <div className="flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-[19px] px-[19px] pb-[399px] box-border gap-[6px] max-w-full text-sm text-grey">
            <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
            <div className="self-stretch flex flex-col items-end justify-start py-0 px-[17px] box-border gap-[19px] max-w-full">
              <div className="self-stretch h-[21px] flex flex-row items-start justify-start py-0 px-3.5 box-border max-w-full">
                <img
                  className="h-[12.8px] flex-1 relative max-w-full overflow-hidden z-[1]"
                  loading="eager"
                  alt=""
                  src="/icon-iphone.svg"
                />
              </div>
              <div className="self-stretch h-[74px] flex flex-col items-start justify-start gap-[14px] top-[0] z-[99] sticky max-w-full">
                <div className="flex flex-row items-center justify-start py-0 pr-px pl-0 gap-[10px]">
                  <div className="flex flex-col items-start justify-start pt-0 px-0 pb-[9px]">
                    <img
                      className="w-[12.1px] h-[17px] relative z-[1]"
                      loading="eager"
                      alt=""
                      src="/group.svg"
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start pt-[5px] px-0 pb-0">
                    <div className="flex flex-row items-center justify-start py-0 px-0">
                      <h3 className="m-0 relative text-inherit font-black font-inherit z-[1]">
                        Your Location
                      </h3>
                      <div className="flex flex-col items-start justify-start pt-0 px-0 pb-1 ml-[-7px]">
                        <img
                          className="w-[11.4px] h-[7px] relative object-contain z-[2]"
                          loading="eager"
                          alt=""
                          src="/group@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="h-1 w-1 relative rounded-[50%] bg-new-2 z-[1]" />
                </div>
                <div className="self-stretch flex-1 bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-start pt-2 px-[3px] pb-1 box-border gap-[20px] max-w-full z-[1]">
                  <div className="h-[34px] w-[354px] relative bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] hidden max-w-full" />
                  <img
                    className="h-[19px] w-[19px] relative mix-blend-normal z-[2]"
                    alt=""
                    src="/xmlid-223.svg"
                  />
                  <input
                    className="w-[50px] [border:none] [outline:none] font-black font-poppins text-sm bg-[transparent] h-[21px] relative text-dimgray-500 text-left inline-block mix-blend-normal z-[2]"
                    placeholder="Search"
                    type="text"
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start py-0 pr-0.5 pl-0 gap-[3px] text-grey-2">
                <nav className="m-0 self-stretch flex flex-row items-start justify-between gap-[20px] whitespace-nowrap text-left text-xs text-grey-2 font-poppins mq450:flex-wrap mq450:justify-center">
                  <div className="w-[54px] flex flex-col items-center justify-start gap-[10px]">
                    <button className="cursor-pointer [border:none] p-0 bg-white self-stretch h-[54px] relative rounded-8xs shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                      <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-full h-full hidden" />
                      <img
                        className="absolute top-[12.7px] left-[11.7px] w-[29.3px] h-[29.3px] z-[2]"
                        alt=""
                        src="/group.svg"
                      />
                    </button>
                    <div className="flex flex-row items-start justify-start py-0 pr-2 pl-[5px]">
                      <div className="relative font-black z-[1]">Dishes</div>
                    </div>
                  </div>
                  <div className="w-[54px] flex flex-col items-start justify-start gap-[10px]">
                    <button className="cursor-pointer [border:none] p-0 bg-white self-stretch h-[54px] relative rounded-8xs shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                      <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-full h-full hidden" />
                      <img
                        className="absolute top-[13px] left-[11px] w-[31px] h-[28.9px] z-[2]"
                        alt=""
                        src="/pizza.svg"
                      />
                    </button>
                    <div className="flex flex-row items-start justify-start py-0 px-[7px]">
                      <div className="relative font-black z-[1]">Pizza</div>
                    </div>
                  </div>
                  <div className="w-[54px] flex flex-col items-center justify-start gap-[10px]">
                    <div className="self-stretch h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                      <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-full h-full hidden" />
                      <img
                        className="absolute top-[15px] left-[12px] w-[30px] h-[25px] z-[2]"
                        loading="eager"
                        alt=""
                        src="/vector.svg"
                      />
                    </div>
                    <div className="relative font-black z-[1]">Burger</div>
                  </div>
                  <div className="w-[54px] flex flex-col items-center justify-start gap-[10px]">
                    <button className="cursor-pointer [border:none] p-0 bg-white self-stretch h-[54px] relative rounded-8xs shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                      <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-full h-full hidden" />
                      <img
                        className="absolute top-[13px] left-[16px] w-[21.8px] h-7 z-[2]"
                        alt=""
                        src="/drinks.svg"
                      />
                    </button>
                    <div className="flex flex-row items-start justify-start py-0 pr-[9px] pl-[5px]">
                      <div className="relative font-black z-[1]">Drinks</div>
                    </div>
                  </div>
                  <div className="w-[54px] flex flex-col items-end justify-start gap-[10px]">
                    <div className="self-stretch h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                      <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-full h-full hidden" />
                      <img
                        className="absolute top-[14px] left-[13px] w-[27.2px] h-[26px] z-[2]"
                        loading="eager"
                        alt=""
                        src="/donut.svg"
                      />
                    </div>
                    <div className="h-5 relative font-black inline-block shrink-0 z-[1]">
                      Dessert
                    </div>
                  </div>
                </nav>
                <h3 className="m-0 h-[21px] relative text-inherit font-black font-inherit inline-block z-[1]">
                  Restaurants
                </h3>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-[19px] text-3xs text-grey-2">
              <div className="w-[225px] flex flex-col items-start justify-start">
                <button className="cursor-pointer [border:none] pt-3 px-[13px] pb-12 bg-[transparent] self-stretch rounded-t-3xs rounded-b-none flex flex-row items-start justify-end bg-cover bg-no-repeat bg-[top] z-[1]">
                  <img
                    className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
                    alt=""
                    src="/rectangle-22@2x.png"
                  />
                  <div className="h-4 w-4 relative">
                    <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-full h-full z-[2]" />
                    <img
                      className="absolute top-[4px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
                      alt=""
                      src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
                    />
                  </div>
                </button>
                <div className="bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[15px] pl-[11px] gap-[20px] z-[2] mt-[-1px]">
                  <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
                  <div className="relative font-black z-[3]">
                    <span>{`Location: `}</span>
                    <span className="text-new-2">2KM</span>
                  </div>
                  <div className="flex flex-row items-start justify-start py-0 pr-[7px] pl-0 gap-[4px]">
                    <img
                      className="h-2 w-2 relative z-[3]"
                      loading="eager"
                      alt=""
                      src="/group.svg"
                    />
                    <div className="relative font-black z-[3]">15 mins.</div>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <img
                      className="h-[7px] w-[7px] relative overflow-hidden shrink-0 z-[3]"
                      loading="eager"
                      alt=""
                      src="/326702-half-star-icon-1.svg"
                    />
                    <div className="relative font-black z-[3]">4.5</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="w-[359px] h-[47px] relative hidden max-w-full z-[7] text-xs text-grey1">
        <div className="absolute h-[74.47%] w-full top-[25.53%] right-[0%] bottom-[0%] left-[0%] overflow-hidden">
          <div className="absolute top-[10px] left-[calc(50%_-_179.5px)] w-full h-[15px]">
            <div className="absolute top-[0px] left-[0px] font-black">Menu</div>
            <div className="absolute top-[0px] left-[84px] font-black">
              Cart
            </div>
            <div className="absolute top-[0px] left-[159px] font-black text-new-2">
              Home
            </div>
            <div className="absolute top-[0px] left-[232px] font-black">
              Restaurants
            </div>
            <div className="absolute top-[0px] left-[324px] font-black">
              Profile
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] w-[355px] flex flex-row items-end justify-between py-0 pr-0.5 pl-0 box-border gap-[20px]">
          <input className="m-0 h-5 w-[25px] relative" type="checkbox" />
          <input className="m-0 h-5 w-5 relative" type="checkbox" />
          <input
            className="m-0 h-[21px] w-[23px] relative min-h-[21px]"
            type="checkbox"
          />
          <input
            className="m-0 self-stretch w-[28.7px] relative min-h-[21px]"
            type="checkbox"
          />
          <input className="m-0 h-5 w-5 relative" type="checkbox" />
        </div>
      </div>
      <div className="relative text-sm font-black text-grey hidden z-[8]">
        Your Location
      </div>
      <img
        className="w-[34px] h-[34px] relative hidden z-[9]"
        alt=""
        src="/hamburger-2.svg"
      />
      <img
        className="w-[34px] h-[34px] relative hidden z-[10]"
        alt=""
        src="/arrow-2.svg"
      />
      <div className="relative text-sm font-black hidden z-[11]">Pizza</div>
      <div className="relative text-sm font-black hidden z-[12]">Pizza</div>
      <div className="relative text-3xs font-black hidden z-[13]">
        <span>Location: 4</span>
        <span className="text-new-2">KM</span>
      </div>
      <div className="relative text-3xs font-black hidden z-[14]">30 mins.</div>
      <div className="relative text-3xs font-black hidden z-[15]">5.5</div>
      <div className="relative text-3xs font-black hidden z-[20]">
        <span>Location: 4</span>
        <span className="text-new-2">KM</span>
      </div>
      <div className="w-[225px] my-0 mx-[!important] absolute top-[391px] right-[-92px] rounded-t-3xs rounded-b-none flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
        <img
          className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="h-4 w-4 relative">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-full h-full z-[2]" />
          <img
            className="absolute top-[5px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
            loading="eager"
            alt=""
            src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
          />
        </div>
      </div>
      <div className="relative text-sm font-black text-white whitespace-pre-wrap hidden z-[18]">
        New Limited time
      </div>
      <div className="h-[23px] relative text-mini font-black text-gold hidden z-[19]">
        Order Now
      </div>
      <img
        className="w-8 h-1.5 relative hidden z-[20]"
        alt=""
        src="/slide.svg"
      />
      <div className="w-[225px] my-0 mx-[!important] absolute top-[266px] right-[-92px] rounded-t-3xs rounded-b-none flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
        <img
          className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
          alt=""
          src="/rectangle-27@2x.png"
        />
        <div className="h-4 w-4 relative">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-full h-full z-[2]" />
          <img
            className="absolute top-[4px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
            loading="eager"
            alt=""
            src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
          />
        </div>
      </div>
      <img
        className="w-[105px] absolute my-0 mx-[!important] bottom-[233px] left-[-18px] h-[225px] object-contain z-[4]"
        alt=""
        src="/pngegg-22-2@2x.png"
      />
      <div className="h-[72px] relative text-xs font-black text-white whitespace-pre-wrap text-right hidden z-[23]">
        <p className="m-0">California-style pizza Sicilian pizza</p>
        <p className="m-0">{`with Burger & French fries & 1 free`}</p>
        <p className="m-0">{`  Coca cola drink. `}</p>
      </div>
      <div className="relative text-smi font-black text-right hidden z-[24]">
        Popular
      </div>
      <div className="h-5 relative text-smi font-black text-chocolate-100 text-right hidden z-[25]">
        See all
      </div>
      <div className="w-[90px] h-[74px] relative hidden z-[26] text-right text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
          alt=""
          src="/pngegg-17-1@2x.png"
        />
        <div className="absolute top-[48px] left-[1px] w-full h-[26px]">
          <div className="absolute top-[0px] left-[0px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-full h-full" />
          <img
            className="absolute top-[6px] left-[0px] rounded-12xs w-[15px] h-3.5"
            alt=""
            src="/vector.svg"
          />
          <div className="absolute top-[8px] left-[13px] font-black">5.0</div>
        </div>
      </div>
      <div className="w-[109px] h-[109px] relative hidden z-[27] text-right text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
          alt=""
          src="/pngegg-4-1@2x.png"
        />
        <div className="absolute top-[79px] left-[7px] w-[89px] h-[26px]">
          <div className="absolute top-[0px] left-[0px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-full h-full" />
          <img
            className="absolute top-[6px] left-[2px] rounded-12xs w-[15px] h-3.5"
            alt=""
            src="/vector.svg"
          />
          <div className="absolute top-[8px] left-[16px] font-black">6.5</div>
        </div>
      </div>
      <footer className="self-stretch h-[85px] flex flex-row items-start justify-start py-0 px-0 box-border max-w-full">
        <div className="self-stretch flex-1 relative bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] max-w-full z-[3]" />
      </footer>
      <FeedbackFrame />
    </div>
  );
};

export default RatingAndReview;
