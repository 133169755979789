import FriedChickenText from "./FriedChickenText";

const Container9 = () => {
  return (
    <div className="self-stretch rounded bg-white flex flex-row items-start justify-start py-0 px-5 gap-[16px] mq450:flex-wrap">
      <img
        className="h-[90px] w-[90px] relative rounded-md object-cover"
        loading="eager"
        alt=""
        src="/image-35@2x.png"
      />
      <div className="w-[229px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border min-w-[229px] mq450:flex-1">
        <FriedChickenText
          hanaChicken="The Place Restaurant"
          friedChicken={`Smokey Jollof & Asun`}
          mins="35 mins"
          resultcontainer="4.1"
          image31="/image-33@2x.png"
          friedChicken1="Assorted Jollof Rice"
          friedchickentextbox="N2,000"
          image32="/image-34@2x.png"
          friedChickenPotatos="Barbeque Chicken"
          prop="N2,600"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propWidth="163px"
          propGap="22px"
        />
      </div>
    </div>
  );
};

export default Container9;
