import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Container = () => {
  const navigate = useNavigate();
  
  const onOrderNowClick = useCallback(() => {
    navigate("/payment");
  }, [navigate]);
  return (
    <section className="self-stretch bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_2px_5px_rgba(23,_26,_31,_0.13)] flex flex-col items-center justify-start py-4 pr-[43px] pl-5 box-border gap-[16px] max-w-full text-left text-sm text-gray-100 font-poppins">
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[22px] pl-0 box-border max-w-full">
        <div className="flex-1 flex flex-row items-center justify-between gap-[20px] max-w-full mq450:flex-wrap">
          <div className="relative leading-[22px]">Total</div>
          <b className="relative text-lg leading-[28px] text-right">N9,950</b>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[22px] box-border max-w-full"
      onClick={onOrderNowClick}>
        <button className="cursor-pointer [border:none] p-[9px] bg-new-2 flex-1 rounded-10xs overflow-hidden flex flex-row items-center justify-center box-border max-w-full whitespace-nowrap hover:bg-tomato-200">
          <div className="relative text-base leading-[26px] font-semibold font-poppins text-white text-left">
            Order now
          </div>
        </button>
      </div>
    </section>
  );
};

export default Container;
