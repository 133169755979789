import React from 'react';
import { useNavigate } from 'react-router-dom';

const Container3 = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <header className="self-stretch h-[84px] bg-white flex flex-row items-start justify-between py-0 pr-[9px] pl-0 box-border gap-[20px] top-[0] z-[99] sticky text-left text-base text-lightslategray-100 font-inter">
      <div className="self-stretch flex flex-col items-start justify-start">
        <div className="flex-1 rounded bg-gray-1300 overflow-hidden flex flex-row items-center justify-start py-[9px] px-4">
          <img onClick={handleBackClick}
            className="h-5 w-5 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/chevron-left-large.svg"
          />
        </div>
      </div>
      <div className="w-[129px] flex flex-col items-start justify-start pt-12 px-0 pb-0 box-border text-center text-lg text-gray-100 font-poppins">
        <div className="relative leading-[28px] font-semibold whitespace-nowrap">
          Order review
        </div>
      </div>
      {/* <div className="w-24 overflow-hidden shrink-0 flex flex-col items-start justify-center pt-[17px] pb-[13px] pr-[18px] pl-3 box-border relative">
        <img
          className="self-stretch h-[10.6px] relative max-w-full overflow-hidden shrink-0"
          alt=""
          src="/group-2.svg"
        />
        <img
          className="w-0 h-0 absolute my-0 mx-[!important] right-[45.5px] bottom-[-10.5px]"
          alt=""
        />
      </div> */}
    </header>
  );
};

export default Container3;
