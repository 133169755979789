import { useMemo } from "react";

const FriedChickenText = ({
  hanaChicken,
  friedChicken,
  mins,
  resultcontainer,
  image31,
  friedChicken1,
  friedchickentextbox,
  image32,
  friedChickenPotatos,
  prop,
  propFlex,
  propMinWidth,
  propAlignSelf,
  propWidth,
  propGap,
}) => {
  const friedChickenTextStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
      alignSelf: propAlignSelf,
    };
  }, [propFlex, propMinWidth, propAlignSelf]);

  const hanaChickenStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const frameDiv1Style = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  return (
    <div
      className="flex-1 flex flex-col items-start justify-start gap-[24px] min-w-[149px] text-left text-xs text-lightslategray-100 font-inter"
      style={friedChickenTextStyle}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[5px]">
        <div
          className="relative text-sm leading-[22px] font-semibold font-poppins text-gray-100"
          style={hanaChickenStyle}
        >
          {hanaChicken}
        </div>
        <div className="relative leading-[20px]">{friedChicken}</div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[2px]">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <div className="flex flex-row items-center justify-start">
              <div className="flex flex-col items-start justify-start py-0 px-0">
                <div className="relative leading-[20px]">{mins}</div>
              </div>
              <div className="relative text-2xs leading-[18px]">•</div>
            </div>
            <div className="relative leading-[20px]">{resultcontainer}</div>
            <img
              className="h-3 w-3 relative"
              loading="eager"
              alt=""
              src="/rating-13.svg"
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-2xs text-limegreen">
            <div className="w-[123px] flex flex-row items-start justify-start gap-[6px]">
              <div className="flex-1 rounded-sm bg-honeydew-200 overflow-hidden flex flex-row items-center justify-center p-px">
                <div className="relative leading-[18px]">Freeship</div>
              </div>
              <div className="rounded-sm bg-seashell overflow-hidden flex flex-row items-center justify-center py-px pr-[5px] pl-1.5 whitespace-nowrap text-new-2">
                <div className="relative leading-[18px]">Near you</div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-xs text-darkslategray-200 font-poppins">
              <img
                className="h-[60px] w-[60px] relative rounded-10xs object-cover"
                loading="eager"
                alt=""
                src={image31}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                <div className="self-stretch relative leading-[20px]">
                  {friedChicken1}
                </div>
                <div className="relative leading-[20px] font-semibold text-gray-100">
                  {friedchickentextbox}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-darkslategray-200 font-poppins">
        <img
          className="h-[60px] w-[60px] relative rounded-10xs object-cover"
          loading="eager"
          alt=""
          src={image32}
        />
        <div
          className="flex-1 flex flex-col items-start justify-start gap-[2px]"
          style={frameDiv1Style}
        >
          <div className="self-stretch relative leading-[20px]">
            {friedChickenPotatos}
          </div>
          <div className="relative leading-[20px] font-semibold text-gray-100">
            {prop}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FriedChickenText;
