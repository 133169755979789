import FrameComponent from "./FrameComponent";

const Container1 = () => {
  return (
    <div className="self-stretch bg-white flex flex-col items-center justify-start relative gap-[2px] text-left text-xs text-white font-inter">
      <FrameComponent
        image60="/image-62@2x.png"
        friedChicken="Chicken Salad"
        sizeL="Size: M"
        toppingColeslawPLANTAIN="Sauce: Salad Cream"
        spicinessHot="Spiciness: No"
        prop="N,1250"
        propPadding="0px 22px"
        propWidth="168px"
        propColor="rgba(23, 26, 31, 0.5)"
      />
      <div className="h-8 my-0 mx-[!important] absolute right-[20px] bottom-[0px] rounded bg-new-2 overflow-hidden shrink-0 flex flex-row items-center justify-start py-1.5 px-3 box-border">
        <img
          className="h-3 w-3 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/e-add.svg"
        />
        <div className="self-stretch w-0 relative leading-[20px] inline-block" />
      </div>
      <div className="w-[231px] h-8 flex flex-row items-start justify-end text-dimgray-100">
        <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
          <div className="self-stretch rounded bg-whitesmoke-200 overflow-hidden flex flex-row items-center justify-start py-1.5 px-3">
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/minus.svg"
            />
            <div className="self-stretch w-0 relative leading-[20px] inline-block" />
          </div>
          <div className="relative text-base leading-[26px] font-poppins text-gray-100 text-center">
            1
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container1;
