const PopUpScreen = () => {
  return (
    <section className="w-[336px] rounded-14xl bg-white shadow-[0px_4px_20px_5px_rgba(0,_0,_0,_0.29)] flex flex-col items-center justify-start pt-[41px] pb-[42px] pr-7 pl-[29px] box-border gap-[44px] max-w-full z-[2] text-left text-lg text-grey-2 font-open-sans mq450:gap-[22px] mq450:pt-[27px] mq450:pb-[27px] mq450:box-border">
      <div className="w-[336px] h-[356px] relative rounded-14xl bg-white shadow-[0px_4px_20px_5px_rgba(0,_0,_0,_0.29)] hidden max-w-full" />
      <div className="flex flex-col items-center justify-start py-0 px-2.5 gap-[31px] mq450:gap-[15px]">
        <div className="w-32 flex flex-row items-center justify-center">
          <div className="h-32 flex-1 relative">
            <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-silver-100 box-border w-[146px] h-[146px] z-[1] border-[9px] border-solid border-crimson-100" />
            <img
              className="absolute top-[46px] left-[33px] w-[63px] h-[37px] z-[2]"
              alt=""
              src="/arrow-vector.svg"
            />
          </div>
        </div>
        <div className="h-[25px] relative font-semibold inline-block z-[1]">
          Your Verification is Successful
        </div>
      </div>
      <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch h-[45px] relative">
        <img
          className="absolute top-[12.9px] left-[153.6px] w-[9.4px] h-[24.6px] hidden z-[1]"
          alt=""
          src="/group-5.svg"
        />
        <div className="absolute top-[0px] left-[0px] rounded-4xs bg-crimson-100 w-full flex flex-row items-center justify-center py-2.5 pr-6 pl-5 box-border h-full z-[1]">
          <div className="h-[45px] w-[279px] relative rounded-4xs bg-crimson-100 hidden" />
          <div className="h-[25px] relative text-lg font-semibold font-open-sans text-white text-left inline-block z-[2]">
            Proceed
          </div>
        </div>
      </button>
    </section>
  );
};

export default PopUpScreen;
