/**
 * 
 */

export default function ReviewCard({
    avatar1,
    jinnyOslin,
    rating21,
    quickDeliveryGoodDishesIL
}){
    return (
//   <div class="p-4 max-w-xs">
//     <p class="whitespace-nowrap">This is a long text that won't wrap and will overflow horizontally if it's wider than the container.</p>
//   </div>
        <div className="flex rounded bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] flex flex-col items-center justify-start p-4 box-border gap-[8px] min-w-xs text-left text-xs text-gray-100 font-poppins">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <img
                className="h-9 w-9 relative rounded-lg overflow-hidden shrink-0 object-cover"
                loading="eager"
                alt=""
                src={avatar1}
              />
              <div className="flex flex-col items-start justify-start">
                <div className="relative leading-[20px] font-semibold">
                  {jinnyOslin}
                </div>
                <div className=" whitespace-nowrap  relative text-2xs leading-[18px] font-inter text-lightslategray-100 text-right pr-1">
                  A day ago
                </div>
              </div>
            </div>
            <img
              className="h-4 w-[85px] relative"
              loading="eager"
              alt=""
              src={rating21}
            />
          </div>
          <div className="self-stretch h-10 relative leading-[20px] font-inter inline-block">
            {quickDeliveryGoodDishesIL}
          </div>
        </div>
      );
}