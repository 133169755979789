/**
 * 
 */
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import MealCard from "./MealCard";

export default function MealRecommendation() {
    return (
        <section className="py-2 pl-5 pr-[65px] font-poppins">
            <div className="flex flex-row items-center justify-between">
                <h3 className="text-bold">
                    For you
                </h3>
                <a href="" className="text-sm font-bold text-gray-400">View all</a>
            </div>
            <div className="flex flex-wrap items-center justify-between font-poppins">
                <MealCard
                    image="/image-37@2x.png"
                    title="Jollof Rice"
                    rating="4.8 "
                    price="N1,000"
                />
                <MealCard
                    image="/image-38@2x.png"
                    title="Chicken Salad"
                    rating="4.8 "
                    price="N1,250"
                />
                <MealCard
                    image="/image-40@2x.png"
                    title="Spicy Chicken"
                    rating="4.5 "
                    price="N2,000"
                />
                <MealCard
                    image="/image-39@2x.png"
                    title="Fried Chips"
                    rating="4.5 "
                    price="N1,500"
                />
            </div>
        </section>
    );
}