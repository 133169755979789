import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LandingPage1 from "./pages/LandingPage1";
import OrderReview from "./pages/OrderReview";
import FoodDetails from "./pages/FoodDetails";
import RestaurantDetails from "./pages/RestaurantDetails";
import Search1 from "./pages/Search1";
import SocialFeed from "./pages/feeds"
import BrowseByCategory from "./pages/BrowseByCategory";
import OrderTracking1 from "./pages/OrderTracking1";
import OrderTracking from "./pages/OrderTracking";
import RatingAndReview1 from "./pages/RatingAndReview1";
import RatingAndReview from "./pages/RatingAndReview";
// import HomePage1 from "./pages/HomePage1";
import HomePage from "./pages/HomePage";
import HomePage2 from "./pages/HomePage2";
// import Homepage2 from "./pages/HomePage2";
import Menu from "./pages/Menu";
import Payment from "./pages/Payment";
import Checkout from "./pages/Checkout";
import Cart from "./pages/Cart";
import Search from "./pages/Search";
import Detail from "./pages/Detail";
import ForgotPassword from "./pages/ForgotPassword";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import MobileResponsiveSolution from "./pages/MobileResponsiveSolution";
import ResetPassword from "./pages/ResetPassword";
import PaymentSuccess from "./pages/PaymentSuccess";
import EnterOTP1 from "./pages/EnterOTP1";
import EnterOTP from "./pages/EnterOTP";
import Screen1 from "./pages/Screen1";
import MobileResponsiveSolution1 from "./pages/MobileResponsiveSolution1";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/food-details":
        title = "";
        metaDescription = "";
        break;
      case "/restaurant-details":
        title = "";
        metaDescription = "";
        break;
      case "/search":
        title = "";
        metaDescription = "";
        break;
      case "/browse-by-category":
        title = "";
        metaDescription = "";
        break;
      case "/order-tracking1":
        title = "";
        metaDescription = "";
        break;
      case "/order-tracking":
        title = "";
        metaDescription = "";
        break;
      case "/rating-and-review":
        title = "";
        metaDescription = "";
        break;
      case "/rating-and-review1":
        title = "";
        metaDescription = "";
        break;
      case "/home-page":
        title = "";
        metaDescription = "";
        break;
      case "/home-page1":
        title = "";
        metaDescription = "";
        break;
      case "/menu":
        title = "";
        metaDescription = "";
        break;
      case "/payment":
        title = "";
        metaDescription = "";
        break;
      case "/checkout":
        title = "";
        metaDescription = "";
        break;
      case "/cart":
        title = "";
        metaDescription = "";
        break;
      case "/search1":
        title = "";
        metaDescription = "";
        break;
      case "/detail":
        title = "";
        metaDescription = "";
        break;
      case "/forgot-password":
        title = "";
        metaDescription = "";
        break;
      case "/sign-in":
        title = "";
        metaDescription = "";
        break;
      case "/sign-up":
        title = "";
        metaDescription = "";
        break;
      case "/mobile-responsive-solution-2":
        title = "";
        metaDescription = "";
        break;
      case "/reset-password":
        title = "";
        metaDescription = "";
        break;
      case "/payment-success":
        title = "";
        metaDescription = "";
        break;
      case "/enter-otp-2":
        title = "";
        metaDescription = "";
        break;
      case "/enter-otp":
        title = "";
        metaDescription = "";
        break;
      case "/screen-37":
        title = "";
        metaDescription = "";
        break;
      case "/mobile-responsive-solution-1":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/landing-page" element={<LandingPage1 />} />
      <Route path="/food-details" element={<FoodDetails />} />
      <Route path="/restaurant-details" element={<RestaurantDetails />} />
      <Route path="/search" element={<Search1 />} />
      <Route path="/restaurant" element={<BrowseByCategory />} />
      <Route path="/order-tracking1" element={<OrderTracking1 />} />
      <Route path="/order-tracking" element={<OrderTracking />} />
      <Route path="/order-review" element={<OrderReview />} />
      <Route path="/rating-and-review" element={<RatingAndReview1 />} />
      <Route path="/rating-and-review1" element={<RatingAndReview />} />
      <Route path="/home-page" element={<HomePage2 />} />
      <Route path="/home-page1" element={<HomePage />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/search1" element={<Search />} />
      <Route path="/detail" element={<Detail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/feeds" element={<SocialFeed />} />
      {/* <Route
        path="/mobile-responsive-solution-2"
        element={<MobileResponsiveSolution />}
      /> */}
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/enter-otp-2" element={<EnterOTP1 />} />
      <Route path="/enter-otp" element={<EnterOTP />} />
      <Route path="/screen-37" element={<Screen1 />} />
      {/* <Route
        path="/mobile-responsive-solution-1"
        element={<MobileResponsiveSolution1 />}
      /> */}
    </Routes>
  );
}
export default App;
