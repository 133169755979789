import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function RestaurantCard() {
  const navigate = useNavigate();

  const onRestaurantClick = useCallback(() => {
    navigate("/restaurant-details");
  }, [navigate]);
  const onMenuIcnClick = useCallback(() => {
    navigate("/enu");
  }, [navigate]);
  return (
    <div className="flex flex-col self-stretch">
      <div className="flex flex-row items-center justify-between text-[#FB6236]">
        <h3 className="text-sm text-medium">
          Restaurants
        </h3>
        <a href="" className="text-sm text-light text-[#FB6236]">See all</a>
      </div>
      <div className="flex flex-row flex-wrap items-center justify-evenly">
        <div className="rounded-3xs w-[117px] sm:w-[145px] mt-2 px-2 bg-chocolate-100 box-border flex flex-col items-center cursor-pointer"
        onClick={onRestaurantClick}>
          <img
            className="w-[90px] sm:w-[135px] pt-3 object-contain"
            loading="eager"
            alt=""
            src="/mask-group-12@2x.png"
          />
          <p className="text-2xs sm:text-xs font-semibold mb-0 text-white">Chicken Republic </p>
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-[11px] w-[53px]"
              loading="eager"
              alt="chopinns rating"
              src="/rating1.svg"
            />
          </div>
          <p className="font-semibold text-[0.6rem] sm:text-4xs mt-0 mb-2 text-xs">4.0 (5000 reviews)</p>
        </div>
        <div className="rounded-3xs w-[117px] sm:w-[145px] mt-2 px-2 bg-chocolate-100 box-border flex flex-col items-center">
          <img
            className="w-[90px] sm:w-[135px] pt-3 object-contain"
            loading="eager"
            alt=""
            src="/mask-group-22@2x.png"
          />
          <p className="text-2xs sm:text-xs font-semibold mb-0 text-white">KFC</p>
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-[11px] w-[53px]"
              loading="eager"
              alt="chopinns rating"
              src="/rating1.svg"
            />
          </div>
          <p className="font-semibold text-[0.6rem] sm:text-4xs mt-0 mb-2 text-xs">4.0 (5000 reviews)</p>
        </div>
        <div className="rounded-3xs w-[117px] sm:w-[145px] mt-2 px-2 bg-chocolate-100 box-border flex flex-col items-center">
          <img
            className="w-[90px] sm:w-[135px] pt-3 object-contain"
            loading="eager"
            alt=""
            src="/mask-group-31@2x.png"
          />
          <p className="text-2xs sm:text-xs font-semibold mb-0 text-white">Jevinik</p>
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-[11px] w-[53px]"
              loading="eager"
              alt="chopinns rating"
              src="/rating1.svg"
            />
          </div>
          <p className="font-semibold text-[0.6rem] sm:text-4xs mt-0 mb-2 text-xs">4.0 (5000 reviews)</p>
        </div>
        <div className="rounded-3xs w-[117px] sm:w-[145px] mt-2 px-2 bg-chocolate-100 box-border flex flex-col items-center">
          <img
            className="w-[90px] sm:w-[135px] pt-3 object-contain"
            loading="eager"
            alt=""
            src="/mask-group-41@2x.png"
          />
          <p className="text-2xs sm:text-xs font-semibold mb-0 text-white">BluCabana</p>
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-[11px] w-[53px]"
              loading="eager"
              alt="chopinns rating"
              src="/rating1.svg"
            />
          </div>
          <p className="font-semibold text-[0.6rem] sm:text-4xs mt-0 mb-2 text-xs">4.0 (5000 reviews)</p>
        </div>
        <div className="rounded-3xs w-[117px] sm:w-[145px] mt-2 px-2 bg-chocolate-100 box-border flex flex-col items-center">
          <img
            className="w-[90px] sm:w-[135px] pt-3 object-contain"
            loading="eager"
            alt=""
            src="/mask-group-41@2x.png"
          />
          <p className="text-2xs sm:text-xs font-semibold mb-0 text-white">BluCabana</p>
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-[11px] w-[53px]"
              loading="eager"
              alt="chopinns rating"
              src="/rating1.svg"
            />
          </div>
          <p className="font-semibold text-[0.6rem] sm:text-4xs mt-0 mb-2 text-xs">4.0 (5000 reviews)</p>
        </div>
        <div className="rounded-3xs w-[117px] sm:w-[145px] mt-2 px-2 bg-chocolate-100 box-border flex flex-col items-center">
          <img
            className="w-[90px] sm:w-[135px] pt-3 object-contain"
            loading="eager"
            alt=""
            src="/mask-group-41@2x.png"
          />
          <p className="text-2xs sm:text-xs font-semibold mb-0 text-white">BluCabana</p>
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-[11px] w-[53px]"
              loading="eager"
              alt="chopinns rating"
              src="/rating1.svg"
            />
          </div>
          <p className="font-semibold text-[0.6rem] sm:text-4xs mt-0 mb-2 text-xs">4.0 (5000 reviews)</p>
        </div>
      </div>
    </div>
  )
}