const MainFrame = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border max-w-full shrink-0 text-left text-base text-lightslategray-100 font-inter">
      <div className="flex-1 flex flex-col items-start justify-start gap-[16px] max-w-full">
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="h-10 overflow-hidden flex flex-col items-end justify-start pt-[17px] pb-0 pr-[15px] pl-[30px] box-border gap-[24px]">
            <img
              className="w-[27.3px] h-[10.7px] relative"
              loading="eager"
              alt=""
              src="/group.svg"
            />
            <div className="h-0 flex flex-row items-start justify-start py-0 px-[7px] box-border">
              <img className="h-0 w-0 relative" loading="eager" alt="" />
            </div>
          </div>
          <div className="w-24 overflow-hidden shrink-0 flex flex-col items-start justify-center pt-[17px] pb-[13px] pr-[18px] pl-3 box-border relative">
            <img
              className="self-stretch h-[10.6px] relative max-w-full overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/group-2.svg"
            />
            <img
              className="w-0 h-0 absolute my-0 mx-[!important] right-[45.5px] bottom-[-10.5px]"
              loading="eager"
              alt=""
            />
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col items-start justify-start py-0 pr-[5px] pl-0 box-border gap-[16px] max-w-full">
          <div className="w-[359px] h-11 flex flex-row items-center justify-start gap-[13px] max-w-full">
            <div className="self-stretch flex-1 flex flex-row items-center justify-start">
              <div className="self-stretch rounded bg-gray-1300 overflow-hidden flex flex-row items-center justify-start py-[9px] px-4 z-[1]">
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0"
                  loading="eager"
                  alt=""
                  src="/chevron-left-large1.svg"
                />
                <div className="self-stretch w-0 relative leading-[26px] inline-block" />
              </div>
              <div className="flex-1 rounded bg-whitesmoke-200 flex flex-row items-center justify-between pt-[7px] pb-1.5 pr-[11px] pl-3 gap-[20px] whitespace-nowrap ml-[-12px] text-sm text-gray-100">
                <div className="relative leading-[22px]">{`Jollof Rice & Chicken`}</div>
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0"
                  alt=""
                  src="/e-remove.svg"
                />
              </div>
            </div>
            <input
              className="m-0 h-7 w-7 relative overflow-hidden shrink-0"
              type="checkbox"
            />
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 box-border max-w-full text-xs text-new-2">
            <div className="flex-1 flex flex-row items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[11px] max-w-full mq450:flex-wrap">
              <div className="flex-1 rounded-sm bg-seashell overflow-hidden flex flex-row items-center justify-start py-1 px-2 box-border gap-[1px] min-w-[51px] whitespace-nowrap mq450:flex-1">
                <div className="relative leading-[20px]">Sort by</div>
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0"
                  alt=""
                  src="/chevron-down-large.svg"
                />
              </div>
              <button className="cursor-pointer [border:none] py-1 pr-[9px] pl-2 bg-whitesmoke-200 rounded-sm overflow-hidden flex flex-row items-center justify-center hover:bg-gainsboro-300">
                <div className="relative text-xs leading-[20px] font-inter text-darkslategray-200 text-left">
                  Freeship
                </div>
              </button>
              <button className="cursor-pointer [border:none] py-1 pr-[9px] pl-2 bg-whitesmoke-200 rounded-sm overflow-hidden flex flex-row items-center justify-center hover:bg-gainsboro-300">
                <div className="relative text-xs leading-[20px] font-inter text-darkslategray-200 text-left">
                  Favorite
                </div>
              </button>
              <button className="cursor-pointer [border:none] py-1 pr-[5px] pl-2 bg-whitesmoke-200 flex-1 rounded-sm overflow-hidden flex flex-row items-center justify-center box-border min-w-[44px] whitespace-nowrap hover:bg-gainsboro-300 mq450:flex-1">
                <div className="relative text-xs leading-[20px] font-inter text-darkslategray-200 text-left">
                  Near you
                </div>
              </button>
              <button className="cursor-pointer [border:none] p-1 bg-whitesmoke-200 w-[54px] rounded-sm overflow-hidden shrink-0 flex flex-row items-center justify-center box-border hover:bg-gainsboro-300">
                <div className="relative text-xs leading-[20px] font-inter text-darkslategray-200 text-left">
                  Parner
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainFrame;
