/**
 * 
 */

import MenuCard from "./MenuCard";

export default function MealCombo(){
    return(
        // <section className="self-stretch flex flex-col items-start justify-start gap-[16px] text-left text-base text-darkslategray-200 font-poppins"></section>
        <section className="py-2 pl-5 pr-[65px] font-poppins">
            <p className="font-extrabold">Combo</p>
            <div className="flex flex-wrap items-center justify-between gap-[16px] font-poppins">
                <MenuCard
                    image="/image-43@2x.png"
                    title="Combo B"
                    subTitle="Fried Chicken, Jollof Rice & Salad"
                    price="N1,850"
                    rating="4.5"
                    maxRate = "90"
                />
                <MenuCard
                    image="/image-44@2x.png"
                    title="Combo B"
                    subTitle="Fried Chicken (Small), Potatos & Sauce"
                    price="N2,000"
                    rating="4.6"
                    maxRate = "75"
                />
            </div>
        </section>
    );
}