/**
 * 
 */

export default function MenuCard({ image, title, subTitle, price, rating, maxRate = 99 }) {
    return (
        <div className="w-full md:w-[249px] lg:w-[300px] rounded bg-white flex flex-row items-start justify-start gap-[12px] text-sm font-poppins">
            <img
                className="h-[90px] w-[90px] rounded-md object-cover "
                loading="eager"
                alt=""
                src={image}
            />
            <span
                className="w-full flex flex-col items-start justify-start gap-[26px]"
            >
                <span className="flex flex-col items-start justify-start">
                    <span className="">{title}</span>
                    <span className="text-xs font-inter text-lightslategray-100">
                        {subTitle}
                    </span>
                </span>
                <span className="w-full flex flex-row items-center justify-between ">
                    <b className="">{price}</b>
                    <span className="flex flex-row items-center justify-start text-xs">
                        <img
                            className="h-4 w-4 relative"
                            loading="eager"
                            alt=""
                            src="/rating-17.svg"
                        />
                        <span>
                            <span>{rating}</span>
                            <span className="text-lightslategray-200"> ({maxRate})</span>
                        </span>
                    </span>
                </span>
            </span>
        </div>
    );
}