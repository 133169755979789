import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const NoteFrame = () => {
  const navigate = useNavigate();

  const onOrderReviewClick = useCallback(() => {
    navigate("/order-review");
  }, [navigate]);

  return (
    <div className="self-stretch h-[202px] flex flex-col items-start justify-start pt-0 pb-[42px] pr-[22px] pl-0 box-border gap-[1px] text-left text-sm text-darkslategray-100 font-inter">
      <b className="relative leading-[22px]">Note for restaurant</b>
      <div className="self-stretch rounded bg-whitesmoke-200 flex flex-col items-center justify-start pt-[7px] pb-[35px] pr-[11px] pl-3 relative whitespace-nowrap text-silver-300">
        <div className="self-stretch relative leading-[22px]">Special note</div>
        <img
          className="w-3 h-3 absolute my-0 mx-[!important] right-[1px] bottom-[1px] overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/resizing-handle.svg"
        />
      </div>
      <div className="self-stretch flex-1 flex flex-row items-start justify-center py-0 px-5 text-xs text-dimgray-100">
        <div className="self-stretch w-[152px] flex flex-col items-center justify-center relative">
          <div className="self-stretch flex-1 rounded bg-white overflow-hidden flex flex-row items-center justify-start p-5 gap-[20px]">
            <div className="self-stretch flex-1 rounded bg-whitesmoke-200 shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] overflow-hidden flex flex-row items-center justify-start py-1.5 px-2.5">
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/remove.svg"
              />
              <div className="self-stretch w-0 relative leading-[20px] inline-block" />
            </div>
            <div className="relative text-base leading-[26px] text-gray-100 text-center">
              1
            </div>
            <div className="self-stretch flex-1 rounded bg-new-2 shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] overflow-hidden flex flex-row items-center justify-start py-1.5 px-2.5 text-white">
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/e-add.svg"
              />
              <div className="self-stretch w-0 relative leading-[20px] inline-block" />
            </div>
          </div>
          <div className="w-[335px] my-0 mx-[!important] absolute right-[-114px] bottom-[-42px] rounded-10xs bg-new-2 overflow-hidden flex flex-row items-end justify-center pt-[9px] pb-0 pr-[21px] pl-5 box-border z-[1] text-base text-white font-poppins"
          onClick={onOrderReviewClick}>
            <div className="h-[35px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="h-[52px] relative leading-[26px] font-semibold inline-block">
                <p className="m-0">Add to cart (N3,650)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteFrame;
