import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const SigningUp2 = () => {
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/enter-otp");
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-col items-center justify-start pt-[34px] pb-[229px] pr-[23px] pl-6 relative gap-[36px] z-[2] text-left text-3xs text-midnightblue font-inter mq450:gap-[18px] mq450:pt-[22px] mq450:pb-[149px] mq450:box-border">
      <textarea
        className="bg-white h-[266px] w-full [outline:none] absolute my-0 mx-[!important] top-[0px] right-[0px] left-[0px] rounded-mini box-border border-[2px] border-solid border-crimson-100"
        rows={13}
        cols={16}
      />
      <div className="self-stretch flex flex-row items-center justify-center z-[1]">
        <div className="flex-1 flex flex-row items-end justify-start py-[15px] px-2 relative gap-[7px]">
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-3xs bg-white box-border border-[1px] border-solid border-crimson-100" />
          <img
            className="h-2.5 w-[13.1px] relative z-[1]"
            alt=""
            src="/group.svg"
          />
          <input
            className="w-[135px] [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-0 px-0 pb-[3px] box-border font-inter font-medium text-sm text-goldenrod"
            placeholder="johndoe@gmail.com"
            type="text"
          />
        </div>
      </div>
      <div className="w-[91.1px] h-[78.2px] relative hidden z-[2]">
        <div className="absolute h-[19.18%] w-[77.94%] top-[0%] left-[22.06%] inline-block">
          Remember me
        </div>
        <img
          className="absolute h-[18.16%] w-[14.27%] top-[81.84%] right-[85.73%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-end justify-start gap-[27px] text-sm text-goldenrod">
        <div className="self-stretch flex flex-row items-center justify-center z-[1]">
          <div className="flex-1 flex flex-row flex-wrap items-start justify-start pt-3.5 pb-[11px] pr-3 pl-[7px] relative gap-[9px]">
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0.4px] right-[0px] bottom-[-0.4px] left-[0px] rounded-3xs bg-white box-border border-[1px] border-solid border-crimson-100" />
            <img
              className="h-[16.3px] w-3 relative z-[1]"
              loading="eager"
              alt=""
              src="/lock.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start min-w-[127px]">
              <div className="relative font-medium z-[1]">************</div>
            </div>
            <div className="h-5 relative text-smi font-black font-poppins text-dimgray-400 inline-block z-[1]">
              Show
            </div>
          </div>
        </div>
        <button
          className="cursor-pointer [border:none] py-[13px] px-5 bg-[transparent] self-stretch flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
          onClick={onGroupButtonClick}
        >
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[2px] rounded-xl bg-crimson-100" />
          <b className="w-[54.1px] relative text-mini inline-block font-open-sans text-white text-left shrink-0 z-[1]">
            Sign in
          </b>
        </button>
      </div>
    </div>
  );
};

export default SigningUp2;
