import FooterFrame from "./FooterFrame";

const FrameComponent7 = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-3 box-border max-w-full text-left text-13xl text-crimson-100 font-poppins">
      <div className="w-[1440px] bg-white flex flex-col items-center justify-start pt-[76px] pb-[38px] pr-5 pl-[61px] box-border gap-[95px] max-w-[101%] shrink-0 z-[4] lg:pl-[30px] lg:box-border mq450:gap-[24px] mq450:pt-[49px] mq450:pb-[25px] mq450:box-border mq750:gap-[47px]">
        <img
          className="w-[1440px] h-[433px] relative hidden max-w-full"
          alt=""
          src="/rectangle-6.svg"
        />
        <div className="w-[1211px] flex flex-row items-start justify-between gap-[20px] max-w-full lg:flex-wrap">
          <div className="w-[325px] flex flex-col items-start justify-start gap-[19px] min-w-[325px] max-w-full lg:flex-1">
            <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <h2 className="m-0 h-12 relative text-inherit font-black font-inherit inline-block z-[1] mq450:text-lgi mq1050:text-7xl">
                Chop Inns
              </h2>
              <div className="self-stretch relative text-lg text-style z-[1]">
                Swift Healthy Cuisine
              </div>
            </div>
            <div className="h-5 flex flex-row items-start justify-start py-0 pr-px pl-0 box-border gap-[25px]">
              <img
                className="h-[20.4px] w-[10.9px] relative z-[1]"
                loading="eager"
                alt=""
                src="/facebook1.svg"
              />
              <img
                className="h-[17.8px] w-[17.8px] relative z-[1]"
                loading="eager"
                alt=""
                src="/instagram1.svg"
              />
              <img
                className="h-[16.5px] w-[20.4px] relative z-[1]"
                loading="eager"
                alt=""
                src="/twitter1.svg"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start pt-2.5 px-0 pb-0 box-border min-w-[777px] max-w-full text-lg text-style lg:flex-1 mq1050:min-w-full">
            <div className="flex flex-row items-start justify-start gap-[76px] max-w-full mq450:gap-[19px] mq1050:flex-wrap mq1050:gap-[38px]">
              <div className="flex flex-col items-start justify-start py-0 pr-[9px] pl-0 gap-[11px]">
                <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[3px] text-5xl text-crimson-100">
                  <div className="h-9 relative font-black inline-block z-[1] mq450:text-lgi">
                    About Us
                  </div>
                </div>
                <div className="h-[27px] relative inline-block z-[1]">
                  About Us
                </div>
                <div className="h-[27px] relative inline-block z-[1]">
                  Service Us
                </div>
                <div className="h-[27px] relative inline-block z-[1]">
                  Contact
                </div>
                <div className="relative z-[1]">Company</div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[11px]">
                <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[3px] text-5xl text-crimson-100">
                  <div className="h-9 relative font-black inline-block z-[1] mq450:text-lgi">
                    Company
                  </div>
                </div>
                <div className="relative z-[1]">Partnership</div>
                <div className="h-[27px] relative inline-block z-[1]">
                  Terms of Use
                </div>
                <div className="relative z-[1]">Privacy</div>
                <div className="relative z-[1]">Sitemap</div>
              </div>
              <div className="w-[379px] flex flex-col items-start justify-start gap-[34px] max-w-full text-5xl text-crimson-100 mq450:gap-[17px]">
                <div className="h-9 relative font-black inline-block z-[1] mq450:text-lgi">
                  Get in touch
                </div>
                <FooterFrame
                  joinTheHealthMealFamily="Join the healthy meal family"
                  rectangle10="/rectangle-10.svg"
                  rectangle1="/rectangle-1.svg"
                  propGap="48px"
                  propMinHeight="unset"
                  propPadding="unset"
                  propFlexWrap="unset"
                  propWidth="338px"
                  propWidth1="118px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative text-base font-inter text-style z-[1]">
          Copyright © 2024 Chop Inns.
        </div>
      </div>
    </section>
  );
};

export default FrameComponent7;
