import { useMemo } from "react";

const OrderOnlineFrameGroceries = ({ propWidth, propAlignSelf }) => {
  const orderOnlineFrameGroceriesStyle = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  return (
    <div
      className="w-[403px] flex flex-row items-start justify-start relative max-w-full"
      style={orderOnlineFrameGroceriesStyle}
    >
      <img
        className="h-[490px] flex-1 relative rounded-xl max-w-full overflow-hidden object-cover"
        loading="eager"
        alt=""
        src="/rectangle-4597@2x.png"
      />
      <button className="cursor-pointer [border:none] pt-2.5 pb-2 pr-[5px] pl-[9px] bg-crimson-100 my-0 mx-[!important] absolute bottom-[-17px] left-[129px] rounded-81xl flex flex-row items-center justify-center whitespace-nowrap z-[1] hover:bg-tomato-100">
        <div className="h-[45px] w-[131px] relative rounded-81xl bg-crimson-100 hidden" />
        <div className="h-[27px] relative text-lg capitalize font-poppins text-white text-left inline-block z-[1]">
          Order Online
        </div>
      </button>
    </div>
  );
};

export default OrderOnlineFrameGroceries;
