import DeliveryServiceFrame from "./DeliveryServiceFrame";

const SearchBox = () => {
  return (
    <section className="flex flex-row items-start justify-start py-0 pr-20 pl-[66px] box-border min-h-[516px] max-w-full text-center text-lg text-black font-montagu-slab mq750:pl-[33px] mq750:pr-10 mq750:box-border">
      <div className="w-[1294px] flex flex-row flex-wrap items-start justify-center gap-[18px] max-w-full">
        <DeliveryServiceFrame
          onlineOrderButton="1,500"
          bellefulJollof="Belleful  Jollof"
          steamJollofRiceWith2Soulf="Steam Jollof Rice with 2 Soulfully spiced chicken"
          propHeight="36px"
        />
        <DeliveryServiceFrame
          onlineOrderButton="1,800"
          bellefulJollof={`Chicken & Chips`}
          steamJollofRiceWith2Soulf="Fried chips with chicken, hot chilli Sauce"
          propMarginLeft="-1px"
          propHeight="unset"
        />
        <div className="flex-1 flex flex-col items-center justify-start min-w-[263px] max-w-[310px]">
          <div className="self-stretch rounded-xl [background:linear-gradient(179.69deg,_rgba(255,_255,_255,_0),_#f54748)] flex flex-col items-center justify-start pt-[146px] pb-[72px] pr-[33px] pl-[34px] gap-[30px] mq450:gap-[15px] mq450:pt-[95px] mq450:pb-[47px] mq450:box-border">
            <div className="w-[310px] relative rounded-xl [background:linear-gradient(179.69deg,_rgba(255,_255,_255,_0),_#f54748)] h-[368px] hidden" />
            <div className="flex flex-row items-start justify-start py-0 pr-0 pl-[3px]">
              <div className="flex flex-row items-start justify-start">
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0 z-[1]"
                  alt=""
                  src="/naira.svg"
                />
                <div className="relative capitalize font-medium z-[2] ml-[-3px]">
                  2,800
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[13px] text-5xl text-crimson-100 font-poppins">
              <div className="relative capitalize font-black z-[1] mq450:text-lgi">
                Asun Spagheti
              </div>
              <div className="self-stretch relative text-base capitalize text-gray-300 z-[1]">{`Smokey Hot Spaghetti & Peppered Asun`}</div>
            </div>
          </div>
          <button className="cursor-pointer [border:none] pt-2.5 pb-2 pr-3 pl-5 bg-crimson-100 rounded-81xl flex flex-row items-center justify-end whitespace-nowrap z-[1] mt-[-27px] hover:bg-tomato-100">
            <div className="h-[45px] w-[131px] relative rounded-81xl bg-crimson-100 hidden" />
            <div className="h-[27px] relative text-lg capitalize font-poppins text-white text-left inline-block z-[1]">
              Order Now
            </div>
          </button>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start min-w-[263px] max-w-[310px]">
          <div className="self-stretch rounded-xl [background:linear-gradient(179.69deg,_rgba(255,_255,_255,_0),_#f54748)] flex flex-col items-center justify-start pt-[146px] pb-[72px] pr-[33px] pl-[34px] gap-[30px] mq450:gap-[15px] mq450:pt-[95px] mq450:pb-[47px] mq450:box-border">
            <div className="w-[310px] relative rounded-xl [background:linear-gradient(179.69deg,_rgba(255,_255,_255,_0),_#f54748)] h-[368px] hidden" />
            <div className="flex flex-row items-start justify-start">
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 z-[1]"
                alt=""
                src="/naira.svg"
              />
              <div className="relative capitalize font-medium z-[2]">1,500</div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[13px] text-5xl text-crimson-100 font-poppins">
              <div className="relative capitalize font-black z-[1] mq450:text-lgi">
                Pro Ewa Agoyin
              </div>
              <div className="self-stretch h-12 relative text-base capitalize text-gray-300 inline-block z-[1]">{`Ewa Agoyin with Fried Fish, Pomo & Dodo`}</div>
            </div>
          </div>
          <button className="cursor-pointer [border:none] pt-2.5 pb-2 pr-3 pl-5 bg-crimson-100 rounded-81xl flex flex-row items-center justify-end whitespace-nowrap z-[1] mt-[-27px] hover:bg-tomato-100">
            <div className="h-[45px] w-[131px] relative rounded-81xl bg-crimson-100 hidden" />
            <div className="h-[27px] relative text-lg capitalize font-poppins text-white text-left inline-block z-[1]">
              Order Now
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default SearchBox;
