/**
 * 
 */

export default function RestaurantListCard({
    image, title, subTitle, time, rating, tags = []
}) {
    return (
        // shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] 
        <div className="flex flex-row items-start justify-start px-5 gap-[16px] text-sm font-poppins">
            <img
                className="w-[90px] rounded-md object-cover"
                loading="eager"
                alt=""
                src={image}
            />
            <div className="flex flex-col items-start justify-start">
                <p className=" m-0 font-semibold">
                    {title}
                </p>
                <p className="text-xs m-0 mt-1 font-inter text-lightslategray-100">
                    {subTitle}
                </p>
                <div className="flex flex-col items-start justify-start gap-[2px] mt-3 text-xs text-lightslategray-100 font-inter">
                    <div className="flex flex-row items-center justify-start">
                    <p className="m-0"> {time} Mins <span className="mx-1">•</span> {rating}</p>
                        <img
                            className="h-3 w-3 mx-1"
                            loading="eager"
                            alt=""
                            src="/rating-13.svg"
                        />
                    </div>
                    <div className="ml-2 shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] bg-honeydew-200 flex flex-row items-center justify-center text-2xs text-limegreen">
                        <div className="p-1">Freeship</div>
                    </div>
                </div>
            </div>
        </div>
    );
}