/**
 * 
 */

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";


export default  function RestaurantNav(){
    const navigate = useNavigate();

    const onSocialFeedClick = useCallback(() => {
      navigate("/feeds");
    }, [navigate]);
    
    const onResturantClick = useCallback(() => {
      navigate("/restaurant");
    }, [navigate]);
  
    const onHomePageClick = useCallback(() => {
      navigate("/home-page");
    }, [navigate]);
  
    return (
        <nav className="self-stretch bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] flex flex-row items-center justify-between p-4 text-xs text-grey-2 font-poppins">
            <div className="flex flex-col items-center justify-center cursor-pointer"
                onClick={onHomePageClick}>
                <img
                    className="w-7 h-7"
                    loading="eager"
                    alt="chopinns home page link icon"
                    src="/home.svg"
                />
                <b className="">Home</b>
            </div>
            <div className="flex flex-col items-center justify-center cursor-pointer" onClick={onResturantClick}>
                <img
                    className="w-7 h-7"
                    loading="eager"
                    alt="chopinns restaurant page link icon"
                    src="/group-8515.svg"
                />
                <b className="">Restaurant</b>
            </div>
            <div className="flex flex-col items-center justify-center cursor-pointer" onClick={onSocialFeedClick}>
                <img
                    className="w-7 h-7"
                    loading="eager"
                    alt="chopinns feed page link icon"
                    src="/group-85161.svg"
                />
                <b className="">Feed</b>
            </div>
            <div className="flex flex-col items-center justify-center cursor-pointer" onClick={onResturantClick}>
                <img
                    className="w-7 h-7"
                    loading="eager"
                    alt="chopinns restaurant page link icon"
                    src="/account-circle.svg"
                />
                <b className="">Profile</b>
            </div>
        </nav>
   );
}