/**
 * 
 */

export default function PopularMeals() {
    return (
        <div className="">
            <div className="rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] flex flex-row items-start justify-start text-white p-4">
                <img
                    className="w-[89px] object-cover"
                    loading="eager"
                    alt="popular deals"
                    src="/mask-group-7@2x.png"
                />
                <div className="flex flex-col px-2 items-start justify-start">
                    <p className="mt-0 mb-1.5 font-bold text-[1.2rem]">Today’s Special</p>
                    <p className="m-0 text-[0.96rem] text-semibold">Mouthwatering Goat Meat Pepper Soup, Diced Plaintain & Utazi Leaves with a  Coca cola drink.</p>
                    <p className="mt-1.5 mb-0"><a href="" className="text-bold text-gold"> Order Now</a></p>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between py-2 text-[#FB6236]">
                <h3 className="text-sm text-medium text-bold">
                    Popular
                </h3>
                <a href="" className="text-sm font-bold text-[#FB6236]">See all</a>
            </div>
            {/* <div className="self-stretch flex flex-row items-start justify-start relative max-w-full text-left text-3xs text-darkslategray-200 font-poppins"> */}
            <div className="self-stretch flex flex-row items-center justify-between font-poppins">
                <div className="rounded pt-2 w-[80px] sm:w-[100px] bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] text-5xs text-gray-100">
                    <img
                        className="w-[80px] object-cover cursor-pointer"
                        loading="eager"
                        alt=""
                        src="/image-371@2x.png"
                    />
                    <p className="text-2xs m-0 font-semibold text-darkslategray-200">
                        Jollof Rice
                    </p>
                    <div className="flex flex-row items-start justify-start">
                        <img
                            className=""
                            loading="eager"
                            alt=""
                            src="/rating-131.svg"
                        />
                        <p className="m-0 text-4xs md:text-3xs font-light">
                            4.8
                            <span className="text-lightslategray-200">(99)</span>
                            <span className="text-3xs font-bold md:pl-3 md:text-xs ">N1,000</span>
                        </p>
                    </div>
                </div>
                <div className="rounded pt-2 w-[80px] sm:w-[100px] bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] text-5xs text-gray-100">
                    <img
                        className="w-[80px] sm:w-[100px] object-cover cursor-pointer"
                        loading="eager"
                        alt=""
                        src="/image-401@2x.png"
                    />
                    <p className="text-2xs m-0 font-semibold text-darkslategray-200">
                    Spicy Chicken
                    </p>
                    <div className="flex flex-row items-start justify-start">
                        <img
                            className=""
                            loading="eager"
                            alt=""
                            src="/rating-131.svg"
                        />
                        <p className="m-0 text-4xs md:text-3xs font-light">
                            4.5
                            <span className="text-lightslategray-200">(99)</span>
                            <span className="text-3xs font-bold md:pl-3 md:text-xs ">N2,000</span>
                        </p>
                    </div>
                </div>
                <div className="rounded pt-2 w-[80px] sm:w-[100px] bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] text-5xs text-gray-100">
                    <img
                        className="w-[80px] sm:w-[100px] object-cover cursor-pointer"
                        loading="eager"
                        alt=""
                        src="/image-381@2x.png"
                    />
                    <p className="text-2xs m-0 font-semibold text-darkslategray-200">
                    Chicken Salad
                    </p>
                    <div className="flex flex-row items-start justify-start">
                        <img
                            className=""
                            loading="eager"
                            alt=""
                            src="/rating-131.svg"
                        />
                        <p className="m-0 text-4xs md:text-3xs font-light">
                            4.9
                            <span className="text-lightslategray-200">(99)</span>
                            <span className="text-3xs font-bold md:pl-3 md:text-xs ">N2,000</span>
                        </p>
                    </div>
                </div>
                <div className="rounded pt-2 w-[80px] sm:w-[100px] bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] text-5xs text-gray-100">
                    <img
                        className="w-[80px] sm:w-[100px] object-cover cursor-pointer"
                        loading="eager"
                        alt=""
                        src="/image-39@2x.png"
                    />
                    <p className="text-2xs m-0 font-semibold text-darkslategray-200">
                    Fried Potatoes
                    </p>
                    <div className="flex flex-row items-start justify-start">
                        <img
                            className=""
                            loading="eager"
                            alt=""
                            src="/rating-131.svg"
                        />
                        <p className="m-0 text-4xs md:text-3xs font-light">
                            4.8
                            <span className="text-lightslategray-200">(99)</span>
                            <span className="text-3xs font-bold md:pl-3 md:text-xs ">N2,000</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
