import SigningUp2 from "../components/SigningUp2";

const ResetPassword = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-center pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-smi text-neutral-black font-poppins">
      <div className="h-6 w-80 overflow-hidden shrink-0 hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border font-source-sans-pro">
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="h-[45px] w-[162px] relative hidden text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="h-[52px] w-[183px] relative hidden text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <main className="flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-[30px] pb-[147px] pr-[57px] pl-11 box-border gap-[34px] max-w-full mq450:pb-[62px] mq450:box-border mq750:pt-5 mq750:pb-24 mq750:box-border">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
        <div className="flex flex-row items-start justify-start py-0 pr-0 pl-[13px]">
          <img
            className="h-[41px] w-[172px] relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/chopinns-v4-1-2@2x.png"
          />
        </div>
        <section className="self-stretch flex flex-col items-center justify-start gap-[15px] text-left text-2xl text-grey-2 font-poppins">
          <div className="w-[253px] flex flex-row items-start justify-start py-0 pr-0 pl-[13px] box-border">
            <div className="flex-1 flex flex-col items-center justify-start gap-[2px]">
              <div className="self-stretch flex flex-row items-center justify-center py-0 pr-px pl-0 z-[1]">
                <div className="h-[26px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
                  <h3 className="m-0 h-8 relative text-inherit font-black font-inherit inline-block">
                    Create New Password
                  </h3>
                </div>
              </div>
              <div className="relative text-3xs font-medium z-[1]">
                Please enter a case sensitive password
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-center relative">
            <img
              className="w-[9.4px] h-[24.6px] absolute my-0 mx-[!important] right-[133px] bottom-[112.5px] z-[1]"
              loading="eager"
              alt=""
              src="/group-5.svg"
            />
            <SigningUp2 />
          </div>
        </section>
      </main>
    </div>
  );
};

export default ResetPassword;
