import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Container8 = () => {
  const navigate = useNavigate();

  const onHomeTextClick = useCallback(() => {
    navigate("/home-page");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='restaurantText1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onAccountFrameClick = useCallback(() => {
    navigate("/social-feed");
  }, [navigate]);

  return (
    <section className="self-stretch bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_2px_5px_rgba(23,_26,_31,_0.13)] flex flex-row flex-wrap items-start justify-start py-4 pr-[43px] pl-[22px] [row-gap:20px] shrink-0 z-[1] text-left text-4xs text-white font-inter">
      <button
        className="cursor-pointer [border:none] py-0.5 pr-[23px] pl-[21px] bg-gray-1300 overflow-hidden flex flex-col items-center justify-start hover:bg-darkslategray-600"
        onClick={onHomeTextClick}
      >
        <img
          className="w-7 h-7 relative overflow-hidden shrink-0"
          alt=""
          src="/home.svg"
        />
        <b className="relative text-3xs leading-[16px] font-poppins text-dimgray-100 text-left">
          Home
        </b>
      </button>
      <div className="w-[98px] flex flex-col items-start justify-start py-0 pr-[23px] pl-0 box-border">
        <div className="self-stretch h-12 bg-gray-1300 overflow-hidden shrink-0 flex flex-col items-end justify-start pt-0 pb-0.5 pr-0.5 pl-[15px] box-border gap-[14px]">
          <div className="w-[34px] flex flex-row items-start justify-start py-0 px-[3px] box-border">
            <div className="flex-1 flex flex-row items-start justify-start relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] bottom-[-12px] left-[-12px] cursor-pointer"
                alt=""
                src="/group-8515.svg"
                onClick={onGroupIconClick}
              />
              <div className="flex-1 rounded-lg bg-crimson-200 flex flex-row items-center justify-center pt-[5px] px-[3px] pb-1 z-[1] border-[2px] border-solid border-white">
                <div className="relative leading-[9px]">99+</div>
              </div>
            </div>
          </div>
          <input
            className="w-[calc(100%_-_17px)] [border:none] [outline:none] inline-block font-poppins text-3xs bg-[transparent] self-stretch h-4 relative leading-[16px] font-bold text-new-2 text-left min-w-[35px]"
            placeholder="Restaurant"
            type="text"
            data-scroll-to="restaurantText1"
          />
        </div>
      </div>
      <div className="flex-1 flex flex-row items-start justify-start relative min-w-[118px] text-3xs text-dimgray-100 font-poppins">
        <img
          className="h-7 w-7 absolute my-0 mx-[!important] top-[2px] right-[3.5px] overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/account-circle.svg"
        />
        <div className="flex-1 flex flex-row items-end justify-between py-0 pr-px pl-0 gap-[20px]">
          <div className="flex flex-col items-center justify-start gap-[6px]">
            <img
              className="w-[25px] h-5 relative"
              loading="eager"
              alt=""
              src="/menu-icon.svg"
            />
            <b className="relative leading-[16px]">Menu</b>
          </div>
          <button
            className="cursor-pointer [border:none] py-0.5 pr-6 pl-[23px] bg-gray-1300 overflow-hidden flex flex-col items-center justify-start hover:bg-darkslategray-600"
            onClick={onAccountFrameClick}
          >
            <img className="w-7 h-7 relative" alt="" src="/group-8516.svg" />
            <b className="relative text-3xs leading-[16px] font-poppins text-dimgray-100 text-left">
              Feed
            </b>
          </button>
          <b className="relative leading-[16px]">Profile</b>
        </div>
      </div>
    </section>
  );
};

export default Container8;
