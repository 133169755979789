const FRAMEHOMEPAGE = () => {
  return (
    // <section className="w-full  absolute top-[-125px] flex flex-col items-center justify-start pt-[138px] pb-[21px] pr-[53px] pl-[70px] box-border gap-[25px] bg-[url('/public/food-banner-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1] text-left text-xs text-gray-1600 font-poppins">
    <section className="self-stretch p-4 bg-[url('/public/food-banner-1@2x.png')] bg-cover bg-no-repeat font-poppins">
      <img
        className="object-cover hidden max-w-full"
        alt="choppin intro banner"
        src="/food-banner-1@2x.png"
      />
      {/* <div className="self-stretch flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[7px] max-w-full"> */}
      {/* <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"> */}
      <div className="self-stretch flex flex-row items-start justify-between">
        <div className="flex flex-col items-start justify-start">
          {/* <div className="flex flex-col items-start justify-start gap-[8px]"> */}
          <div className="flex flex-row items-end justify-start">
            {/* <div className="  font-black text-white z-[2]">Your City</div> */}
            <select id="citiesdropdown" name="city" className="bg-inherit text-white font-bold text-[1.2rem]">
              <option value="" disabled selected>Your City</option>
              <option className="text-black" value="lagos" disabled>Lagos</option>
              <option className="text-black" value="abuja">Abuja</option>
            </select>
            {/* <div className="h-[11px] w-[21px] flex flex-col items-start justify-start pt-0 pb-[5px] pr-[9px] pl-0 box-border">
                  <img
                    className="w-[11.5px] h-[6.9px]   object-contain z-[2]"
                    alt=""
                    src="/search-frame.svg"
                  />
                </div> */}
            {/* <div className="h-1 w-1   rounded-[50%] bg-new-2 z-[2]" /> */}
          </div>
          <div className="flex flex-row items-start justify-start gap-[6px] pt-3 pl-1">
            <img
              className="pt-1"
              loading="eager"
              alt=""
              src="/iconlocation.svg"
            />
            <div className="text-white">Abuja</div>
          </div>
          {/* </div> */}
        </div>
        <img
          className="h-10 w-10   object-cover z-[2]"
          loading="eager"
          alt=""
          src="/mask-group2@2x.png"
        />
      </div>
      <h1 className="text-white sm:text-center text-[1.3rem]">
        Order Your Favorite Food Today
      </h1>
    </section>
  );
};

export default FRAMEHOMEPAGE;
