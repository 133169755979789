const ServiceFrame = () => {
  return (
    <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[63px] max-w-full text-left text-29xl text-black font-poppins mq450:gap-[16px] mq750:gap-[31px]">
      <div className="h-[619px] flex-1 relative min-w-[455px] max-w-full mq750:min-w-full">
        <img
          className="absolute top-[196px] left-[604px] w-24 h-[252px]"
          loading="eager"
          alt=""
          src="/decore-1.svg"
        />
        <div className="absolute top-[0px] left-[0px] w-full flex flex-row items-start justify-start h-full max-w-full">
          <img
            className="w-[405.5px] absolute my-0 mx-[!important] top-[21.1px] right-[-291.5px] h-[118.9px]"
            loading="eager"
            alt=""
            src="/vector-9.svg"
          />
          <div className="h-[619px] flex-1 relative z-[1] flex items-center justify-center">
            <img
              className="h-full flex-1 max-w-full overflow-hidden z-[1] object-contain absolute left-[118px] top-[66px] w-full [transform:scale(2.118)]"
              alt=""
              src="/group-8472@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start justify-start pt-[57px] px-0 pb-0 box-border min-w-[448px] max-w-full mq450:pt-[37px] mq450:box-border mq750:min-w-full">
        <div className="self-stretch flex flex-col items-start justify-start gap-[47px] max-w-full mq750:gap-[23px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[21px] max-w-full">
            <h1 className="m-0 w-[503px] h-[124px] relative text-inherit leading-[129.9%] font-bold font-inherit inline-block max-w-full mq450:text-10xl mq450:leading-[37px] mq1050:text-19xl mq1050:leading-[50px]">
              <span>{`We have `}</span>
              <span className="text-crimson-100">more</span>
              <span>{` to our `}</span>
              <span className="text-style1">delivery</span>
              <span> service</span>
            </h1>
            <div className="self-stretch relative text-lg text-gray-300">
              Take your feeding experience to the next level with us. Our
              services are specifically for you.
            </div>
          </div>
          <div className="w-[577px] flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[34px] max-w-full text-lg text-style mq750:gap-[17px]">
            <div className="flex flex-col items-start justify-start gap-[23px] max-w-full">
              <div className="w-[409px] flex flex-row items-start justify-between gap-[20px] max-w-full mq450:flex-wrap">
                <div className="h-7 flex flex-row items-start justify-start gap-[10px]">
                  <img
                    className="h-[26px] w-[26px] relative object-cover"
                    loading="eager"
                    alt=""
                    src="/image-15@2x.png"
                  />
                  <div className="h-[27px] relative font-medium inline-block">
                    Online Order
                  </div>
                </div>
                <div className="h-[33px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
                  <div className="flex-1 flex flex-row items-start justify-start gap-[16px]">
                    <img
                      className="h-6 w-6 relative object-cover"
                      loading="eager"
                      alt=""
                      src="/groceries-10963225-1@2x.png"
                    />
                    <div className="h-[27px] relative font-medium inline-block">
                      Groceries
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[33px] mq750:flex-wrap mq750:gap-[16px]">
                <div className="flex flex-row items-start justify-start gap-[12px]">
                  <img
                    className="h-6 w-6 relative object-cover"
                    loading="eager"
                    alt=""
                    src="/reserved-2702515-1@2x.png"
                  />
                  <div className="h-[27px] relative font-medium inline-block">
                    Restaurant Reservation
                  </div>
                </div>
                <div className="flex flex-row items-end justify-start gap-[16px]">
                  <img
                    className="h-6 w-6 relative object-cover"
                    alt=""
                    src="/restaurant-10309145-1@2x.png"
                  />
                  <div className="relative font-medium">Recipe Generation</div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq750:flex-wrap">
              <div className="flex flex-row items-end justify-start gap-[10px]">
                <img
                  className="h-6 w-6 relative object-cover"
                  loading="eager"
                  alt=""
                  src="/paymentmethod-4871460-1@2x.png"
                />
                <div className="h-[27px] relative font-medium inline-block">
                  Credit Purchase
                </div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[16px]">
                <img
                  className="h-6 w-6 relative object-cover"
                  loading="eager"
                  alt=""
                  src="/foodtray-3524457-1@2x.png"
                />
                <div className="relative font-medium">{`Food Competitions & Events`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceFrame;
