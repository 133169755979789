/**
 * 
 */
import RestaurantListCard from "./RestaurantListCard";

export default function RestaurantRecommendationList(){
    return(
        <section className="mx-4 mt-2 mb-4">
             <div className="flex flex-row items-center justify-between font-poppins">
                <h3 className="font-medium">
                Recommended for you
                </h3>
                <a href="" className="text-sm mr-8 font-bold text-gray-400">View all</a>
            </div>
            <div className="flex flex-wrap pt-4 gap-4">
                <RestaurantListCard
                    image="/image-27@2x.png"
                    title="Burger King"
                    subTitle="Best Burgers in Town"
                    time="35"
                    rating="4.1"
                    tags="Smokey Jollof & Asun"
                />
            </div>
        </section>
    );
}