import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const SigningUp = () => {
  const [coreIconChecked, setCoreIconChecked] = useState(true);
  const navigate = useNavigate();

  const onSigningUpClick = useCallback(() => {
    navigate("/home-page");
  }, [navigate]);

  const onForgotYourPasswordClick = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const onGroupContainerClick = useCallback(() => {
    navigate("/home-page");
  }, [navigate]);

  return (
    <section
      className="rounded-mini bg-white box-border flex flex-col items-center justify-start pt-[31px] pb-[73px] pr-[21px] pl-[22px] gap-[25px] max-w-full z-[2] text-left text-3xs text-midnightblue font-inter border-[2px] border-solid border-crimson-100 mq450:pt-5 mq450:pb-[47px] mq450:box-border">
      <div className="w-[327px] h-[462px] relative rounded-mini bg-white box-border hidden max-w-full border-[2px] border-solid border-crimson-100" />
      <button className="cursor-pointer bg-[transparent] rounded-3xs flex flex-row border-[1px] border-solid border-crimson-100 mq450:pl-5 mq450:pr-5 mq450:box-border">
        <div className="h-[45px] w-[280px] relative rounded-3xs box-border hidden border-[1px] border-solid border-crimson-100" />
        <img
          className="h-[27px] w-[27px] relative overflow-hidden shrink-0 z-[1]"
          alt=""
          src="/flatcoloriconsgoogle.svg"
        />
        <div className="flex flex-col items-start justify-start py-0 px-0">
          <a  href="#" className="w-[168px] relative text-base font-medium font-inter text-crimson-100 text-center inline-block z-[1]">
            Sign in with Google
          </a>
        </div>
      </button>
      <div className="w-[91.1px] h-[78.2px] relative hidden">
        <div className="absolute top-[0px] left-[calc(50%_-_25.45px)] inline-block w-[71px] h-[15px]">
          Remember me
        </div>
        <img
          className="absolute h-[18.16%] w-[14.27%] top-[81.84%] right-[85.73%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[21px] text-sm text-goldenrod">
        <div className="self-stretch h-[76px] flex flex-col items-start justify-start gap-[23px]">
          <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-500" />
          <div className="self-stretch rounded-3xs bg-white flex flex-row items-end justify-start pt-3.5 px-2 pb-4 gap-[7px] z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
            <img
              className="h-2.5 w-[13.1px] relative z-[1]"
              alt=""
              src="/group.svg"
            />
            <input
              className="text-gray-700 w-full [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-[0.40000000000009095px] px-0 pb-[3px] box-border font-inter font-medium text-sm"
              placeholder="johndoe@gmail.com"
              type="email"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[20px] min-h-[88px]">
          <div className="self-stretch rounded-3xs bg-white flex flex-row flex-wrap items-start justify-start pt-3.5 pb-[11px] pr-3 pl-[7px] gap-[9px] z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
            <img
              className="h-[16.3px] w-3 relative z-[1]"
              loading="eager"
              alt=""
              src="/lock.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start min-w-[127px]">
            <input
              className="text-gray-700 w-full [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-[0.40000000000009095px] px-0 pb-[3px] box-border font-inter font-medium text-sm"
              placeholder="************"
              type="password"
            />
            </div>
            <div className="h-5 relative text-smi font-black font-poppins text-gray-400 inline-block z-[1] cursor-pointer">
              Show
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-[3px] text-2xs text-black font-open-sans">
            <div
              className="relative tracking-[0.06em] cursor-pointer z-[1]"
              onClick={onForgotYourPasswordClick}
            >
              Forgot your password?
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row items-start justify-start py-0 px-2 box-border">
          <button className="cursor-pointer [border:none] p-0 bg-[transparent] h-[46px] flex-1 relative">
            <img
              className="absolute top-[18.9px] left-[152.6px] w-[9.4px] h-[24.6px] hidden z-[1]"
              alt=""
              src="/group-5.svg"
            />
            <div
              className="absolute top-[0px] left-[0px] rounded-xl bg-crimson-100 w-full flex flex-row items-center justify-center py-[13px] pr-[21px] pl-5 box-border h-full whitespace-nowrap cursor-pointer z-[1]"
              onClick={onGroupContainerClick}
            >
              <div className="h-[46px] w-[255px] relative rounded-xl bg-crimson-100 hidden" />
              <b className="relative text-mini font-open-sans text-white text-left z-[1]">
                Sign in
              </b>
            </div>
          </button>
        </div>
        <div className="w-[193px] flex flex-row items-start justify-start py-0 px-[7px] box-border text-2xs text-darkslategray-300 font-open-sans">
          <div className="flex-1 flex flex-row items-end justify-start gap-[6px]">
            <input
              className="accent-new-2 m-0 h-3.5 w-3.5 relative z-[1]"
              checked={coreIconChecked}
              type="checkbox"
              onChange={(event) => setCoreIconChecked(event.target.checked)}
            />
            <div className="flex-1 relative tracking-[0.03em] z-[1]">
              Remember me for 30 days
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SigningUp;
