import FrameFoodDetails from "../components/FrameFoodDetails";
import LineContainer from "../components/LineContainer";
import NoteFrame from "../components/NoteFrame";

const FoodDetails = () => {
  return (
    <div className="w-full relative bg-snow-400 shadow-[0px_3px_6px_rgba(18,_15,_40,_0.12)] overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[22px] box-border gap-[20px] tracking-[normal]">
      <FrameFoodDetails />
      <footer className="w-[397px] flex flex-row items-start justify-start py-0 pr-5 pl-[19px] box-border max-w-full">
        <div className="flex-1 flex flex-col items-start justify-start gap-[40px] max-w-full mq450:gap-[20px]">
          <LineContainer />
          <NoteFrame />
        </div>
      </footer>
    </div>
  );
};

export default FoodDetails;
