import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const OrderStatusFrame = () => {
  const navigate = useNavigate();

  const onUserVectorClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const onBackHomeTextClick = useCallback(() => {
    navigate("/home-page1");
  }, [navigate]);

  return (
    <footer className="w-full my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[0px] rounded-t-21xl rounded-b-none bg-snow-300 flex flex-col items-center justify-start pt-[38px] pb-8 pr-[61px] pl-[52px] box-border gap-[16px] max-w-full z-[3] text-left text-sm text-silver-200 font-poppins">
      <div className="w-[428px] h-[250px] relative rounded-t-21xl rounded-b-none bg-snow-300 hidden max-w-full" />
      <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
        <div className="w-[190px] flex flex-col items-start justify-start gap-[13px]">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-2">
            <div className="flex-1 flex flex-row items-start justify-between gap-[20px]">
              <div className="flex flex-col items-center justify-start gap-[8px]">
                <img
                  className="w-12 h-12 relative z-[4]"
                  loading="eager"
                  alt=""
                  src="/store.svg"
                />
                <b className="h-[21px] relative inline-block z-[4]">Store</b>
              </div>
              <div className="flex flex-col items-center justify-start gap-[8px] text-crimson-100">
                <img
                  className="w-12 h-12 relative z-[4]"
                  loading="eager"
                  alt=""
                  src="/courier1.svg"
                />
                <b className="relative z-[4]">Courier</b>
              </div>
            </div>
          </div>
          <div className="relative text-darkslategray-500 z-[4]">
            Your order is on the way.
          </div>
        </div>
        <div className="flex flex-col items-center justify-start gap-[9px]">
          <img
            className="w-[43.2px] h-[47.5px] relative z-[4]"
            loading="eager"
            alt=""
            src="/user.svg"
          />
          <b className="h-[21px] relative inline-block z-[4]">You</b>
        </div>
      </div>
      <div className="w-[285px] flex flex-row items-start justify-start py-0 pr-5 pl-[46px] box-border mq450:pl-5 mq450:box-border">
        <button
          className="cursor-pointer [border:none] pt-[11px] px-2.5 pb-2.5 bg-crimson-100 flex-1 rounded-81xl flex flex-row items-center justify-center whitespace-nowrap z-[4] hover:bg-tomato-100"
          onClick={onUserVectorClick}
        >
          <b
            className="relative text-xl leading-[32px] capitalize font-poppins text-snow-100 text-left cursor-pointer"
            onClick={onBackHomeTextClick}
          >
            Back Home
          </b>
        </button>
      </div>
    </footer>
  );
};

export default OrderStatusFrame;
