import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const RatingAndReview1 = () => {
  const navigate = useNavigate();

  const onMenuIconClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  return (
    <div className="w-full h-[952px] relative bg-white overflow-hidden hidden text-left text-3xs text-grey-2 font-poppins">
      <img
        className="absolute h-[2.58%] w-[2.2%] top-[51.36%] right-[44.39%] bottom-[46.06%] left-[53.41%] max-w-full overflow-hidden max-h-full hidden z-[1]"
        alt=""
        src="/group-5.svg"
      />
      <div className="absolute top-[470px] left-[29px] w-80 overflow-hidden hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border text-smi text-neutral-black font-source-sans-pro">
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="absolute top-[634px] left-[24px] w-[162px] h-[45px] hidden text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-[162px] h-[45px]" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="absolute top-[630px] left-[217px] w-[183px] h-[52px] hidden text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <div className="absolute top-[823px] left-[32px] w-[113px] h-[71px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[113px] h-[71px] object-cover"
          alt=""
          src="/pngegg-50-1@2x.png"
        />
        <div className="absolute top-[45px] left-[4px] w-[89px] h-[26px]">
          <div className="absolute top-[0px] left-[0px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
          <img
            className="absolute top-[6px] left-[2px] rounded-12xs w-[15px] h-3.5"
            alt=""
            src="/vector.svg"
          />
          <div className="absolute top-[8px] left-[15px] font-black">5.8</div>
        </div>
        <div className="absolute top-[16px] left-[calc(50%_+_106.5px)] rounded-[50%] bg-new-2 [filter:blur(14px)] w-10 h-10 hidden" />
      </div>
      <img
        className="absolute top-[819px] left-[162px] w-[115px] h-[61px] object-cover hidden"
        alt=""
        src="/pngegg-42-1@2x.png"
      />
      <div className="absolute top-[868px] left-[175px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] hidden z-[1]" />
      <img
        className="absolute top-[874px] left-[177px] rounded-12xs w-[15px] h-3.5 hidden z-[2]"
        alt=""
        src="/vector.svg"
      />
      <div className="absolute top-[876px] left-[190px] text-2xs font-black text-new-2 text-right hidden">
        5.8
      </div>
      <img
        className="absolute top-[810px] left-[296px] w-24 h-[78px] object-cover"
        loading="eager"
        alt=""
        src="/pizzapng19310-1@2x.png"
      />
      <div className="absolute top-[868px] left-[304px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] hidden z-[1]" />
      <img
        className="absolute top-[874px] left-[306px] rounded-12xs w-[15px] h-3.5 hidden z-[2]"
        alt=""
        src="/vector.svg"
      />
      <div className="absolute top-[876px] left-[319px] text-2xs font-black text-new-2 text-right hidden">
        5.0
      </div>
      <footer className="absolute top-[867px] left-[calc(50%_-_214px)] bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] w-[430px] h-[85px] z-[4]" />
      <div className="absolute top-[888px] left-[36px] w-[359px] h-[47px] hidden text-xs text-grey1">
        <div className="absolute h-[74.47%] w-full top-[25.53%] right-[0%] bottom-[0%] left-[0%] overflow-hidden">
          <div className="absolute top-[10px] left-[calc(50%_-_179.5px)] w-[358px] h-[15px]">
            <div className="absolute top-[0px] left-[0px] font-black">Menu</div>
            <div className="absolute top-[0px] left-[84px] font-black">
              Cart
            </div>
            <div className="absolute top-[0px] left-[159px] font-black text-new-2">
              Home
            </div>
            <div className="absolute top-[0px] left-[232px] font-black">
              Restaurants
            </div>
            <div className="absolute top-[0px] left-[324px] font-black">
              Profile
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] w-[355px] flex flex-row items-end justify-between py-0 pr-0.5 pl-0 box-border">
          <img
            className="h-5 w-[25px] relative cursor-pointer"
            alt=""
            src="/menu-icon.svg"
            onClick={onMenuIconClick}
          />
          <img className="h-5 w-5 relative" alt="" src="/cart-icon.svg" />
          <img
            className="h-[21px] w-[23px] relative min-h-[21px]"
            alt=""
            src="/home.svg"
          />
          <img
            className="self-stretch w-[28.7px] relative max-h-full min-h-[21px]"
            alt=""
            src="/dish.svg"
          />
          <img className="h-5 w-5 relative" alt="" src="/profile.svg" />
        </div>
      </div>
      <div className="absolute top-[718px] left-[54px] text-sm font-black text-grey hidden">
        Your Location
      </div>
      <img
        className="absolute top-[68px] left-[361px] w-[34px] h-[34px] hidden"
        alt=""
        src="/hamburger-2.svg"
      />
      <img
        className="absolute top-[68px] left-[318px] w-[34px] h-[34px] hidden"
        alt=""
        src="/arrow-2.svg"
      />
      <div className="absolute top-[717px] left-[67px] text-sm font-black hidden w-[49px] h-5">
        Pizza
      </div>
      <div className="absolute top-[711px] left-[59px] text-sm font-black hidden w-[49px] h-5">
        Pizza
      </div>
      <div className="absolute top-[541px] left-[36px] w-[359px] h-[135px] hidden z-[1]">
        <div className="absolute top-[0px] left-[0px] rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] w-[359px] h-[135px]" />
      </div>
      <div className="absolute top-[470px] left-[38px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 hidden z-[2]" />
      <div className="absolute top-[480px] left-[49px] font-black hidden">
        <span>Location: 4</span>
        <span className="text-new-2">KM</span>
      </div>
      <div className="absolute top-[480px] left-[154px] font-black hidden">
        30 mins.
      </div>
      <div className="absolute top-[480px] left-[233px] font-black hidden">
        5.5
      </div>
      <img
        className="absolute top-[482px] left-[224px] w-[7px] h-[7px] hidden z-[3]"
        alt=""
        src="/vector-3.svg"
      />
      <img
        className="absolute top-[482px] left-[142px] w-2 h-2 hidden z-[3]"
        alt=""
        src="/iconclock.svg"
      />
      <div className="absolute top-[476px] left-[306px] font-black hidden">
        <span>Location: 4</span>
        <span className="text-new-2">KM</span>
      </div>
      <div className="absolute top-[466px] left-[295px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[13px] pl-[116px] box-border gap-[25px] z-[2]">
        <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
        <div className="self-stretch flex-1 relative font-black z-[3]">
          30 mins.
        </div>
        <div className="self-stretch w-[26px] flex flex-row items-start justify-start gap-[2px]">
          <img
            className="h-[7px] w-[7px] relative z-[3]"
            loading="eager"
            alt=""
            src="/vector-3.svg"
          />
          <div className="self-stretch flex-1 relative font-black z-[3]">
            5.5
          </div>
        </div>
      </div>
      <div className="absolute top-[391px] left-[295px] rounded-t-3xs rounded-b-none w-[225px] flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
        <img
          className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="h-4 w-4 relative">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
          <img
            className="absolute top-[5px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
            loading="eager"
            alt=""
            src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
          />
        </div>
      </div>
      <img
        className="absolute top-[478px] left-[399px] w-2 h-2 hidden z-[3]"
        alt=""
        src="/iconclock.svg"
      />
      <div className="absolute top-[563px] left-[245px] text-sm font-black text-white whitespace-pre-wrap hidden">
        New Limited time
      </div>
      <div className="absolute top-[646px] left-[294px] text-mini font-black text-gold hidden">
        Order Now
      </div>
      <img
        className="absolute top-[571px] left-[200px] w-8 h-1.5 hidden"
        alt=""
        src="/slide.svg"
      />
      <div className="absolute top-[569px] left-[278px] rounded-[50%] bg-white w-1.5 h-1.5 z-[3]" />
      <div className="absolute top-[266px] left-[295px] rounded-t-3xs rounded-b-none w-[225px] flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
        <img
          className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
          alt=""
          src="/rectangle-27@2x.png"
        />
        <div className="h-4 w-4 relative">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
          <img
            className="absolute top-[4px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
            loading="eager"
            alt=""
            src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
          />
        </div>
      </div>
      <div className="absolute top-[341px] left-[295px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[23px] pl-[11px] box-border gap-[19px] z-[2]">
        <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
        <div className="self-stretch w-[74px] relative font-black inline-block z-[3]">
          <span>Location: 3</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-start justify-between">
          <div className="self-stretch w-14 flex flex-row items-start justify-start gap-[4px]">
            <img
              className="h-2 w-2 relative z-[3]"
              loading="eager"
              alt=""
              src="/group.svg"
            />
            <div className="self-stretch flex-1 relative font-black z-[3]">
              20 mins.
            </div>
          </div>
          <div className="self-stretch w-4 flex flex-row items-start justify-start gap-[2px]">
            <img
              className="h-[7px] w-[7px] relative z-[3]"
              loading="eager"
              alt=""
              src="/vector-3.svg"
            />
            <div className="self-stretch flex-1 relative font-black z-[3]">
              6
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute top-[576px] left-[15px] w-[138px] h-[100px] object-cover hidden z-[2]"
        alt=""
        src="/pngegg-19-1@2x.png"
      />
      <img
        className="absolute top-[494px] left-[-18px] w-[105px] h-[225px] object-contain z-[4]"
        alt=""
        src="/pngegg-22-2@2x.png"
      />
      <div className="absolute top-[593px] left-[149px] text-xs font-black text-white whitespace-pre-wrap text-right hidden">
        <p className="m-0">California-style pizza Sicilian pizza</p>
        <p className="m-0">{`with Burger & French fries & 1 free`}</p>
        <p className="m-0">{`  Coca cola drink. `}</p>
      </div>
      <div className="absolute top-[698px] left-[28px] text-smi font-black text-right hidden">
        Popular
      </div>
      <div className="absolute top-[700px] left-[351px] text-smi font-black text-chocolate-100 text-right hidden">
        See all
      </div>
      <div className="absolute top-[725px] left-[35px] w-[90px] h-[74px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[90px] h-[74px] object-cover"
          alt=""
          src="/pngegg-17-1@2x.png"
        />
        <div className="absolute top-[48px] left-[1px] w-[89px] h-[26px]">
          <div className="absolute top-[0px] left-[0px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
          <img
            className="absolute top-[6px] left-[0px] rounded-12xs w-[15px] h-3.5"
            alt=""
            src="/vector.svg"
          />
          <div className="absolute top-[8px] left-[13px] font-black">5.0</div>
        </div>
      </div>
      <div className="absolute top-[694px] left-[162px] w-[109px] h-[109px] hidden text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[109px] h-[109px] object-cover"
          alt=""
          src="/pngegg-4-1@2x.png"
        />
        <div className="absolute top-[79px] left-[7px] w-[89px] h-[26px]">
          <div className="absolute top-[0px] left-[0px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
          <img
            className="absolute top-[6px] left-[2px] rounded-12xs w-[15px] h-3.5"
            alt=""
            src="/vector.svg"
          />
          <div className="absolute top-[8px] left-[16px] font-black">6.5</div>
        </div>
      </div>
      <div className="absolute top-[719px] left-[300px] w-[93px] h-20 z-[2]">
        <img
          className="absolute h-[calc(100%_-_2px)] top-[0px] bottom-[2px] left-[0px] max-h-full w-20 object-cover"
          alt=""
          src="/4-1@2x.png"
        />
        <div className="absolute top-[54px] left-[4px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] z-[1]" />
        <img
          className="absolute h-[17.5%] w-[16.13%] top-[75%] right-[77.42%] bottom-[7.5%] left-[6.45%] rounded-12xs max-w-full overflow-hidden max-h-full z-[2]"
          loading="eager"
          alt=""
          src="/vector.svg"
        />
      </div>
      <main className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] w-[428px] flex flex-col items-center justify-start pt-[19px] px-[19px] pb-[399px] box-border gap-[19px] text-left text-sm text-grey font-poppins">
        <div className="w-[428px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[772px] hidden" />
        <div className="w-[366px] flex flex-row items-start justify-start pt-0 px-5 pb-[9px] box-border">
          <img
            className="h-[12.8px] flex-1 relative max-w-full overflow-hidden z-[1]"
            loading="eager"
            alt=""
            src="/icon-iphone.svg"
          />
        </div>
        <div className="self-stretch h-[74px] flex flex-col items-start justify-start py-0 px-[18px] box-border gap-[14px]">
          <div className="w-[149px] flex-1 flex flex-row items-center justify-start gap-[12px]">
            <div className="flex flex-col items-start justify-start pt-0 px-0 pb-[9px]">
              <img
                className="w-[12.1px] h-[17px] relative z-[1]"
                loading="eager"
                alt=""
                src="/group.svg"
              />
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start pt-[5px] px-0 pb-0">
              <div className="self-stretch flex-1 flex flex-row items-center justify-start py-0 px-0">
                <div className="self-stretch flex-1 relative font-black z-[1]">
                  Your Location
                </div>
                <div className="flex flex-col items-start justify-start pt-0 px-0 pb-1 ml-[-4px]">
                  <img
                    className="w-[11.4px] h-[7px] relative object-contain z-[2]"
                    loading="eager"
                    alt=""
                    src="/group@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className="h-1 w-1 relative rounded-[50%] bg-new-2 z-[1]" />
          </div>
          <div className="self-stretch flex-1 bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-start pt-2 px-[3px] pb-1 gap-[20px] z-[1]">
            <div className="h-[34px] w-[354px] relative bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] hidden" />
            <img
              className="h-[19px] w-[19px] relative mix-blend-normal z-[2]"
              loading="eager"
              alt=""
              src="/xmlid-223.svg"
            />
            <input
              className="w-[52px] [border:none] [outline:none] font-black font-poppins text-sm bg-[transparent] h-[21px] relative text-dimgray-500 text-left inline-block mix-blend-normal z-[2]"
              placeholder="Search"
              type="text"
            />
          </div>
        </div>
        <section className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 px-[17px] text-left text-sm text-grey-2 font-poppins">
          <div className="flex-1 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[8px] min-w-[148px]">
            <div className="w-[204px] flex flex-row items-start justify-start gap-[21px]">
              <div className="h-[54px] flex-1 relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
                <img
                  className="absolute top-[12.7px] left-[11.7px] w-[29.3px] h-[29.3px] z-[2]"
                  loading="eager"
                  alt=""
                  src="/group.svg"
                />
              </div>
              <div className="h-[54px] flex-1 relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
                <img
                  className="absolute top-[13px] left-[11px] w-[31px] h-[28.9px] z-[2]"
                  loading="eager"
                  alt=""
                  src="/pizza.svg"
                />
              </div>
              <div className="h-[54px] flex-1 relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
                <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
                <img
                  className="absolute top-[15px] left-[12px] w-[30px] h-[25px] z-[2]"
                  loading="eager"
                  alt=""
                  src="/vector.svg"
                />
              </div>
            </div>
            <div className="w-[206px] h-11 flex flex-col items-start justify-start gap-[3px]">
              <div className="self-stretch flex-1 flex flex-row items-start justify-start py-0 pr-0 pl-[7px]">
                <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                  <div className="self-stretch w-[55px] flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border">
                    <div className="self-stretch flex-1 relative font-black z-[1]">
                      Dishes
                    </div>
                  </div>
                  <div className="self-stretch w-[49px] relative font-black inline-block shrink-0 z-[1]">
                    Pizza
                  </div>
                  <div className="self-stretch w-[49px] relative font-black inline-block shrink-0 z-[1]">
                    Burger
                  </div>
                </div>
              </div>
              <div className="w-[92px] flex-1 relative font-black inline-block z-[1]">
                Restaurants
              </div>
            </div>
            <div className="self-stretch h-[107px] relative text-3xs">
              <div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
                  alt=""
                  src="/rectangle-22@2x.png"
                />
                <div className="h-4 w-4 relative">
                  <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
                  <img
                    className="absolute top-[4px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
                    loading="eager"
                    alt=""
                    src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
                  />
                </div>
              </div>
              <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[13px] pl-[11px] box-border gap-[20px] z-[2]">
                <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
                <div className="self-stretch flex-1 relative font-black z-[3]">
                  <span>{`Location: `}</span>
                  <span className="text-new-2">2KM</span>
                </div>
                <div className="self-stretch w-[62px] flex flex-row items-start justify-start py-0 pr-[7px] pl-0 box-border gap-[4px]">
                  <img
                    className="h-2 w-2 relative z-[3]"
                    loading="eager"
                    alt=""
                    src="/group.svg"
                  />
                  <div className="self-stretch flex-1 relative font-black z-[3]">
                    15 mins.
                  </div>
                </div>
                <div className="self-stretch w-[26px] flex flex-row items-start justify-start gap-[2px]">
                  <img
                    className="h-[7px] w-[7px] relative overflow-hidden shrink-0 z-[3]"
                    loading="eager"
                    alt=""
                    src="/326702-half-star-icon-1.svg"
                  />
                  <div className="self-stretch flex-1 relative font-black z-[3]">
                    4.5
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[75px] flex flex-col items-end justify-start py-0 pr-0 pl-[21px] box-border gap-[10px]">
            <div className="self-stretch h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
              <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
              <img
                className="absolute top-[13px] left-[16px] w-[21.8px] h-7 z-[2]"
                loading="eager"
                alt=""
                src="/drinks.svg"
              />
            </div>
            <div className="w-[49px] h-5 relative font-black inline-block shrink-0 z-[1]">
              Drinks
            </div>
          </div>
          <div className="w-[54px] flex flex-col items-end justify-start gap-[10px]">
            <div className="self-stretch h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] z-[1]">
              <div className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] hidden" />
              <img
                className="absolute top-[14px] left-[13px] w-[27.2px] h-[26px] z-[2]"
                loading="eager"
                alt=""
                src="/donut.svg"
              />
            </div>
            <div className="w-[49px] h-5 relative font-black inline-block shrink-0 z-[1]">
              Dessert
            </div>
          </div>
        </section>
      </main>
      <div className="absolute top-[395px] left-[0px] w-[428px] h-[556px] text-sm">
        <div className="absolute top-[0px] left-[0px] w-[428px] h-[556px]">
          <div className="absolute top-[0px] left-[38px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] bg-cover bg-no-repeat bg-[top] z-[1]">
            <img
              className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover hidden"
              alt=""
              src="/rectangle-25@2x.png"
            />
            <div className="absolute top-[12px] left-[196px] w-4 h-4">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
              <img
                className="absolute top-[5px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
                loading="eager"
                alt=""
                src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
              />
            </div>
          </div>
          <div className="absolute top-[47px] left-[0px] rounded-t-45xl rounded-b-none bg-white box-border w-[428px] h-[509px] z-[6] border-[1px] border-solid border-gray-1000" />
        </div>
        <div className="absolute top-[288px] left-[25px] rounded-2xl bg-white box-border w-[378px] h-36 flex flex-row items-start justify-start py-5 px-[26px] z-[7] text-darkslategray-500 border-[1px] border-solid border-crimson-600">
          <div className="h-36 w-[378px] relative rounded-2xl bg-white box-border hidden border-[1px] border-solid border-crimson-600" />
          <div className="w-36 relative inline-block z-[8]">
            Write your comment
          </div>
        </div>
        <div className="absolute top-[452px] left-[25px] rounded-2xl bg-new-2 box-border w-[378px] h-[63px] flex flex-row items-center justify-center pt-3.5 pb-[13px] pr-5 pl-[21px] z-[7] text-5xl text-snow-500 border-[1px] border-solid border-gray-1000">
          <div className="h-[63px] w-[378px] relative rounded-2xl bg-new-2 box-border hidden border-[1px] border-solid border-gray-1000" />
          <h2 className="m-0 self-stretch w-[63px] relative text-inherit font-bold font-inherit inline-block z-[8]">
            Send
          </h2>
        </div>
        <div className="absolute top-[192px] left-[70px] w-72 flex flex-row items-start justify-between z-[7]">
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-7.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-7.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-7.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-10.svg"
          />
          <img
            className="h-8 w-8 relative min-h-[32px]"
            loading="eager"
            alt=""
            src="/vector-10.svg"
          />
        </div>
        <b className="absolute top-[254px] left-[44px] z-[7]">Your Comment</b>
        <b className="absolute top-[83px] left-[43px] text-base inline-block text-center w-[342px] z-[7]">
          Kindly drop a rating for the just completed order
        </b>
        <div className="absolute top-[146px] left-[114px] whitespace-pre-wrap z-[7]">
          Ordered on Jan 19 at 13:00
        </div>
      </div>
    </div>
  );
};

export default RatingAndReview1;
