const EwalletForBillPayment = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 px-5 box-border max-w-full text-left text-base text-darkslategray-200 font-poppins">
      <div className="w-[334px] flex flex-col items-start justify-start gap-[16px] max-w-full">
        <div className="relative leading-[26px] font-semibold">
          Payment details
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[13px]">
          <div className="self-stretch rounded bg-whitesmoke-200 flex flex-row items-center justify-between pt-[7px] pb-1.5 pr-[11px] pl-3 gap-[20px]">
            <div className="w-[143px] flex flex-row items-center justify-start gap-[12px]">
              <img
                className="h-3.5 w-3.5 relative overflow-hidden shrink-0"
                alt=""
                src="/payments.svg"
              />
              <input
                className="w-[calc(100%_-_28px)] [border:none] [outline:none] font-inter text-sm bg-[transparent] h-[22px] flex-1 relative leading-[22px] text-gray-100 text-left inline-block min-w-[70px]"
                placeholder="Credit/Debit Card"
                type="text"
              />
            </div>
            <img
              className="h-3.5 w-3.5 relative overflow-hidden shrink-0"
              alt=""
              src="/chevron-right-large.svg"
            />
          </div>
          <div className="self-stretch rounded bg-whitesmoke-200 flex flex-row items-center justify-between pt-[7px] pb-1.5 pr-[11px] pl-3 gap-[20px]">
            <div className="w-[188px] flex flex-row items-center justify-start gap-[6px]">
              <input
                className="m-0 h-3.5 w-3.5 relative overflow-hidden shrink-0"
                type="checkbox"
              />
              <input
                className="w-[calc(100%_-_28px)] [border:none] [outline:none] font-inter text-sm bg-[transparent] h-[22px] flex-1 relative leading-[22px] text-gray-100 text-left inline-block min-w-[101px]"
                placeholder="- 10% for bill over N50,00"
                type="text"
              />
            </div>
            <img
              className="h-3.5 w-3.5 relative overflow-hidden shrink-0"
              alt=""
              src="/chevron-right-large.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EwalletForBillPayment;
