/**
 * 
 */
import ReviewCard from "./ReviewCard";

export default function Reviews(){
    return (
        <section className="py-2 pl-5 font-poppins">
            <div className="flex flex-row items-center pr-[65px] justify-between">
                <p className="font-extrabold">
                Reviews
                </p>
                <a href="" className="text-sm font-bold text-gray-400 ">View all</a>
            </div>
          <div className="flex flex-col items-start justify-start gap-[12px]">
          {/* md:w-full  sm:w-screen min-[767px]:text-gray-400 */}
            <div className="flex  flex-row w-screen md:w-full overflow-hidden flex-nowrap overflow-x-auto gap-[12px] text-xs text-gray-100">
            {/* <div className="self-stretch flex flex-row  mx-auto bg-white rounded-lg shadow-lg overflow-x-scroll"> */}
              <ReviewCard
                avatar1="/avatar-1@2x.png"
                jinnyOslin="John Yun"
                rating21="/rating-21.svg"
                quickDeliveryGoodDishesIL={`Quick delivery, good dishes. I love the Jollof rice & chicken. `}
              />
              <ReviewCard
                avatar1="/avatar-2@2x.png"
                jinnyOslin="Tolu Oslin"
                rating21="/rating-22.svg"
                quickDeliveryGoodDishesIL="Fresh ingredients, good taste"
              />
              <ReviewCard
                avatar1="/avatar-2@2x.png"
                jinnyOslin="Tolu Oslin"
                rating21="/rating-22.svg"
                quickDeliveryGoodDishesIL="Fresh ingredients, good taste"
              />
               <ReviewCard
                avatar1="/avatar-1@2x.png"
                jinnyOslin="John Yun"
                rating21="/rating-21.svg"
                quickDeliveryGoodDishesIL={`Quick delivery, good dishes. I love the Jollof rice & chicken. `}
              />
              {/* <Container4
                avatar1="/avatar-1@2x.png"
                jinnyOslin="John Yun"
                rating21="/rating-21.svg"
                quickDeliveryGoodDishesIL={`Quick delivery, good dishes. I love the Jollof rice & chicken. `}
              />
              <Container4
                avatar1="/avatar-2@2x.png"
                jinnyOslin="Tolu Oslin"
                rating21="/rating-22.svg"
                quickDeliveryGoodDishesIL="Fresh ingredients, good taste"
              /> */}
            </div>
          </div>
        </section>
      );
}