const AmountNFrame = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 px-[23px] text-left text-base text-gray-800 font-poppins">
      <div className="w-[292px] flex flex-col items-center justify-start gap-[7px]">
        <div className="w-[206px] flex flex-row items-start justify-start py-0 pr-[25px] pl-0 box-border">
          <div className="flex-1 flex flex-row items-center justify-start gap-[11px]">
            <div className="flex-1 flex flex-col items-start justify-start py-0 pr-1.5 pl-0">
              <img
                className="self-stretch h-[35.1px] relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
                loading="eager"
                alt=""
                src="/mask-group-1@2x.png"
              />
            </div>
            <img
              className="self-stretch w-[54px] relative max-h-full min-h-[39px] z-[1]"
              loading="eager"
              alt=""
              src="/mask-group-2.svg"
            />
            <img
              className="h-[35.8px] w-[50px] relative object-contain z-[1]"
              loading="eager"
              alt=""
              src="/mask-group-3@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <b className="h-6 relative inline-block z-[1]">Amount</b>
          <b className="relative text-black z-[1]">N25,000</b>
        </div>
      </div>
    </div>
  );
};

export default AmountNFrame;
