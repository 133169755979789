const PaymentOptions = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 px-5 box-border max-w-full text-left text-sm text-darkslategray-200 font-poppins">
      <div className="w-[335px] flex flex-col items-start justify-start gap-[13px] max-w-full">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
            <div className="flex flex-col items-start justify-start gap-[12px]">
              <div className="relative leading-[22px]">Subtotal</div>
              <div className="relative leading-[22px]">Delivery fee</div>
            </div>
            <div className="flex flex-col items-end justify-start gap-[12px] text-right">
              <div className="relative leading-[22px]">N7,950</div>
              <div className="relative leading-[22px]">N2,000</div>
            </div>
          </div>
          <img
            className="w-[327px] h-1 relative object-contain max-w-full"
            loading="eager"
            alt=""
            src="/line-12.svg"
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[9px] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
            <div className="relative leading-[22px]">VAT</div>
            <div className="relative leading-[22px] text-right">7.5%</div>
          </div>
          <img
            className="w-[327px] h-[3px] relative object-contain max-w-full"
            loading="eager"
            alt=""
            src="/line-13.svg"
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="relative leading-[22px]">Payment method</div>
          <div className="relative leading-[22px] text-right">Card</div>
        </div>
      </div>
    </section>
  );
};

export default PaymentOptions;
