import { useMemo } from "react";

const FooterFrame = ({
  joinTheHealthMealFamily,
  rectangle10,
  rectangle1,
  propGap,
  propMinHeight,
  propPadding,
  propFlexWrap,
  propWidth,
  propWidth1,
}) => {
  const footerFrameStyle = useMemo(() => {
    return {
      gap: propGap,
      minHeight: propMinHeight,
      padding: propPadding,
    };
  }, [propGap, propMinHeight, propPadding]);

  const subscribeBoxStyle = useMemo(() => {
    return {
      flexWrap: propFlexWrap,
      width: propWidth,
    };
  }, [propFlexWrap, propWidth]);

  const rectangleIconStyle = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  return (
    <div
      className="self-stretch flex flex-col items-start justify-start gap-[34px] min-h-[145px] max-w-full text-left text-lg text-style font-poppins mq450:gap-[17px]"
      style={footerFrameStyle}
    >
      <div className="self-stretch relative">{joinTheHealthMealFamily}</div>
      <div
        className="w-[338px] flex flex-row flex-wrap items-start justify-start gap-[15px] max-w-full text-sm text-gray-400 font-inter"
        style={subscribeBoxStyle}
      >
        <div className="flex-1 flex flex-row items-center justify-start pt-[17px] px-6 pb-4 box-border relative min-w-[32px]">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-19xl max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src={rectangle10}
          />
          <div className="relative z-[1]">Email</div>
        </div>
        <button className="cursor-pointer [border:none] pt-4 pb-[17px] pr-6 pl-7 bg-crimson-100 rounded-20xl flex flex-row items-center justify-center hover:bg-tomato-100">
          <img
            className="h-[50px] w-[118px] relative rounded-20xl hidden"
            alt=""
            src={rectangle1}
            style={rectangleIconStyle}
          />
          <div className="relative text-sm font-medium font-inter text-white text-left z-[1]">
            Subscribe
          </div>
        </button>
      </div>
    </div>
  );
};

export default FooterFrame;
