import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const navigate = useNavigate();

  const onEditButtonContainerClick = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onGoToPaymentButtonClick = useCallback(() => {
    navigate("/payment");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden hidden flex-row items-start justify-center pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-mini text-black font-poppins">
      <b className="w-[110px] relative hidden opacity-[0.83]">Popular Meals</b>
      <b className="w-[140px] relative hidden opacity-[0.83]">
        Previously viewed
      </b>
      <div className="h-[35px] w-[381px] relative hidden max-w-full text-base">
        <div className="absolute top-[0px] left-[0px] rounded-md bg-gainsboro-200 w-full h-full" />
        <b className="absolute top-[6px] left-[44px] opacity-[0.38]">Search</b>
        <img
          className="absolute top-[9px] left-[14px] w-[18px] h-[18px] object-cover"
          alt=""
          src="/search-149852-1@2x.png"
        />
      </div>
      <div className="h-[45px] w-[376px] relative hidden max-w-full text-base">
        <div className="absolute top-[7px] left-[135px] font-semibold">
          Hi Jane Doe,
        </div>
        <img
          className="absolute top-[0px] left-[331px] w-[45px] h-[45px] object-cover"
          alt=""
          src="/mask-group@2x.png"
        />
        <img
          className="absolute top-[8px] left-[0px] w-[30px] h-[30px] object-cover"
          alt=""
          src="/menu-2976215-1@2x.png"
        />
      </div>
      <div className="h-[243px] w-[428px] relative bg-gainsboro-400 hidden max-w-full" />
      <div className="h-[147px] w-[379px] relative hidden max-w-full text-xl">
        <div className="absolute top-[0px] left-[1px] font-extrabold opacity-[0.83]">
          Price
        </div>
        <div className="absolute top-[39px] left-[1px] font-extrabold opacity-[0.83]">
          Delivery
        </div>
        <div className="absolute top-[78px] left-[0px] font-extrabold opacity-[0.83]">
          Tax
        </div>
        <div className="absolute top-[117px] left-[0px] font-extrabold opacity-[0.83]">
          Total
        </div>
        <div className="absolute top-[5px] left-[316px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 25000
        </div>
        <div className="absolute top-[44px] left-[316px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 20000
        </div>
        <div className="absolute top-[83px] left-[315px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 15000
        </div>
        <div className="absolute top-[122px] left-[315px] font-poor-story text-crimson-100 opacity-[0.83]">
          N 60000
        </div>
      </div>
      <main className="flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-[30px] pb-3.5 pr-[23px] pl-6 box-border gap-[48px] max-w-full text-left text-xl text-white font-poppins mq750:pt-5 mq750:pb-5 mq750:box-border">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
        <section className="self-stretch flex flex-col items-center justify-start gap-[17px] min-h-[131px] text-left text-xl text-gray-700 font-poppins">
          <div className="w-[115px] h-[30px] flex flex-row items-start justify-start py-0 pr-[9px] pl-0 box-border">
            <div className="self-stretch flex-1 relative font-medium z-[1]">
              Check Out
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-[34px] relative">
            <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
              <div className="h-[30px] w-[87px] flex flex-row items-start justify-start relative">
                <div className="self-stretch flex-1 relative font-extrabold z-[1]">
                  Address
                </div>
                <div className="w-[175px] absolute my-0 mx-[!important] right-[-174px] bottom-[-82px] text-sm text-black inline-block z-[2]">
                  <p className="m-0">Jane Doe</p>
                  <p className="m-0">+234 80123456</p>
                  <p className="m-0">{`House No 1, Abuja Estate, `}</p>
                  <p className="m-0">FCT, Nigeria</p>
                </div>
              </div>
              <div
                className="w-[103px] rounded-81xl bg-crimson-100 flex flex-row items-center justify-center cursor-pointer z-[1] text-base text-white"
                onClick={onEditButtonContainerClick}
              >
                <div className="h-[22px] w-[71px] flex flex-col items-start justify-end py-0 px-5 box-border">
                  <div className="self-stretch h-6 relative lowercase inline-block">
                    edit
                  </div>
                </div>
              </div>
            </div>
            <img
              className="w-[34px] h-[34px] absolute my-0 mx-[!important] bottom-[0px] left-[43px] object-cover z-[2]"
              loading="eager"
              alt=""
              src="/fastdelivery-1@2x.png"
            />
          </div>
        </section>
        <section className="self-stretch flex flex-col items-start justify-start py-0 pr-px pl-0 box-border gap-[14px] max-w-full text-left text-mini text-gray-700 font-poppins">
          <div className="w-[126px] h-[23px] relative font-extrabold inline-block z-[1]">
            Order Summary
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[5px] max-w-full text-xl">
            <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-between pt-3 pb-[25px] pr-0 pl-px box-border gap-[20px] max-w-full z-[1] mq450:flex-wrap mq450:pr-px mq450:box-border">
              <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
              <div className="flex flex-row items-end justify-start gap-[14px]">
                <img
                  className="h-[65px] w-[65px] relative object-cover z-[1]"
                  loading="eager"
                  alt=""
                  src="/mask-group-11@2x.png"
                />
                <div className="h-[25px] w-[54px] flex flex-row items-start justify-start relative">
                  <div className="w-36 absolute my-0 mx-[!important] top-[-29px] right-[-90px] inline-block z-[1]">
                    Chicken Salad
                  </div>
                  <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                    N 5000
                  </div>
                </div>
              </div>
              <div className="h-[33px] flex flex-col items-start justify-start pt-[13px] px-0 pb-0 box-border font-poor-story">
                <div className="flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                  <div className="rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-start py-0 pr-1 pl-[5px] gap-[7px] z-[1]">
                    <div className="h-[16.9px] w-[61.3px] relative rounded-4xl bg-gainsboro-400 hidden" />
                    <div className="h-[17px] w-9 relative">
                      <img
                        className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                        loading="eager"
                        alt=""
                        src="/minussign@2x.png"
                      />
                      <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                    </div>
                    <img
                      className="h-[9.4px] w-[9.4px] relative object-cover z-[2]"
                      loading="eager"
                      alt=""
                      src="/plussign@2x.png"
                    />
                  </div>
                  <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                    1
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[5px] max-w-full">
              <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-between pt-3.5 pb-[23px] pr-0 pl-px box-border gap-[20px] max-w-full z-[1] mq450:flex-wrap mq450:pr-px mq450:box-border">
                <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
                <div className="flex flex-row items-end justify-start gap-[14px]">
                  <img
                    className="h-[65px] w-[65px] relative object-cover z-[4]"
                    loading="eager"
                    alt=""
                    src="/mask-group-21@2x.png"
                  />
                  <div className="h-[29px] w-[54px] flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start relative">
                      <div className="w-[100px] absolute my-0 mx-[!important] top-[-27px] right-[-46px] inline-block z-[1]">
                        Jollof Rice
                      </div>
                      <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                        N 5000
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[29px] flex flex-col items-start justify-start pt-[9px] px-0 pb-0 box-border font-poor-story">
                  <div className="flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                    <div className="rounded-4xl bg-gainsboro-400 flex flex-row items-center justify-start py-0 pr-1 pl-[5px] gap-[7px] z-[1]">
                      <div className="h-[16.9px] w-[61.3px] relative rounded-4xl bg-gainsboro-400 hidden" />
                      <div className="h-[17px] w-9 relative">
                        <img
                          className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                          loading="eager"
                          alt=""
                          src="/minussign@2x.png"
                        />
                        <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                      </div>
                      <img
                        className="h-[9.4px] w-[9.4px] relative object-cover z-[2]"
                        loading="eager"
                        alt=""
                        src="/plussign@2x.png"
                      />
                    </div>
                    <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                      1
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-start pt-3 pb-[25px] pr-0 pl-px box-border gap-[14px] max-w-full z-[2] mq450:flex-wrap mq450:pr-px mq450:box-border">
                <img
                  className="h-[65px] w-[65px] relative object-cover z-[1]"
                  loading="eager"
                  alt=""
                  src="/mask-group-11@2x.png"
                />
                <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
                <div className="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[13px] box-border min-w-[71px]">
                  <div className="w-[66px] h-[30px] flex flex-row items-start justify-start relative">
                    <div className="self-stretch flex-1 relative z-[1]">
                      Burger
                    </div>
                    <div className="w-[54px] absolute my-0 mx-[!important] bottom-[-22px] left-[0px] font-poor-story text-crimson-500 inline-block z-[2]">
                      N 5000
                    </div>
                  </div>
                </div>
                <div className="h-[31px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[7px] box-border min-w-[66px]">
                  <img
                    className="w-[9.4px] h-[24.6px] relative hidden z-[1]"
                    alt=""
                    src="/group-5.svg"
                  />
                </div>
                <div className="h-[33px] w-[61px] flex flex-col items-start justify-start pt-[13px] px-0 pb-0 box-border font-poor-story">
                  <div className="self-stretch flex-1 flex flex-row items-end justify-start py-0 pr-[27px] pl-0">
                    <div className="h-[17px] flex-1 relative rounded-4xl bg-gainsboro-400 z-[1]">
                      <div className="absolute top-[0.2px] left-[0px] rounded-4xl bg-gainsboro-400 w-full h-full hidden" />
                      <img
                        className="absolute top-[4px] right-[3.4px] w-[9.4px] h-[9.4px] object-cover z-[2]"
                        loading="eager"
                        alt=""
                        src="/plussign@2x.png"
                      />
                      <div className="absolute h-full top-[0px] bottom-[0px] left-[5px] w-9">
                        <img
                          className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                          loading="eager"
                          alt=""
                          src="/minussign@2x.png"
                        />
                        <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                      </div>
                    </div>
                    <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                      1
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-between pt-[18px] pb-[19px] pr-0 pl-px box-border gap-[20px] max-w-full z-[1] mq450:flex-wrap mq450:pr-px mq450:box-border">
                <div className="h-[102px] w-[380px] relative rounded-lg bg-whitesmoke-100 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] hidden max-w-full" />
                <div className="flex flex-row items-end justify-start gap-[14px]">
                  <img
                    className="h-[65px] w-[65px] relative object-cover z-[4]"
                    loading="eager"
                    alt=""
                    src="/mask-group-4@2x.png"
                  />
                  <div className="h-[33px] w-[54px] flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start relative">
                      <div className="w-[106px] absolute my-0 mx-[!important] top-[-27px] right-[-52px] inline-block z-[1]">
                        Beef Pasta
                      </div>
                      <div className="self-stretch flex-1 relative font-poor-story text-crimson-500 z-[2]">
                        N 5000
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[60px] w-[61px] flex flex-row items-end justify-start py-5 pr-[27px] pl-0 box-border font-poor-story">
                  <div className="h-[17px] flex-1 relative rounded-4xl bg-gainsboro-400 z-[1]">
                    <div className="absolute top-[0.2px] left-[0px] rounded-4xl bg-gainsboro-400 w-full h-full hidden" />
                    <img
                      className="absolute top-[4px] right-[3.4px] w-[9.4px] h-[9.4px] object-cover z-[2]"
                      loading="eager"
                      alt=""
                      src="/plussign@2x.png"
                    />
                    <div className="absolute h-full top-[0px] bottom-[0px] left-[5px] w-9">
                      <img
                        className="absolute top-[0.8px] left-[0px] w-[10.6px] h-[15.6px] object-cover z-[2]"
                        loading="eager"
                        alt=""
                        src="/minussign@2x.png"
                      />
                      <div className="absolute top-[0.2px] left-[16.9px] bg-snow-200 w-[18.8px] h-[16.9px] z-[2]" />
                    </div>
                  </div>
                  <div className="w-[5.6px] relative inline-block h-[18.8px] shrink-0 z-[3] ml-[-33px]">
                    1
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="w-[301px] flex flex-row items-start justify-start py-0 pr-3 pl-0 box-border">
          <div
            className="h-[41px] flex-1 rounded-81xl bg-crimson-100 flex flex-row items-center justify-center pt-1.5 px-[5px] pb-[5px] box-border whitespace-nowrap cursor-pointer z-[1]"
            onClick={onGoToPaymentButtonClick}
          >
            <div className="self-stretch w-[149px] relative lowercase inline-block">
              go to payment
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Checkout;
