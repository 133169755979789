import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const onYourCityTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='text']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onGroupIcon2Click = useCallback(() => {
    navigate("/home-page");
  }, [navigate]);

  const onMenuIconClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  const onRectangleClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden hidden flex-col items-center justify-start pt-0 px-0 pb-[58px] box-border gap-[11px] text-left text-2xs text-new-2 font-poppins">
      <div className="w-80 overflow-hidden hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border z-[0] text-smi text-neutral-black font-source-sans-pro">
        <div className="self-stretch h-5 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="w-[162px] h-[45px] relative hidden z-[1] text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-[162px] h-[45px]" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="w-[183px] h-[52px] relative hidden z-[2] text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <div className="w-[19px] relative font-black text-right hidden z-[3]">
        5.8
      </div>
      <main className="w-[520px] h-[803px] relative text-right text-2xs text-new-2 font-poppins">
        <div className="absolute top-[353px] left-[295px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-between pt-2.5 pb-[7px] pr-[23px] pl-[11px] box-border z-[2] text-left text-3xs text-grey-2">
          <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
          <div className="self-stretch w-[50px] relative font-black inline-block z-[3]">
            The Place
          </div>
          <div className="self-stretch w-[98px] flex flex-row items-start justify-start gap-[26px]">
            <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[4px]">
              <img
                className="h-2 w-2 relative z-[3]"
                loading="eager"
                alt=""
                src="/group.svg"
              />
              <div className="self-stretch flex-1 relative font-black z-[3]">
                20 mins.
              </div>
            </div>
            <div className="self-stretch w-4 flex flex-row items-start justify-start gap-[2px]">
              <img
                className="h-[7px] w-[7px] relative z-[3]"
                loading="eager"
                alt=""
                src="/vector-3.svg"
              />
              <div className="self-stretch flex-1 relative font-black z-[3]">
                6
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-[725px] left-[35px] w-[90px] h-[74px] flex flex-row items-end justify-center py-0 pr-0 pl-px box-border bg-[url('/public/burger@3x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
          <img
            className="h-[74px] w-[90px] relative object-cover hidden"
            alt=""
            src="/pngegg-17-1@2x.png"
          />
          <div className="h-[26px] flex-1 rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] flex flex-row items-start justify-start pt-1.5 px-0 pb-px box-border z-[1]">
            <div className="h-[26px] w-[89px] relative rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] hidden z-[1]" />
            <img
              className="h-3.5 w-[15px] relative rounded-12xs z-[2]"
              loading="eager"
              alt=""
              src="/vector.svg"
            />
            <div className="w-[19px] relative font-black inline-block z-[3] ml-[-2px]">
              5.0
            </div>
          </div>
        </div>
        <div className="absolute top-[694px] left-[162px] w-[109px] h-[109px] flex flex-row items-end justify-start py-1 pr-[13px] pl-[7px] box-border bg-[url('/public/pizza-1@3x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
          <img
            className="h-[109px] w-[109px] relative object-cover hidden"
            alt=""
            src="/pngegg-4-1@2x.png"
          />
          <div className="h-[26px] flex-1 rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] flex flex-row items-start justify-start pt-1.5 px-0.5 pb-px box-border z-[1]">
            <div className="h-[26px] w-[89px] relative rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] hidden z-[1]" />
            <img
              className="h-3.5 w-[15px] relative rounded-12xs z-[2]"
              loading="eager"
              alt=""
              src="/vector.svg"
            />
            <div className="w-[18px] relative font-black inline-block z-[3] ml-[-1px]">
              6.5
            </div>
          </div>
        </div>
        <div className="absolute top-[773px] left-[304px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px] flex flex-row items-start justify-start pt-1.5 px-0.5 pb-px box-border z-[3]">
          <div className="h-[26px] w-[89px] relative rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] hidden z-[1]" />
          <img
            className="h-3.5 w-[15px] relative rounded-12xs z-[4]"
            loading="eager"
            alt=""
            src="/vector.svg"
          />
          <div className="w-[18px] relative font-black inline-block z-[5] ml-[-1px]">
            6.0
          </div>
        </div>
        <section className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] w-[428px] flex flex-col items-center justify-start pt-[19px] pb-[52px] pr-[33px] pl-[34px] box-border gap-[123px] text-left text-xs text-grey font-poppins">
          <div className="w-[428px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[772px] hidden" />
          <div className="self-stretch flex flex-col items-center justify-start py-0 px-[3px] gap-[28px]">
            <img
              className="w-[326px] h-[12.8px] relative z-[1]"
              loading="eager"
              alt=""
              src="/icon-iphone.svg"
            />
            <div className="self-stretch flex flex-col items-start justify-start gap-[14px]">
              <div className="w-[108px] flex flex-row items-end justify-start gap-[7px]">
                <div className="flex flex-col items-start justify-start pt-0 px-0 pb-[3px]">
                  <img
                    className="w-[12.1px] h-[17px] relative z-[1]"
                    loading="eager"
                    alt=""
                    src="/group.svg"
                  />
                </div>
                <div className="h-[18px] flex-1 flex flex-col items-start justify-start py-0 pr-[5px] pl-0 box-border">
                  <div
                    className="self-stretch flex-1 relative font-black cursor-pointer z-[1]"
                    onClick={onYourCityTextClick}
                  >
                    Your City
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start pt-0 px-0 pb-1">
                  <div className="w-[18px] h-[7px] relative">
                    <div className="absolute top-[2px] left-[14px] rounded-[50%] bg-new-2 w-1 h-1 z-[1]" />
                    <img
                      className="absolute top-[0px] left-[0px] w-[11.4px] h-[7px] object-contain cursor-pointer z-[1]"
                      loading="eager"
                      alt=""
                      src="/group@2x.png"
                      onClick={onGroupIcon2Click}
                    />
                  </div>
                </div>
              </div>
              <div className="self-stretch h-[34px] rounded-sm bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-start pt-2 px-[9px] pb-1.5 box-border gap-[9px] z-[1] text-smi text-dimgray-500">
                <div className="h-[34px] w-[354px] relative rounded-sm bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] hidden" />
                <img
                  className="h-[19px] w-[19px] relative mix-blend-normal z-[2]"
                  loading="eager"
                  alt=""
                  src="/xmlid-223.svg"
                />
                <div className="self-stretch w-[90px] relative font-medium inline-block mix-blend-normal z-[2]">
                  Search meals
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] text-sm text-grey-2">
            <div className="w-[233px] flex flex-row items-start justify-start py-0 px-1 box-border">
              <div className="flex-1 flex flex-col items-start justify-start gap-[18px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[7px]">
                  <h3 className="m-0 w-[92px] h-[21px] relative text-inherit font-black font-inherit inline-block z-[1]">
                    Restaurants
                  </h3>
                  <div className="self-stretch h-[107px] relative text-3xs">
                    <div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
                      <img
                        className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
                        alt=""
                        src="/rectangle-22@2x.png"
                      />
                      <div className="h-4 w-4 relative">
                        <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
                        <img
                          className="absolute top-[4px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
                          loading="eager"
                          alt=""
                          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
                        />
                      </div>
                    </div>
                    <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[13px] pl-[11px] box-border gap-[4px] z-[2]">
                      <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
                      <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[1px]">
                        <div className="self-stretch flex-1 relative font-black z-[3]">
                          Chicken Republic
                        </div>
                        <img
                          className="h-2 w-2 relative z-[3]"
                          loading="eager"
                          alt=""
                          src="/group.svg"
                        />
                      </div>
                      <div className="self-stretch w-[66px] flex flex-col items-start justify-start py-0 pr-[23px] pl-0 box-border">
                        <div className="self-stretch flex-1 relative font-black z-[3]">
                          15 mins.
                        </div>
                      </div>
                      <div className="self-stretch w-[26px] flex flex-row items-start justify-start gap-[2px]">
                        <img
                          className="h-[7px] w-[7px] relative overflow-hidden shrink-0 z-[3]"
                          loading="eager"
                          alt=""
                          src="/326702-half-star-icon-1.svg"
                        />
                        <div
                          className="self-stretch flex-1 relative font-black z-[3]"
                          data-scroll-to="text"
                        >
                          4.5
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch h-[107px] relative text-3xs">
                  <div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
                    <img
                      className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
                      alt=""
                      src="/rectangle-25@2x.png"
                    />
                    <div className="h-4 w-4 relative">
                      <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
                      <img
                        className="absolute top-[5px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
                        loading="eager"
                        alt=""
                        src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
                      />
                    </div>
                  </div>
                  <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8 flex flex-row items-start justify-start pt-2.5 pb-[7px] pr-[13px] pl-[11px] box-border gap-[31px] z-[2]">
                    <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
                    <div className="self-stretch w-[62px] relative font-black inline-block z-[3]">
                      Mama Cass
                    </div>
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[4px]">
                      <img
                        className="h-2 w-2 relative z-[3]"
                        loading="eager"
                        alt=""
                        src="/iconclock.svg"
                      />
                      <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                        <div className="self-stretch w-[45px] relative font-black inline-block z-[3]">
                          30 mins.
                        </div>
                        <div className="self-stretch w-[26px] flex flex-row items-start justify-start gap-[2px]">
                          <img
                            className="h-[7px] w-[7px] relative z-[3]"
                            alt=""
                            src="/vector-3.svg"
                          />
                          <div className="self-stretch flex-1 relative font-black z-[3]">
                            5.5
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute top-[2679px] left-[-746px] w-0 h-0 flex flex-row items-start justify-start">
                    <img
                      className="h-[24.6px] w-[9.4px] absolute my-0 mx-[!important] z-[1]"
                      loading="eager"
                      alt=""
                      src="/group-5.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-[135px] rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] flex flex-col items-end justify-start pt-6 px-[11px] pb-[9px] box-border relative gap-[9px] z-[1] text-white">
              <div className="w-[359px] relative rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] h-[135px] hidden z-[0]" />
              <div className="w-[137px] h-[21px] flex flex-row items-center justify-center relative">
                <h3 className="m-0 self-stretch flex-1 relative text-inherit font-black font-inherit whitespace-pre-wrap z-[2]">
                  New Limited time
                </h3>
                <div className="h-1.5 w-1.5 absolute my-0 mx-[!important] top-[6px] left-[33px] rounded-[50%] bg-white z-[3]" />
              </div>
              <h3 className="my-0 mx-[!important] w-[83px] absolute right-[16px] bottom-[5px] text-mini font-black font-inherit text-gold inline-block z-[2]">
                Order Now
              </h3>
              <div className="w-[246px] flex-1 flex flex-row items-start justify-start py-0 px-[13px] box-border text-right text-xs">
                <div className="self-stretch flex-1 relative font-black whitespace-pre-wrap z-[3]">
                  <p className="m-0">California-style pizza Sicilian pizza</p>
                  <p className="m-0">{`with Burger & French fries & 1 free`}</p>
                  <p className="m-0">{`  Coca cola drink. `}</p>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-1 text-right text-smi">
              <div className="flex-1 flex flex-row items-start justify-between min-h-[21px]">
                <div className="w-[54px] relative font-black inline-block z-[1]">
                  Popular
                </div>
                <div className="w-11 relative font-black text-chocolate-100 inline-block z-[1]">
                  See all
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="w-[19px] relative font-black text-right hidden z-[5]">
        5.0
      </div>
      <div className="w-[359px] h-[47px] relative hidden z-[6] text-xs text-grey1">
        <div className="absolute h-[74.47%] w-full top-[25.53%] right-[0%] bottom-[0%] left-[0%] overflow-hidden hidden">
          <div className="absolute top-[10px] left-[calc(50%_-_179.5px)] w-[358px] h-[15px]">
            <div className="absolute top-[0px] left-[0px] font-black">
              Support
            </div>
            <div className="absolute top-[0px] left-[84px] font-black">
              Cart
            </div>
            <div className="absolute top-[0px] left-[159px] font-black text-new-2">
              Home
            </div>
            <div className="absolute top-[0px] left-[232px] font-black">
              Restaurants
            </div>
            <div className="absolute top-[0px] left-[324px] font-black">
              Profile
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] w-[355px] hidden flex-row items-end justify-between py-0 pr-0.5 pl-0 box-border">
          <img
            className="h-5 w-[25px] relative cursor-pointer"
            alt=""
            src="/menu-icon.svg"
            onClick={onMenuIconClick}
          />
          <img className="h-5 w-5 relative" alt="" src="/cart-icon.svg" />
          <img
            className="h-[21px] w-[23px] relative min-h-[21px]"
            alt=""
            src="/home.svg"
          />
          <img
            className="self-stretch w-[28.7px] relative max-h-full min-h-[21px]"
            alt=""
            src="/dish.svg"
          />
          <img className="h-5 w-5 relative" alt="" src="/profile.svg" />
        </div>
      </div>
      <img
        className="w-[34px] h-[34px] relative hidden z-[7]"
        alt=""
        src="/hamburger-2.svg"
      />
      <img
        className="w-[34px] h-[34px] relative hidden z-[8]"
        alt=""
        src="/arrow-2.svg"
      />
      <div className="w-[354px] h-[84px] relative hidden z-[9] text-xs text-grey-2">
        <div
          className="absolute top-[0px] left-[0px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px] cursor-pointer"
          onClick={onRectangleClick}
        />
        <div className="absolute top-[0px] left-[76px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px]" />
        <div className="absolute top-[0px] left-[150px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px]" />
        <div className="absolute top-[0px] left-[225px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px]" />
        <div className="absolute top-[0px] left-[300px] rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] w-[54px] h-[54px]" />
        <div className="absolute top-[64px] left-[5px] font-black inline-block w-[49px] h-5">
          Dishes
        </div>
        <div className="absolute top-[64px] left-[83px] font-black inline-block w-[49px] h-5">
          Pizza
        </div>
        <div className="absolute top-[64px] left-[155px] font-black inline-block w-[49px] h-5">
          Burger
        </div>
        <div className="absolute top-[64px] left-[230px] font-black inline-block w-[49px] h-5">
          Drinks
        </div>
        <div className="absolute top-[64px] left-[305px] font-black">
          Dessert
        </div>
        <img
          className="absolute h-[34.88%] w-[8.28%] top-[15.12%] right-[88.42%] bottom-[50%] left-[3.31%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt=""
          src="/group.svg"
          onClick={onGroupIconClick}
        />
        <img
          className="absolute h-[34.4%] w-[8.76%] top-[15.48%] right-[66.67%] bottom-[50.12%] left-[24.58%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/pizza.svg"
        />
        <img
          className="absolute h-[29.76%] w-[8.47%] top-[17.86%] right-[45.76%] bottom-[52.38%] left-[45.76%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <img
          className="absolute h-[33.33%] w-[6.16%] top-[15.48%] right-[25.76%] bottom-[51.19%] left-[68.08%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/drinks.svg"
        />
        <img
          className="absolute h-[30.95%] w-[7.68%] top-[16.67%] right-[3.9%] bottom-[52.38%] left-[88.42%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/donut.svg"
        />
      </div>
      <div className="w-[225px] my-0 mx-[!important] absolute top-[403px] right-[-92px] rounded-t-3xs rounded-b-none flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
        <img
          className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="h-4 w-4 relative">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
          <img
            className="absolute top-[5px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
            loading="eager"
            alt=""
            src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
          />
        </div>
      </div>
      <div className="w-[225px] h-8 my-0 mx-[!important] absolute right-[-92px] bottom-[442px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] flex flex-row items-start justify-between pt-2.5 pb-[7px] pr-[13px] pl-[11px] box-border z-[2] text-3xs text-grey-2">
        <div className="h-8 w-[225px] relative bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] hidden" />
        <div className="self-stretch w-[21px] relative font-black inline-block z-[3]">
          KFC
        </div>
        <div className="self-stretch w-[108px] flex flex-row items-start justify-start gap-[25px]">
          <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[4px]">
            <img
              className="h-2 w-2 relative z-[3]"
              loading="eager"
              alt=""
              src="/iconclock.svg"
            />
            <div className="self-stretch flex-1 relative font-black z-[3]">
              30 mins.
            </div>
          </div>
          <div className="self-stretch w-[26px] flex flex-row items-start justify-start gap-[2px]">
            <img
              className="h-[7px] w-[7px] relative z-[3]"
              loading="eager"
              alt=""
              src="/vector-3.svg"
            />
            <div className="self-stretch flex-1 relative font-black z-[3]">
              5.5
            </div>
          </div>
        </div>
      </div>
      <img
        className="w-8 h-1.5 relative hidden z-[12]"
        alt=""
        src="/slide.svg"
      />
      <div className="w-[225px] my-0 mx-[!important] absolute top-[278px] right-[-92px] rounded-t-3xs rounded-b-none flex flex-row items-start justify-end pt-3 px-[13px] pb-12 box-border bg-cover bg-no-repeat bg-[top] z-[1]">
        <img
          className="h-[76px] w-[225px] relative rounded-t-3xs rounded-b-none object-cover hidden"
          alt=""
          src="/rectangle-27@2x.png"
        />
        <div className="h-4 w-4 relative">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-white w-4 h-4 z-[2]" />
          <img
            className="absolute top-[4px] left-[4px] w-2 h-2 overflow-hidden z-[3]"
            loading="eager"
            alt=""
            src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
          />
        </div>
      </div>
      <img
        className="w-[138px] relative h-[100px] object-cover hidden z-[14]"
        alt=""
        src="/pngegg-19-1@2x.png"
      />
      <img
        className="w-[105px] relative h-[225px] object-contain hidden z-[15]"
        alt=""
        src="/pngegg-22-2@2x.png"
      />
      <img
        className="w-20 h-[78px] absolute my-0 mx-[!important] right-[48px] bottom-[155px] object-cover z-[2]"
        alt=""
        src="/4-1@2x.png"
      />
      <div className="w-[404px] flex flex-row items-start justify-start py-0 pr-6 pl-5 box-border text-right">
        <div className="flex-1 flex flex-row items-end justify-start gap-[18px]">
          <div className="flex-1 flex flex-row items-end justify-start py-0 pr-5 pl-1 box-border bg-[url('/public/pasta-2@3x.png')] bg-cover bg-no-repeat bg-[top] min-w-[73px] min-h-[71px]">
            <img
              className="h-[71px] w-[113px] relative object-cover hidden"
              alt=""
              src="/pngegg-50-1@2x.png"
            />
            <div className="h-[26px] flex-1 rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] flex flex-row items-start justify-start pt-1.5 px-0.5 pb-px box-border z-[1]">
              <div className="h-[26px] w-[89px] relative rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] hidden z-[1]" />
              <img
                className="h-3.5 w-[15px] relative rounded-12xs z-[2]"
                loading="eager"
                alt=""
                src="/vector.svg"
              />
              <div className="w-[19px] relative font-black inline-block z-[3] ml-[-2px]">
                5.8
              </div>
            </div>
            <div className="h-10 w-10 relative rounded-[50%] bg-new-2 [filter:blur(14px)] hidden" />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-1.5 box-border min-w-[75px] min-h-[75px]">
            <div className="self-stretch h-[61px] relative">
              <div className="absolute top-[0px] left-[0px] flex flex-row items-start justify-start">
                <img
                  className="h-[61px] w-[115px] relative object-cover"
                  loading="eager"
                  alt=""
                  src="/pngegg-42-1@2x.png"
                />
                <div className="h-[26px] w-[89px] absolute my-0 mx-[!important] bottom-[-10px] left-[calc(50%_-_45.5px)] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] z-[1]" />
              </div>
              <img
                className="absolute top-[55px] left-[15px] rounded-12xs w-[15px] h-3.5 hidden z-[2]"
                alt=""
                src="/vector.svg"
              />
            </div>
          </div>
          <div className="w-24 flex flex-col items-start justify-start pt-0 px-0 pb-1.5 box-border">
            <div className="self-stretch h-[78px] relative">
              <div className="absolute top-[0px] left-[0px] flex flex-row items-start justify-start">
                <img
                  className="h-[78px] w-24 relative object-cover"
                  loading="eager"
                  alt=""
                  src="/pizzapng19310-1@2x.png"
                />
                <div className="h-[26px] w-[89px] absolute my-0 mx-[!important] right-[-1px] bottom-[-2px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] z-[1]" />
              </div>
              <img
                className="absolute top-[64px] left-[10px] rounded-12xs w-[15px] h-3.5 hidden z-[2]"
                alt=""
                src="/vector.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <img
        className="w-[153px] h-[153px] absolute my-0 mx-[!important] bottom-[269px] left-[-44px] object-cover z-[2]"
        loading="eager"
        alt=""
        src="/mask-group@2x.png"
      />
      <footer className="w-[430px] absolute my-0 mx-[!important] right-[-6px] bottom-[-5px] bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] h-[85px] z-[4]" />
    </div>
  );
};

export default HomePage;
