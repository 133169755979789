import RestaurantDetailsComp from "../components/RestaurantDetailsComp";
import MealRecommendation from "../components/MealRecommendation";
import MenuRecommendation from "../components/MenuRecommendation";
import Reviews from "../components/Reviews";
import MealCombo from "../components/MealCombo";
import RestaurantNav from "../components/nav/RestaurantNav";

const RestaurantDetails = () => {
  return (
    <main className="flex justify-center">
      <div className="lg:w-[800px] md:w-[600px] flex flex-col bg-snow-400 shadow-[0px_3px_6px_rgba(18,_15,_40,_0.12)]">
        <RestaurantDetailsComp />
        <MealRecommendation />
        <MenuRecommendation />
        <Reviews />
        <MealCombo />
        <RestaurantNav />
      </div>
    </main>
  );
};

export default RestaurantDetails;
