import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const SocialFeed = () => {
  const navigate = useNavigate();

  const onHomeButtonsFrameClick = useCallback(() => {
    navigate("/home-page");
  }, [navigate]);

  return (
    <footer className="w-auto [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] overflow-hidden flex flex-col items-center justify-start pt-0 pb-[219px] pr-16 pl-[63px] box-border gap-[285px]">
      <div className="relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[952px] hidden" />
      <img
        className="w-[148px] h-[111px] relative object-cover z-[1]"
        alt=""
        src="/chopinns-v3-3-2@2x.png"
      />
      <section className="self-stretch flex flex-col items-center justify-start gap-[140px] text-center text-45xl text-crimson-100 font-poppins">
        <b className="h-40 relative leading-[80px] capitalize flex items-center z-[1]">
          <span>
            <p className="m-0">
              <span className="text-77xl">C</span>
              <span>{`oming `}</span>
            </p>
            <p className="m-0">
              <span className="text-77xl">S</span>
              <span>oon</span>
            </p>
          </span>
        </b>
        <div
          className="rounded-81xl box-border flex flex-row items-center justify-center pt-1 px-[3px] pb-[3px] cursor-pointer z-[1] text-left text-l border-[2px] border-solid border-crimson-100"
          onClick={onHomeButtonsFrameClick}
        >
          <div className="capitalize p-3 font-body font-semibold">
            Back Home
          </div>
        </div>
      </section>
    </footer>
  );
};

export default SocialFeed;
