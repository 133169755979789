import MainFrame from "../components/MainFrame";
import Container10 from "../components/Container10";
import Container9 from "../components/Container9";
import Container8 from "../components/Container8";

const Search1 = () => {
  return (
    <div className="w-full relative bg-snow-400 shadow-[0px_3px_6px_rgba(18,_15,_40,_0.12)] overflow-hidden flex flex-col items-start justify-start gap-[28px] tracking-[normal] text-left text-sm text-darkslategray-200 font-poppins">
      <MainFrame />
      <div className="flex flex-row items-start justify-start py-0 px-5 shrink-0">
        <div className="relative leading-[22px]">
          359 results for "Jollof Rice"
        </div>
      </div>
      <section className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-full shrink-0">
        <Container10 />
        <div className="w-[375px] h-0 flex flex-row items-start justify-start py-0 px-5 box-border max-w-full">
          <img
            className="flex-1 relative max-w-full overflow-clip max-h-[unset]"
            loading="eager"
            alt=""
            src="/line-7.svg"
          />
        </div>
        <Container9 />
      </section>
      <footer className="self-stretch h-20 relative bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_2px_5px_rgba(23,_26,_31,_0.13)] hidden text-left text-3xs text-dimgray-100 font-poppins">
        <img
          className="absolute top-[18px] left-[43.5px] w-7 h-7 overflow-hidden"
          alt=""
          src="/home.svg"
        />
        <b className="absolute top-[46px] left-[43px] leading-[16px] text-chocolate-200">
          Home
        </b>
        <div className="absolute top-[16px] left-[97px] bg-gray-1300 w-[75px] h-12 overflow-hidden">
          <b className="absolute top-[calc(50%_+_6px)] left-[calc(50%_-_22px)] leading-[16px]">
            Restaurant
          </b>
          <img
            className="absolute top-[2px] left-[30px] w-7 h-7"
            alt=""
            src="/group-8515.svg"
          />
          <div className="absolute top-[-2px] left-[42px] rounded-lg bg-crimson-200 box-border w-7 h-[18px] text-4xs text-white font-inter border-[2px] border-solid border-white">
            <div className="absolute top-[calc(50%_-_4.5px)] left-[calc(50%_-_9px)] leading-[9px]">
              99+
            </div>
          </div>
        </div>
        <b className="absolute top-[46px] left-[195.5px] leading-[16px]">
          Menu
        </b>
        <img
          className="absolute top-[20px] left-[197px] w-[25px] h-5"
          alt=""
          src="/menu-icon.svg"
        />
        <b className="absolute top-[46px] left-[271px] leading-[16px]">Feed</b>
        <img
          className="absolute top-[18px] left-[270px] w-7 h-7"
          alt=""
          src="/group-8516.svg"
        />
        <img
          className="absolute top-[18px] left-[345.5px] w-7 h-7 overflow-hidden"
          alt=""
          src="/account-circle.svg"
        />
        <b className="absolute top-[46px] left-[344.5px] leading-[16px]">
          Profile
        </b>
      </footer>
      <Container8 />
    </div>
  );
};

export default Search1;
