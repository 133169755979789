const AlsoOrderedContainer = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-5 box-border max-w-full text-left text-base text-darkslategray-200 font-poppins">
      <div className="w-[554px] flex flex-col items-start justify-start gap-[16px] max-w-[138%] shrink-0">
        <div className="w-[124px] relative leading-[26px] font-semibold inline-block pr-5">
          Also ordered
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[16px] text-sm mq450:flex-wrap">
          <div className="flex-[0.8537] rounded bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] flex flex-row items-start justify-start py-3 pr-12 pl-3 box-border gap-[16px] min-w-[175px] mq450:flex-1 mq450:pr-5 mq450:box-border">
            <img
              className="h-[60px] w-[60px] relative rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs object-cover min-h-[60px]"
              alt=""
              src="/image-61@2x.png"
            />
            <div className="flex-1 flex flex-col items-start justify-start gap-[16px]">
              <div className="relative leading-[22px]">Sauté Chicken Rice</div>
              <input
                className="w-[49px] [border:none] [outline:none] font-inter text-sm bg-[transparent] h-[22px] relative leading-[22px] text-gray-100 text-left inline-block"
                placeholder="N2,000"
                type="text"
              />
            </div>
          </div>
          <div className="flex-1 rounded bg-white shadow-[0px_0px_2px_rgba(23,_26,_31,_0.16),_0px_0px_1px_rgba(23,_26,_31,_0.11)] flex flex-row items-start justify-start p-3 box-border gap-[16px] min-w-[175px] mq450:flex-1">
            <img
              className="h-[60px] w-[60px] relative rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs object-cover min-h-[60px]"
              loading="eager"
              alt=""
              src="/image-63@2x.png"
            />
            <div className="w-[54px] flex flex-col items-start justify-start gap-[16px]">
              <div className="relative leading-[22px]">Sagethi</div>
              <div className="self-stretch relative leading-[22px] font-inter text-gray-100 pr-[7px]">
                N1,500
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AlsoOrderedContainer;
