const FrameComponent8 = () => {
  return (
    <section className="w-[1446px] h-[1032px] absolute my-0 mx-[!important] right-[-820px] bottom-[839px]">
      <img
        className="absolute top-[392px] left-[474px] w-[157px] h-[213.5px] z-[1]"
        loading="eager"
        alt=""
        src="/vector-101.svg"
      />
      <div className="absolute top-[119px] left-[0px] w-[575px] h-[575px]">
        <div className="absolute top-[569.58px] left-[575.7px] rounded-[50%] box-border w-full h-full [transform:_rotate(179.47deg)] [transform-origin:0_0] z-[3] border-[23px] border-solid border-crimson-300" />
        <img
          className="absolute top-[33px] left-[52px] rounded-[50%] w-[476px] h-[476px] object-contain z-[4]"
          alt=""
          src="/unsplashjwu9fplw7fi@2x.png"
        />
      </div>
      <img
        className="absolute top-[0px] left-[42px] w-[476px] h-[643.9px] object-contain z-[5]"
        alt=""
        src="/group-8463@2x.png"
      />
      <img
        className="absolute top-[0.2px] left-[370px] w-[334.5px] h-[332.5px] object-contain z-[6]"
        loading="eager"
        alt=""
        src="/5a3ac62578a9f8@2x.png"
      />
      <div className="absolute top-[374px] left-[251px] w-[1195px] h-[658px]">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-contain mix-blend-multiply"
          alt=""
          src="/group-8441@2x.png"
        />
        <img
          className="absolute top-[171px] left-[33px] w-[271px] h-[179px] object-contain z-[6]"
          alt=""
          src="/seekpng@2x.png"
        />
      </div>
    </section>
  );
};

export default FrameComponent8;
