const Screen1 = () => {
  return (
    <div className="w-full relative rounded-14xl bg-white overflow-hidden hidden flex-col items-start justify-start pt-[19px] px-0 pb-0 box-border gap-[201px] tracking-[normal] text-left text-xs text-grey1 font-poppins mq450:gap-[50px] mq825:gap-[100px]">
      <div className="w-[359px] h-[35px] relative overflow-hidden shrink-0 hidden max-w-full">
        <div className="absolute top-[0px] left-[-26px] w-[42px] h-[35px]" />
        <div className="absolute top-[10px] left-[0px] font-black">Menu</div>
        <div className="absolute top-[10px] left-[79px] font-black">Cart</div>
        <div className="absolute top-[10px] left-[calc(50%_-_18.5px)] font-black">
          Home
        </div>
        <div className="absolute top-[10px] left-[224px] font-black">
          Restaurants
        </div>
        <div className="absolute top-[10px] left-[325px] font-black text-new-2">
          Profile
        </div>
      </div>
      <div className="w-[143px] h-1.5 relative hidden">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-21xl bg-gainsboro-500" />
      </div>
      <section className="w-[821px] flex flex-row items-start justify-end py-0 px-0 box-border max-w-[191%] shrink-0 text-left text-[22px] text-grey-2 font-poppins">
        <div className="flex-1 flex flex-col items-start justify-start gap-[20px] max-w-full">
          <div className="w-[405px] h-[60px] flex flex-row items-start justify-start py-0 px-[26px] box-border max-w-full">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[14px] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[27px] box-border max-w-full">
                <img
                  className="h-[12.8px] flex-1 relative max-w-full overflow-hidden"
                  loading="eager"
                  alt=""
                  src="/icon-iphone.svg"
                />
              </div>
              <div className="w-[300px] flex-1 flex flex-row items-start justify-between gap-[20px]">
                <div className="flex flex-col items-start justify-start pt-[3px] px-0 pb-0">
                  <img
                    className="w-6 h-6 relative overflow-hidden shrink-0"
                    loading="eager"
                    alt=""
                    src="/3994376-arrow-back-left-navigation-previous-icon-1-1.svg"
                  />
                </div>
                <div className="self-stretch w-[202px] relative font-black inline-block">
                  How can we help?
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-end justify-end pt-3.5 px-[51px] pb-0 box-border [row-gap:20px] max-w-full text-xl text-grey mq450:flex-wrap mq825:pl-[25px] mq825:pr-[25px] mq825:box-border">
            <div className="flex-1 flex flex-row items-start justify-start relative min-w-[279px] max-w-full">
              <img
                className="h-1.5 w-1.5 absolute my-0 mx-[!important] top-[-117px] left-[-1px] z-[1]"
                loading="eager"
                alt=""
                src="/vector1.svg"
              />
              <div className="flex-1 bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] flex flex-row items-end justify-between pt-2.5 pb-1.5 pr-[30px] pl-9 box-border gap-[20px] max-w-full">
                <div className="h-12 w-[430px] relative bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] hidden max-w-full" />
                <img
                  className="h-8 w-8 relative z-[2]"
                  loading="eager"
                  alt=""
                  src="/vector-12.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 object-contain z-[3]"
                  loading="eager"
                  alt=""
                  src="/3994376-arrow-back-left-navigation-previous-icon-1-8.svg"
                />
              </div>
            </div>
            <div className="h-[534px] w-[290px] flex flex-row items-start justify-start relative min-w-[290px] mq450:flex-1">
              <div className="self-stretch flex-1 relative font-black z-[2]">
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  I can’t place my order
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  &nbsp;
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  My orders
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  &nbsp;
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  Payment information
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  &nbsp;
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  My support requests
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  &nbsp;
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  Pro Subcriptions
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  &nbsp;
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  Rewards
                </p>
                <p className="[margin-block-start:0] [margin-block-end:12px]">
                  &nbsp;
                </p>
                <p className="m-0">Cancellation policy</p>
              </div>
              <div className="w-[430px] my-0 mx-[!important] absolute top-[-14px] left-[-89px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] flex flex-row items-end justify-between pt-[7px] pb-[11px] pr-[30px] pl-[37px] box-border gap-[20px]">
                <div className="h-12 w-[430px] relative bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] hidden max-w-full" />
                <img
                  className="h-[30.4px] w-[29px] relative z-[1]"
                  loading="eager"
                  alt=""
                  src="/order-icon.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 object-contain z-[1]"
                  loading="eager"
                  alt=""
                  src="/3994376-arrow-back-left-navigation-previous-icon-1-8.svg"
                />
              </div>
              <div className="w-[430px] my-0 mx-[!important] absolute top-[132px] left-[-89px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] flex flex-row items-end justify-between pt-3 pb-[9px] pr-[29px] pl-[37px] box-border gap-[20px]">
                <div className="h-12 w-[430px] relative bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] hidden max-w-full" />
                <img
                  className="h-[27.1px] w-[38px] relative z-[1]"
                  loading="eager"
                  alt=""
                  src="/card.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 object-contain z-[1]"
                  loading="eager"
                  alt=""
                  src="/3994376-arrow-back-left-navigation-previous-icon-1-8.svg"
                />
              </div>
              <div className="w-[430px] my-0 mx-[!important] absolute bottom-[59px] left-[-90px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] flex flex-row items-end justify-between pt-2.5 pb-2 pr-[29px] pl-[38px] box-border gap-[20px]">
                <div className="h-12 w-[430px] relative bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] hidden max-w-full" />
                <img
                  className="h-[30px] w-[30px] relative z-[1]"
                  loading="eager"
                  alt=""
                  src="/group1.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 object-contain z-[3]"
                  loading="eager"
                  alt=""
                  src="/3994376-arrow-back-left-navigation-previous-icon-1-8.svg"
                />
              </div>
              <div className="h-12 w-[430px] absolute my-0 mx-[!important] bottom-[208px] left-[-90px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)]">
                <div className="absolute top-[0px] left-[0px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] w-full h-full hidden" />
                <div className="absolute top-[20px] left-[37px] bg-grey w-[39px] h-6 z-[1]" />
              </div>
              <div className="w-[430px] my-0 mx-[!important] absolute top-[205px] left-[-90px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] flex flex-row items-end justify-between pt-[15px] pb-2 pr-[29px] pl-[37px] box-border gap-[20px]">
                <div className="h-12 w-[430px] relative bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] hidden max-w-full" />
                <img
                  className="self-stretch w-[31px] relative max-h-full min-h-[25px] z-[1]"
                  loading="eager"
                  alt=""
                  src="/vector-21.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 object-contain z-[1]"
                  loading="eager"
                  alt=""
                  src="/3994376-arrow-back-left-navigation-previous-icon-1-8.svg"
                />
              </div>
              <div className="w-[430px] my-0 mx-[!important] absolute top-[59px] left-[-481px] bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] flex flex-row items-end justify-between pt-[13px] pb-2.5 pr-[29px] pl-[38px] box-border gap-[20px]">
                <div className="h-12 w-[430px] relative bg-white shadow-[0px_5px_0px_-3px_rgba(0,_0,_0,_0.09)] hidden max-w-full" />
                <img
                  className="self-stretch w-[34.1px] relative max-h-full min-h-[25px] z-[1]"
                  loading="eager"
                  alt=""
                  src="/dish.svg"
                />
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 object-contain z-[1]"
                  loading="eager"
                  alt=""
                  src="/3994376-arrow-back-left-navigation-previous-icon-1-8.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="w-[360px] h-px relative box-border hidden opacity-[0.5] max-w-full border-t-[1px] border-solid border-grey-2" />
      <section className="self-stretch h-[85px] relative">
        <img
          className="absolute top-[21px] left-[201px] w-6 h-[22px] hidden z-[2]"
          alt=""
          src="/home-icon.svg"
        />
        <img
          className="absolute top-[27px] left-[36px] w-5 h-4 hidden z-[2]"
          alt=""
          src="/menu-icon.svg"
        />
        <img
          className="absolute top-[23px] left-[117px] w-5 h-5 hidden z-[2]"
          alt=""
          src="/cart-icon.svg"
        />
        <img
          className="absolute top-[23px] left-[278px] w-[29px] h-[20.7px] hidden z-[2]"
          alt=""
          src="/restaurants-icon.svg"
        />
        <img
          className="absolute top-[22px] left-[369px] w-[18px] h-[21px] hidden z-[2]"
          alt=""
          src="/profile-icon.svg"
        />
        <div className="absolute top-[0px] left-[0px] rounded-t-none rounded-b-14xl bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] w-full h-full z-[3]" />
      </section>
    </div>
  );
};

export default Screen1;
