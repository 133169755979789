import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OrderStatusFrame from "../components/OrderStatusFrame";

const OrderTracking = () => {
  const navigate = useNavigate();

  const onMenuIconClick = useCallback(() => {
    navigate("/menu");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-sm text-grey-2 font-poppins">
      <div className="w-80 overflow-hidden hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border z-[0] text-smi text-neutral-black font-source-sans-pro">
        <div className="self-stretch h-5 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="w-[162px] h-[45px] relative hidden z-[1] text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="w-[183px] h-[52px] relative hidden z-[2] text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div>
      <div className="w-[113px] h-[71px] relative hidden z-[3] text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
          alt=""
          src="/pngegg-50-1@2x.png"
        />
        <div className="absolute top-[45px] left-[4px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[19.72%] w-[13.27%] top-[71.83%] right-[81.42%] bottom-[8.45%] left-[5.31%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[53px] left-[19px] font-black">5.8</div>
        <div className="absolute top-[16px] left-[calc(50%_+_106.5px)] rounded-[50%] bg-new-2 [filter:blur(14px)] w-10 h-10 hidden" />
      </div>
      <div className="w-[115px] h-[75px] relative hidden z-[4] text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-[115px] h-[61px] object-cover"
          alt=""
          src="/pngegg-42-1@2x.png"
        />
        <div className="absolute top-[49px] left-[13px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[18.67%] w-[13.04%] top-[73.33%] right-[73.91%] bottom-[8%] left-[13.04%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[57px] left-[28px] font-black">5.8</div>
      </div>
      <div className="w-[97px] h-[84px] relative hidden z-[5] text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-24 h-[78px] object-cover"
          alt=""
          src="/pizzapng19310-1@2x.png"
        />
        <div className="absolute top-[58px] left-[8px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[16.67%] w-[15.46%] top-[76.19%] right-[74.23%] bottom-[7.14%] left-[10.31%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[66px] left-[23px] font-black">5.0</div>
      </div>
      <div className="self-stretch relative bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25),_0px_-11px_15px_-10px_rgba(32,_16,_40,_0.25)] h-[85px] hidden z-[6]" />
      <div className="w-[359px] h-[47px] relative hidden max-w-full z-[7] text-xs text-grey1">
        <div className="absolute h-[74.47%] w-full top-[25.53%] right-[0%] bottom-[0%] left-[0%] overflow-hidden">
          <div className="absolute top-[10px] left-[calc(50%_-_179.5px)] w-full h-[15px]">
            <div className="absolute top-[0px] left-[0px] font-black">Menu</div>
            <div className="absolute top-[0px] left-[84px] font-black text-new-2">
              Cart
            </div>
            <div className="absolute top-[0px] left-[159px] font-black">
              Home
            </div>
            <div className="absolute top-[0px] left-[232px] font-black">
              Restaurants
            </div>
            <div className="absolute top-[0px] left-[324px] font-black">
              Profile
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] w-[355px] flex flex-row items-end justify-between py-0 pr-0.5 pl-0 box-border gap-[20px]">
          <img
            className="h-5 w-[25px] relative cursor-pointer"
            alt=""
            src="/menu-icon.svg"
            onClick={onMenuIconClick}
          />
          <img className="h-5 w-5 relative" alt="" src="/cart-icon.svg" />
          <img
            className="h-[21px] w-[23px] relative min-h-[21px]"
            alt=""
            src="/home.svg"
          />
          <img
            className="self-stretch w-[28.7px] relative max-h-full min-h-[21px]"
            alt=""
            src="/dish.svg"
          />
          <img className="h-5 w-5 relative" alt="" src="/profile.svg" />
        </div>
      </div>
      <img
        className="w-[12.1px] h-[17px] relative hidden z-[8]"
        alt=""
        src="/group.svg"
      />
      <div className="w-[102px] relative font-black text-grey hidden z-[9]">
        Your Location
      </div>
      <div className="w-1 h-1 relative rounded-[50%] bg-new-2 hidden z-[10]" />
      <img
        className="w-[34px] h-[34px] relative hidden z-[11]"
        alt=""
        src="/hamburger-2.svg"
      />
      <img
        className="w-[34px] h-[34px] relative hidden z-[12]"
        alt=""
        src="/arrow-2.svg"
      />
      <img
        className="w-[11.4px] h-[7px] relative object-contain hidden z-[13]"
        alt=""
        src="/group@2x.png"
      />
      <div className="w-[54px] h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden z-[14]" />
      <div className="w-[54px] h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden z-[15]" />
      <div className="w-[54px] h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden z-[16]" />
      <div className="w-[54px] h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden z-[17]" />
      <div className="w-[54px] h-[54px] relative rounded-8xs bg-white shadow-[0px_5px_6px_rgba(0,_0,_0,_0.16)] hidden z-[18]" />
      <div className="w-[49px] relative font-black hidden h-5 shrink-0 z-[19]">
        Dishes
      </div>
      <div className="w-[49px] relative font-black hidden h-5 shrink-0 z-[20]">
        Pizza
      </div>
      <div className="w-[49px] relative font-black hidden h-5 shrink-0 z-[21]">
        Burger
      </div>
      <div className="w-[49px] relative font-black hidden h-5 shrink-0 z-[22]">
        Drinks
      </div>
      <div className="w-[49px] relative font-black hidden h-5 shrink-0 z-[23]">
        Dessert
      </div>
      <img
        className="w-[29.3px] h-[29.3px] relative hidden z-[24]"
        alt=""
        src="/group.svg"
      />
      <img
        className="w-[31px] h-[28.9px] relative hidden z-[25]"
        alt=""
        src="/pizza.svg"
      />
      <img
        className="w-[30px] h-[25px] relative hidden z-[26]"
        alt=""
        src="/vector.svg"
      />
      <img
        className="w-[21.8px] h-7 relative hidden z-[27]"
        alt=""
        src="/drinks.svg"
      />
      <img
        className="w-[27.2px] h-[26px] relative hidden z-[28]"
        alt=""
        src="/donut.svg"
      />
      <div className="w-[92px] relative font-black hidden z-[29]">
        Restaurants
      </div>
      <div className="w-[359px] h-[135px] relative hidden max-w-full z-[30]">
        <div className="absolute top-[0px] left-[0px] rounded-mid [background:linear-gradient(94.53deg,_#fb6236,_rgba(255,_153,_0,_0.88))] w-full h-full" />
      </div>
      <div className="w-[225px] h-[107px] relative hidden z-[31] text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>Location: 4</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          30 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">5.5</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute h-[6.54%] w-[3.11%] top-[81.31%] right-[14.22%] bottom-[12.15%] left-[82.67%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector-3.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/iconclock.svg"
        />
        <img
          className="absolute top-[17px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
      </div>
      <div className="w-[225px] h-[107px] relative hidden z-[32] text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-25@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>Location: 4</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          30 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">5.5</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute h-[6.54%] w-[3.11%] top-[81.31%] right-[14.22%] bottom-[12.15%] left-[82.67%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector-3.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/iconclock.svg"
        />
        <img
          className="absolute top-[17px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
      </div>
      <div className="w-[225px] h-[107px] relative hidden z-[33] text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-22@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>{`Location: `}</span>
          <span className="text-new-2">2KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          15 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">4.5</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute top-[16px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
        <img
          className="absolute top-[87px] left-[186px] w-[7px] h-[7px] overflow-hidden"
          alt=""
          src="/326702-half-star-icon-1.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/group.svg"
        />
      </div>
      <div className="w-[137px] relative font-black text-white whitespace-pre-wrap hidden z-[34]">
        New Limited time
      </div>
      <div className="w-[83px] relative text-mini font-black text-gold hidden z-[35]">
        Order Now
      </div>
      <img
        className="w-8 h-1.5 relative hidden z-[36]"
        alt=""
        src="/slide.svg"
      />
      <div className="w-1.5 h-1.5 relative rounded-[50%] bg-white hidden z-[37]" />
      <div className="w-[225px] h-[107px] relative hidden z-[38] text-3xs">
        <img
          className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none w-[225px] h-[76px] object-cover"
          alt=""
          src="/rectangle-27@2x.png"
        />
        <div className="absolute top-[75px] left-[0px] bg-white shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] w-[225px] h-8" />
        <div className="absolute top-[85px] left-[11px] font-black">
          <span>Location: 3</span>
          <span className="text-new-2">KM</span>
        </div>
        <div className="absolute top-[85px] left-[116px] font-black">
          20 mins.
        </div>
        <div className="absolute top-[85px] left-[195px] font-black">6</div>
        <div className="absolute top-[12px] left-[196px] rounded-[50%] bg-white w-4 h-4" />
        <img
          className="absolute top-[16px] left-[200px] w-2 h-2 overflow-hidden"
          alt=""
          src="/290111-favorite-heart-like-love-valentine-icon-2.svg"
        />
        <img
          className="absolute h-[7.48%] w-[3.56%] top-[81.31%] right-[50.22%] bottom-[11.21%] left-[46.22%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/group.svg"
        />
        <img
          className="absolute h-[6.54%] w-[3.11%] top-[81.31%] right-[14.22%] bottom-[12.15%] left-[82.67%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector-3.svg"
        />
      </div>
      <img
        className="w-[138px] relative h-[100px] object-cover hidden z-[39]"
        alt=""
        src="/pngegg-19-1@2x.png"
      />
      <img
        className="w-[105px] relative h-[225px] object-contain hidden z-[40]"
        alt=""
        src="/pngegg-22-2@2x.png"
      />
      <div className="w-[220px] relative text-xs font-black text-white whitespace-pre-wrap text-right hidden z-[41]">
        <p className="m-0">California-style pizza Sicilian pizza</p>
        <p className="m-0">{`with Burger & French fries & 1 free`}</p>
        <p className="m-0">{`  Coca cola drink. `}</p>
      </div>
      <div className="w-[54px] relative text-smi font-black text-right hidden z-[42]">
        Popular
      </div>
      <div className="w-11 relative text-smi font-black text-chocolate-100 text-right hidden z-[43]">
        See all
      </div>
      <div className="w-[90px] h-[74px] relative hidden z-[44] text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
          alt=""
          src="/pngegg-17-1@2x.png"
        />
        <div className="absolute top-[48px] left-[1px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[18.92%] w-[16.67%] top-[72.97%] right-[82.22%] bottom-[8.11%] left-[1.11%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[56px] left-[14px] font-black">5.0</div>
      </div>
      <div className="w-[109px] h-[109px] relative hidden z-[45] text-right text-2xs text-new-2">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
          alt=""
          src="/pngegg-4-1@2x.png"
        />
        <div className="absolute top-[79px] left-[7px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[12.84%] w-[13.76%] top-[77.98%] right-[77.98%] bottom-[9.17%] left-[8.26%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[87px] left-[23px] font-black">6.5</div>
      </div>
      <div className="w-[93px] h-20 relative hidden z-[46] text-right text-2xs text-new-2">
        <img
          className="absolute h-[calc(100%_-_2px)] top-[0px] bottom-[2px] left-[0px] max-h-full w-20 object-cover"
          alt=""
          src="/4-1@2x.png"
        />
        <div className="absolute top-[54px] left-[4px] rounded-12xs bg-gray-1100 shadow-[0px_5px_10px_rgba(0,_0,_0,_0.1)] w-[89px] h-[26px]" />
        <img
          className="absolute h-[17.5%] w-[16.13%] top-[75%] right-[77.42%] bottom-[7.5%] left-[6.45%] rounded-12xs max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
        <div className="absolute top-[62px] left-[20px] font-black">6.0</div>
      </div>
      <main className="self-stretch h-[773px] [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start py-[19px] pr-9 pl-[38px] box-border relative max-w-full">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full z-[0]" />
        <img
          className="w-[9.4px] h-[24.6px] absolute my-0 mx-[!important] right-[190px] bottom-[259.5px] z-[1]"
          loading="eager"
          alt=""
          src="/group-5.svg"
        />
        <section className="self-stretch h-[115px] flex flex-col items-center justify-start gap-[68px] top-[0] z-[99] sticky max-w-full mq450:gap-[34px]">
          <img
            className="w-[326px] h-[12.8px] relative max-w-full z-[1]"
            loading="eager"
            alt=""
            src="/icon-iphone.svg"
          />
          <div className="self-stretch flex-1 bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-start pt-2 px-[3px] pb-1 box-border gap-[20px] max-w-full z-[1]">
            <div className="h-[34px] w-[354px] relative bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] hidden max-w-full" />
            <img
              className="h-[19px] w-[19px] relative mix-blend-normal z-[2]"
              alt=""
              src="/xmlid-223.svg"
            />
            <input
              className="w-[50px] [border:none] [outline:none] font-black font-poppins text-sm bg-[transparent] h-[21px] relative text-dimgray-500 text-left inline-block mix-blend-normal z-[2]"
              placeholder="Search"
              type="text"
            />
          </div>
        </section>
      </main>
      <img
        className="w-full absolute my-0 mx-[!important] right-[0px] bottom-[125px] left-[0px] max-w-full overflow-hidden h-[664px] shrink-0 object-cover z-[2]"
        alt=""
        src="/courier-frames@2x.png"
      />
      <OrderStatusFrame />
    </div>
  );
};

export default OrderTracking;
