import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const SigningUp1 = () => {
  const [page1IconChecked, setPage1IconChecked] = useState(true);
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  return (
    <section className="h-[706px] w-[327px] my-0 mx-[!important] absolute bottom-[-97px] left-[44px] rounded-mini bg-white box-border flex flex-col items-center justify-start pt-[31px] pb-[67px] pr-[11px] pl-[15px] gap-[22px] z-[2] text-left text-3xs text-midnightblue font-inter border-[2px] border-solid border-crimson-100">
      <div className="w-[327px] relative rounded-mini bg-white box-border h-[706px] hidden border-[2px] border-solid border-crimson-100" />
      <div className="w-[91.1px] h-[78.2px] relative hidden">
        <div className="absolute top-[0px] left-[calc(50%_-_25.45px)] inline-block w-[71px] h-[15px]">
          Remember me
        </div>
        <img
          className="absolute h-[18.16%] w-[14.27%] top-[81.84%] right-[85.73%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
      </div>
      <div className="self-stretch flex-1 flex flex-row items-start justify-start py-0 pr-2.5 pl-[7px]">
        <form className="m-0 h-[470px] flex-1 flex flex-col items-center justify-start gap-[26px]">
          <button className="cursor-pointer py-[9px] pr-[45px] pl-11 bg-[transparent] self-stretch rounded-3xs flex flex-row items-end justify-start z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[280px] relative rounded-3xs box-border hidden border-[1px] border-solid border-crimson-100" />
            <img
              className="h-[27px] w-[27px] relative overflow-hidden shrink-0 z-[1]"
              alt=""
              src="/flatcoloriconsgoogle.svg"
            />
            <div className="flex flex-col items-start justify-start py-0 px-0">
              <div className="w-[168px] relative text-base font-medium font-inter text-crimson-100 text-center inline-block z-[1]">
                Sign up with Google
              </div>
            </div>
          </button>
          <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-500" />
          <div className="self-stretch rounded-3xs bg-white flex flex-row items-end justify-start pt-3.5 px-[13px] pb-4 gap-[12px] z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
            <img
              className="h-[11px] w-[11.8px] relative z-[1]"
              alt=""
              src="/group.svg"
            />
            <input
              className="w-full [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-[0.40000000000009095px] px-0 pb-[3px] box-border font-inter font-medium text-sm"
              placeholder="John Doe"
              type="text"
            />
          </div>
          <div className="self-stretch h-[45px] rounded-3xs bg-white box-border flex flex-row items-start justify-start pt-4 px-2 pb-3.5 gap-[7px] z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
            <img
              className="h-[13px] w-[13px] relative z-[1]"
              alt=""
              src="/vector-11.svg"
            />
            <input
              className="w-full [border:none] [outline:none] font-medium font-inter text-sm bg-[transparent] h-3 relative  inline-block z-[1]"
              placeholder="09012345678"
              type="number"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[11px]">
            <div className="self-stretch rounded-3xs bg-white flex flex-row items-end justify-start pt-3.5 px-2 pb-4 gap-[7px] z-[1] border-[1px] border-solid border-crimson-100">
              <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
              <img
                className="h-2.5 w-[13.1px] relative z-[1]"
                alt=""
                src="/group.svg"
              />
              <input
                className="w-full [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-[0.40000000000009095px] px-0 pb-[3px] box-border font-inter font-medium text-sm"
                placeholder="johndoe@gmail.com"
                type="email"
              />
            </div>
            <div className="self-stretch h-[45px] relative">
              <img
                className="absolute top-[-17.1px] left-[160.6px] w-[9.4px] h-[24.6px] hidden z-[1]"
                alt=""
                src="/group-5.svg"
              />
              <div className="absolute top-[0px] left-[0px] rounded-3xs bg-white box-border w-[280px] flex flex-row items-start justify-start pt-3.5 pb-[11px] pr-3 pl-[7px] gap-[9px] z-[1] border-[1px] border-solid border-crimson-100">
                <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
                <img
                  className="h-[16.3px] w-3 relative z-[1]"
                  loading="eager"
                  alt=""
                  src="/lock.svg"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  {/* <div className="relative text-sm font-medium font-inter text-goldenrod text-left z-[1]">
                    **
                  </div> */}
                  <input
                className="w-full [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-[0.40000000000009095px] px-0 pb-[3px] box-border font-inter font-medium text-sm"
                placeholder="**********"
                type="password"
              />
                </div>
                <div className="h-5 relative text-smi font-black font-poppins cursor-pointer text-gray-400 text-left inline-block z-[1]">
                  Show
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-3xs bg-white flex flex-row items-start justify-start pt-[15px] pb-2.5 pr-4 pl-[7px] gap-[9px] z-[1] border-[1px] border-solid border-crimson-100">
            <div className="h-[45px] w-[280px] relative rounded-3xs bg-white box-border hidden border-[1px] border-solid border-crimson-100" />
            <img
              className="h-[16.3px] w-3 relative z-[1]"
              loading="eager"
              alt=""
              src="/lock.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start">
              
            <input
                className="w-full [border:none] [outline:none] bg-[transparent] h-[15px] flex flex-col items-start justify-start pt-[0.40000000000009095px] px-0 pb-[3px] box-border font-inter font-medium text-sm"
                placeholder="Confirm Password"
                type="password"
              />
              {/* <div className="relative text-sm font-medium font-inter text-goldenrod text-left z-[1]">
                Confirm Password
              </div> */}
            </div>
            <b className="relative text-smi font-poppins text-gray-400 cursor-pointer text-left z-[1]">
              Hide
            </b>
          </div>
          <div className="self-stretch flex flex-row items-end justify-start py-0 pr-1 pl-[3px] gap-[17px]">
            <input
              className="accent-new-2 m-0 h-3.5 w-3.5 relative z-[1]"
              checked={page1IconChecked}
              type="checkbox"
              onChange={(event) => setPage1IconChecked(event.target.checked)}
            />
            <div className="relative text-2xs tracking-[0.06em] font-open-sans text-black text-left z-[1]">
              Yes, I want to receive offers and discounts
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-row items-start justify-start py-0 pr-[23px] pl-[19px]">
        <button
          className="cursor-pointer [border:none] py-[13px] pr-[71px] pl-[70px] bg-crimson-100 rounded-xl flex flex-row items-center justify-center z-[1]"
        >
          <div className="h-[46px] w-[255px] relative rounded-xl bg-crimson-100 hidden" />
          <a href="/home-page" className="relative text-mini font-open-sans text-white text-left z-[1]">
            Create account
          </a>
        </button>
      </div>
      <div className="self-stretch h-[30px] relative text-2xs tracking-[0.03em] font-open-sans inline-block opacity-[0.7] z-[1] text-grey-2">
        <span>By Creating an account you agree to the</span>
        <a href="#" className="text-new-2"> privacy policy</a>
        <span> and to the</span>
        <a href="#" className="text-new-2"> terms of use</a>
      </div>
    </section>
  );
};

export default SigningUp1;
