import SigningUp1 from "../components/SigningUp1";

const SignUp = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[63px] box-border gap-[106px] text-left text-smi text-neutral-black font-poppins">
      {/* <div className="w-80 overflow-hidden hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border font-source-sans-pro">
        <div className="self-stretch h-5 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div> */}
      <main className="h-[772px] flex flex-row items-start justify-center relative">
        <SigningUp1 />
        <section className="w-[327px] flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start p-[35px] gap-[29px] text-left text-3xs text-grey-2 font-poppins">
          <div className="w-[428px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] h-[772px] hidden" />
          <img
            className="w-[172px] h-[41px] relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/chopinns-v4-1-2@2x.png"
          />
          <div className="w-[212px] h-10 relative">
            <div className="absolute top-[25px] left-[6px] font-medium z-[1]">
              Please fill in your accurate information
            </div>
            <div className="absolute top-[0px] left-[0px] flex flex-row items-center justify-start py-0 pr-[9px] pl-0 z-[1] text-2xl">
              <div className="h-[26px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
                <h3 className="m-0 h-8 relative text-inherit font-black font-inherit inline-block">
                  Create an account
                </h3>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <div className="w-[162px] h-[45px] relative hidden text-lg text-white">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-[162px] h-[45px]" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <div className="w-[183px] h-[52px] relative hidden text-mid text-text-color">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </div> */}
      <div className="flex flex-row items-start justify-start py-0 px-5 gap-[5px] text-3xs text-crimson-100 font-inter">
        <div className="relative">Already have an account?</div>
        <a href="/sign-in" className="relative font-medium text-style1">Sign in</a>
      </div>
    </div>
  );
};

export default SignUp;
