import { useCallback } from "react";
import SigningUp from "../components/SigningUp";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();

  const onForSignInClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-center pt-0 px-0 pb-[180px] box-border tracking-[normal] text-left text-smi text-neutral-black font-source-sans-pro">
      {/* <div className="h-6 w-80 overflow-hidden shrink-0 hidden flex-col items-start justify-start pt-1 px-4 pb-0 box-border">
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center opacity-[0.6]">
          This is the description area
        </div>
      </div>
      <div className="h-[45px] w-[162px] relative hidden text-lg text-white font-poppins">
        <div className="absolute top-[0px] left-[0px] rounded-81xl bg-crimson-100 w-full h-full" />
        <div className="absolute top-[10px] left-[20px] capitalize">
          Downlode App
        </div>
      </div>
      <footer className="h-[52px] w-[183px] relative hidden text-left text-mid text-text-color font-poppins">
        <div className="absolute top-[15px] left-[73px] font-medium">
          Watch Video
        </div>
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[52px]"
          alt=""
          src="/play-button.svg"
        />
      </footer> */}
      <main className="flex-1 [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] flex flex-col items-center justify-start pt-9 pb-[123px] pr-[57px] pl-11 box-border gap-[13px] max-w-full text-left text-2xl text-grey-2 font-poppins mq450:pt-5 mq450:pb-[52px] mq450:box-border mq750:pt-[23px] mq750:pb-20 mq750:box-border">
        <div className="w-[428px] h-[772px] relative [background:linear-gradient(180deg,_rgba(245,_71,_72,_0.06),_rgba(245,_71,_72,_0))] hidden max-w-full" />
        <div className="h-14 flex flex-row items-start justify-start py-0 pr-0 pl-[13px] box-border">
          <img
            className="h-[41px] w-[172px] relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/chopinns-v4-1-2@2x.png"
          />
        </div>
        <div className="flex flex-row items-start justify-start py-0 pr-0 pl-[18px]">
          <div className="flex flex-col items-center justify-start gap-[2px]">
            <div className="flex flex-row items-center justify-center py-0 pr-px pl-0 z-[1]">
              <div className="h-[26px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
                <h1 className="m-0 h-8 relative text-inherit font-black font-inherit inline-block">
                  Welcome back
                </h1>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start py-0 pr-[15px] pl-2.5 text-3xs">
              <div className="relative font-medium z-[1]">
                Please log in to your account
              </div>
            </div>
          </div>
        </div>
        <SigningUp />
        <div className="flex flex-row items-start justify-start py-0 pr-0 pl-2 text-3xs text-crimson-100 font-inter">
          <div
            className="flex flex-row items-start justify-start gap-[3px] cursor-pointer z-[1]"
            onClick={onForSignInClick}
          >
            <div className="relative">Don’t have an account yet?</div>
            <div className="relative font-medium text-style1 whitespace-nowrap">
              Sign up
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignIn;
