/**
 * 
 * @returns 
 */
export default function MealSearchBar() {
    return (
        <div
            className="w-[354px] bg-white shadow-[0px_4px_7px_-2px_rgba(0,_0,_0,_0.15)] flex flex-row items-start justify-start py-2 px-2 cursor-pointer"
        >
            <img
                className="px-1"
                alt="search icon"
                src="/xmlid-223.svg"
            />
            <input
                className="w-full [border:none] [outline:none] font-medium font-poppins"
                placeholder="Search meals"
                type="search"
            />
        </div>
    )
}