/**
 * 
 */
// import Container5 from "./Container5";

import MenuCard from "./MenuCard";
export default function MenuRecommendation() {
    return (
        <section className="py-2 pl-5 pr-[65px] font-poppins">
            <p className="font-extrabold">Menu</p>
            <div className="flex flex-wrap items-center justify-between gap-[16px] font-poppins">
                <MenuCard
                    image="/image-41@2x.png"
                    title="Sauté Chicken Rice"
                    subTitle="Sauté Chicken  Rice"
                    price="N1,200"
                    rating="4.3"
                />
                <MenuCard
                    image="/image-42@2x.png"
                    title="Chicken Burger"
                    subTitle="Fried chicken, Cheese & Burger"
                    price="N2,000"
                    rating="4.9"
                />
            </div>
            <div className="flex flex-row items-center justify-center py-4">
                <button className="cursor-pointer bg-inherit flex flex-row font-inter text-new-2">See all</button>
            </div>
        </section>
    );

}