const LineContainer = () => {
  return (
    <div className="w-[335px] flex flex-col items-start justify-start gap-[6px] max-w-full text-left text-sm text-gray-100 font-inter">
      <div className="w-[315px] flex flex-row items-start justify-between gap-[20px] text-darkslategray-200 font-poppins">
        <div className="flex flex-col items-start justify-start gap-[7px]">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <b className="relative leading-[22px]">Size</b>
            <div className="relative text-xs leading-[20px] font-light text-lightslategray-100">
              (Pick 1)
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[6px] text-gray-100 font-inter">
            <div className="h-4 w-4 relative rounded-[50%] bg-gray-1300 box-border border-[1px] border-solid border-dimgray-100" />
            <div className="relative leading-[22px]">S</div>
          </div>
        </div>
        <input
          className="m-0 h-6 w-6 relative overflow-hidden shrink-0"
          type="checkbox"
        />
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        loading="eager"
        alt=""
        src="/line-7.svg"
      />
      <div className="w-80 flex flex-row items-center justify-start gap-[6px]">
        <div className="h-4 w-4 relative rounded-[50%] bg-gray-1300 box-border border-[1px] border-solid border-dimgray-100" />
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="relative leading-[22px]">M</div>
        </div>
        <div className="relative leading-[22px] font-poppins text-lightslategray-100 text-right">
          N1,000
        </div>
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        loading="eager"
        alt=""
        src="/line-7.svg"
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[6px] max-w-full">
        <div className="w-[324px] flex flex-row items-start justify-between gap-[20px] max-w-full">
          <div className="flex flex-col items-start justify-start gap-[20px]">
            <div className="flex flex-row items-center justify-start gap-[5px]">
              <div className="h-[17px] w-[17px] relative">
                <div className="absolute top-[0px] left-[-0.5px] rounded-[50%] bg-new-2 w-full h-full" />
                <div className="absolute top-[5px] left-[4.5px] rounded-[50%] bg-seashell w-[7px] h-[7px] z-[1]" />
              </div>
              <div className="relative leading-[22px]">L</div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[6px] text-darkslategray-200 font-poppins">
              <div className="flex flex-row items-center justify-start gap-[4px]">
                <b className="relative leading-[22px]">Topping</b>
                <div className="relative text-xs leading-[20px] font-light text-lightslategray-100">
                  (Optional)
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-[6px] text-gray-100 font-inter">
                <input
                  className="m-0 h-4 w-4 relative rounded-sm"
                  type="checkbox"
                />
                <div className="relative leading-[22px]">Coleslaw</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[48px] text-right text-lightslategray-100 font-poppins">
            <div className="relative leading-[22px]">N2,000</div>
            <div className="relative leading-[22px]">N,700</div>
          </div>
        </div>
        <img
          className="self-stretch relative max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/line-7.svg"
        />
        <div className="w-[315px] flex flex-row items-center justify-start gap-[6px]">
          <input className="m-0 h-4 w-4 relative rounded-sm" type="checkbox" />
          <div className="flex-1 flex flex-col items-start justify-start">
            <div className="relative leading-[22px]">Plantain</div>
          </div>
          <div className="relative leading-[22px] font-poppins text-lightslategray-100 text-right">
            N5,00
          </div>
        </div>
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        loading="eager"
        alt=""
        src="/line-7.svg"
      />
      <div className="w-[318px] flex flex-row items-start justify-between gap-[20px]">
        <div className="flex flex-col items-start justify-start gap-[21px]">
          <div className="flex flex-row items-center justify-start gap-[6px]">
            <input
              className="m-0 h-4 w-4 relative rounded-sm box-border border-[1px] border-solid border-lightslategray-100"
              type="checkbox"
            />
            <div className="relative leading-[22px]">Asun</div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[7px] text-darkslategray-200 font-poppins">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <b className="relative leading-[22px]">Spiciness</b>
              <div className="relative text-xs leading-[20px] font-light text-lightslategray-100">
                (Pick 1)
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[6px] text-gray-100 font-inter">
              <div className="h-4 w-4 relative rounded-[50%] bg-gray-1300 box-border border-[1px] border-solid border-lightslategray-100" />
              <div className="relative leading-[22px]">No</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-start gap-[20px] text-right text-lightslategray-100 font-poppins">
          <div className="relative leading-[22px]">N1,150</div>
          <input className="m-0 w-[30px] h-6" type="checkbox" />
        </div>
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        loading="eager"
        alt=""
        src="/line-7.svg"
      />
      <div className="flex flex-row items-center justify-start gap-[5px]">
        <div className="h-[17px] w-[17px] relative">
          <div className="absolute top-[0px] left-[-0.5px] rounded-[50%] bg-new-2 w-full h-full" />
          <div className="absolute top-[5px] left-[4.5px] rounded-[50%] bg-seashell w-[7px] h-[7px] z-[1]" />
        </div>
        <div className="relative leading-[22px]">Hot</div>
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        loading="eager"
        alt=""
        src="/line-7.svg"
      />
      <div className="flex flex-row items-center justify-start gap-[6px]">
        <div className="h-4 w-4 relative rounded-[50%] bg-gray-1300 box-border border-[1px] border-solid border-lightslategray-100" />
        <div className="relative leading-[22px]">Very hot</div>
      </div>
    </div>
  );
};

export default LineContainer;
