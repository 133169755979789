const FrameB = () => {
  return (
    <section className="self-stretch h-[129px] flex flex-row items-start justify-start py-0 px-px box-border text-left text-mini text-gray-700 font-poppins">
      <div className="flex flex-col items-start justify-start gap-[11px]">
        <div className="flex flex-row items-start justify-start gap-[29px]">
          <div className="relative font-extrabold z-[1]">Pay on delivery</div>
          <img
            className="h-4 w-4 relative object-cover z-[1]"
            loading="eager"
            alt=""
            src="/rightarrow-1@2x.png"
          />
        </div>
        <div className="flex flex-row items-center justify-start gap-[11px]">
          <div className="flex flex-col items-start justify-start gap-[9px]">
            <div className="h-[23px] relative font-extrabold inline-block z-[1]">
              Bank Transfer
            </div>
            <div className="relative font-extrabold z-[1]">Pay on Credit</div>
            <div className="relative font-extrabold z-[1]">
              Credit/Debit Card
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[12px] text-black">
            <img
              className="w-4 h-4 relative object-cover z-[1]"
              loading="eager"
              alt=""
              src="/rightarrow-1@2x.png"
            />
            <div className="relative font-extrabold z-[1]">
              <span>(</span>
              <span className="text-crimson-100">Coming Soon</span>
              <span>)</span>
            </div>
            <img
              className="w-4 h-4 relative object-contain z-[1]"
              loading="eager"
              alt=""
              src="/rightarrow-1-2@2x.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameB;
