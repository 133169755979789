import { useMemo } from "react";

const FrameComponent = ({
  image60,
  friedChicken,
  sizeL,
  toppingColeslawPLANTAIN,
  spicinessHot,
  prop,
  propPadding,
  propWidth,
  propColor,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const backgroundImageFrameStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const bStyle = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <div
      className="self-stretch flex flex-row items-start justify-between gap-[20px] text-left text-xs text-lightslategray-100 font-inter mq450:flex-wrap"
      style={frameDivStyle}
    >
      <div
        className="w-[217px] flex flex-row items-start justify-start gap-[18px]"
        style={backgroundImageFrameStyle}
      >
        <img
          className="h-9 w-9 relative object-cover"
          loading="eager"
          alt=""
          src={image60}
        />
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="relative text-sm leading-[22px] font-poppins text-darkslategray-200">
            {friedChicken}
          </div>
          <div className="relative leading-[20px]">{sizeL}</div>
          <div className="relative leading-[20px]">
            {toppingColeslawPLANTAIN}
          </div>
          <div className="relative leading-[20px]">{spicinessHot}</div>
          <b
            className="relative text-sm leading-[22px] font-poppins text-gray-100"
            style={bStyle}
          >
            {prop}
          </b>
        </div>
      </div>
      <div className="h-7 rounded-sm bg-whitesmoke-200 overflow-hidden flex flex-row items-center justify-start py-[5px] px-2.5 box-border text-2xs text-dimgray-100">
        <img
          className="h-3 w-3 relative overflow-hidden shrink-0"
          alt=""
          src="/pen.svg"
        />
        <div className="self-stretch w-0 relative leading-[18px] inline-block" />
      </div>
    </div>
  );
};

export default FrameComponent;
