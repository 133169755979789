import TopLevelContainer from "../components/TopLevelContainer";
import MainFrame1 from "../components/MainFrame1";
import RecommendationsList from "../components/RecommendationsList";
import SortButton from "../components/SortButton";
import BrowseFrame from "../components/BrowseFrame";

import RestaurantList from "../components/RestaurantList";
import RestaurantNav from "../components/nav/RestaurantNav";
import RestaurantRecommendationList from "../components/RestaurantRecommendationList";

const BrowseByCategory = () => {
  return (
    <main className="flex justify-center">
    <div className="lg:min-w-[800px] md:min-w-[600px] flex flex-col bg-snow-400 shadow-[0px_3px_6px_rgba(18,_15,_40,_0.12)]">
    {/* <div className="w-auto items-center relative bg-snow-400 shadow-[0px_3px_6px_rgba(18,_15,_40,_0.12)] overflow-hidden flex flex-col items-start justify-start gap-[40px] tracking-[normal]"> */}
      <RestaurantList />
      <RestaurantRecommendationList />
      < RestaurantNav />

      {/* <TopLevelContainer /> */}
      {/* <section className="w-[401px] flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[32px] max-w-full"> */}
        {/* <MainFrame1 /> */}
        {/* <div className="self-stretch flex flex-col items-start justify-start gap-[15px] max-w-full"> */}
          {/* <RecommendationsList /> */}
          {/* <SortButton /> */}
        {/* </div> */}
      {/* </section> */}
      {/* <BrowseFrame /> */}
    </div>
    </main>
  );
};

export default BrowseByCategory;
