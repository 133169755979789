const FrameComponent4 = () => {
  return (
    <section className="self-stretch h-[194px] flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[15px] text-left text-base text-gray-900 font-poppins">
      <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
        <div className="w-[213px] flex flex-col items-end justify-start">
          <div className="w-[9.4px] h-[24.6px] relative z-[1]"></div>
          <div className="self-stretch flex flex-row items-start justify-start">
            <b className="relative z-[1]">Expiry date</b>
          </div>
        </div>
        <input
          className="[border:none] [outline:none] bg-whitesmoke-300 self-stretch h-[35px] relative rounded-md min-w-[229px] z-[1]"
          type="text"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
        <input
          className="w-[78px] [border:none] [outline:none] inline-block font-poppins text-base bg-[transparent] h-6 relative font-bold text-gray-900 text-left z-[1]"
          placeholder="Password"
          type="text"
        />
        <div className="self-stretch h-[35px] relative rounded-md bg-whitesmoke-300 z-[1]" />
      </div>
    </section>
  );
};

export default FrameComponent4;
